import { mapState } from "vuex"

import addl_profile_utilities from "./AddlProfileUtilities"

const language_utilities = {
    mixins: [addl_profile_utilities],
    mounted () {
        this.local_translate_to()
    },
    watch: {
        profile_id: "local_translate_to",
        localization: "local_translate_to"
    },
    methods: {
        reread_current_language () {
            const profile = this.profile
            if(!profile || !profile.language) this.current_language = this.$cookie.get("lang") || this.$i18n.locale() || "en"
            else this.current_language = profile.language || "en"
        },
        local_translate_to () {
            this.reread_current_language()
            const language = this.current_language
            this.$i18n.set(language)
            this.$numeral.locale(language)
        },
        translate_to (language) {
            if(language !== this.current_language) this.$store.dispatch("SET_LANGUAGE", { language })
        }
    },
    computed: {
        ...mapState(["localization"]),
        alternate_language () {
            if(this.localization === "en") return "fr"
            return "en"
        },
        km_units () {
            if(this.localization === "fr") return `&nbsp;${this.$root.translate("Km")}`
            return this.$root.translate("Km")
        },
        localized_decimal_separator () {
            if(this.localization === "fr") return ","
            return "."
        }
    },
    data () {
        return {
            current_language: "en"
        }
    }
}

export default language_utilities
