<template>
    <base-select
        ref="field"
        v-bind="select_props"
        :id="identifier"
        :options="filtered_options"
        :expand-on-focus="expandOnFocus || showAll"
        @focus="$emit('focus')"
        @search="search"
        @input="pick"
    >
        <template v-slot:option="{ option }"><slot name="option" :option="option"></slot></template>
        <template v-slot:right_addon="{ option }"><slot name="right_addon" :option="option"></slot></template>
    </base-select>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import handles_choices from "@/nibnut/mixins/HandlesChoices"

import BaseSelect from "./BaseSelect"

export default {
    name: "DefaultSelect",
    mixins: [is_nibnut_component, handles_choices],
    components: {
        BaseSelect
    },
    methods: {
        autopick (option) {
            this.pick(option[this.idField], this.name, option)
        },
        pick (value, field, option) {
            if(this.adHoc && (value === this.emptyValue) && !!option[this.labelField]) this.$emit("create", option[this.labelField].replace(this.adHocChoicePrefix, ""), field)
            else if((value === this.emptyValue) && !this.required) this.$emit("input", this.emptyValue, field, option)
            else if(value !== this.emptyValue) this.$emit("input", value, field, option)
        }
    },
    computed: {
        select_props () {
            const { id, expandOnFocus, dataSource, ...select_props } = this.$props
            return select_props
        },
        available_options () {
            if((this.option_query.length < this.minOptionQueryLength) && !this.showAll) {
                return this.options.filter(option => option[this.idField] === this.value) || []
            }
            return this.options || []
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // object.idField value
            default: null
        },
        glyph: {
            type: String,
            default: "caret-down"
        },
        expandOnFocus: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            options: []
        }
    }
}
</script>
