import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

const addl_profile_utilities = {
    mixins: [profile_utilities],
    methods: {
        role_is_regional_admin (role) {
            return (role === this.constants("roles", "ROLE_REGIONAL_ADMIN").id)
        },
        role_is_at_least_regional_admin (role) {
            return (role >= this.constants("roles", "ROLE_REGIONAL_ADMIN").id)
        },
        role_is_captain (role) {
            return (role === this.constants("roles", "ROLE_CAPTAIN").id)
        },
        role_is_at_least_captain (role) {
            return (role >= this.constants("roles", "ROLE_CAPTAIN").id)
        },
        maybe_show_login_message () {
            if(!!this.profile && !!this.profile.next_login_message) {
                this.$info(this.profile.next_login_message)
                this.$store.dispatch("RECORD_SAVE", {
                    entity: "user",
                    id: this.profile_id,
                    data: { next_login_message: null }
                }).catch(() => {
                    // ignore any error
                })
            }
        }
    },
    computed: {
        ...mapState(["walker_uuid"]),
        is_at_least_regional_admin () {
            return this.is_regional_admin || this.is_administrator || this.is_developer
        },
        is_regional_admin () {
            if(!this.profile_id) return false
            return this.role_is_regional_admin(this.profile.role)
        },
        is_at_least_captain () {
            return this.is_captain || this.is_regional_admin || this.is_administrator || this.is_developer
        },
        is_captain () {
            if(!this.profile_id) return false
            return this.role_is_captain(this.profile.role)
        },
        raw_walker_records () {
            return this.entity_records("walker")
        },
        current_walker () {
            return this.raw_walker_records.find(walker => walker.uuid === this.walker_uuid) || {}
        }
    }
}

export default addl_profile_utilities
