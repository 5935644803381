<template>
    <modal-dialog
        id="confirm"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span class="h5">{{ $root.translate("Re-send invitation to {name}", { name: user[nameField] }) }}</span>
        </template>
        <base-form
            :has-required-fields="true"
            @submit.prevent.stop="invite"
        >
            <invitation-expiries
                id="expires_in"
                name="expires_in"
                v-model="expires_in"
                :required="true"
                :error="has_error('expires_in')"
            />
            <default-button
                type="submit"
                :waiting="inviting"
                :block="true"
            >
                {{ $root.translate("Re-send Invitation") }}
            </default-button>
        </base-form>
    </modal-dialog>
</template>

<script>
import { mapState } from "vuex"

import handles_errors from "@/nibnut/mixins/HandlesErrors"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import InvitationExpiries from "@/nibnut/components/Inputs/InvitationExpiries"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    mixins: [handles_errors],
    components: {
        BaseForm,
        InvitationExpiries,
        ModalDialog,
        DefaultButton
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            this.expires_in = this.app_context.settings.default_invitation_delay
        },
        invite () {
            this.inviting = true
            this.$store.dispatch(
                "REINVITE",
                {
                    email: this.user.email,
                    expires_in: this.expires_in
                }
            ).then(() => {
                this.$success(this.$root.translate("New invitation email sent!"))
                this.shown = false
            }).catch(this.receive_error).then(() => {
                this.inviting = false
            })
        }
    },
    computed: {
        ...mapState(["app_context"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true
        },
        nameField: {
            type: String,
            default: "name"
        }
    },
    data () {
        return {
            expires_in: 0,
            inviting: false
        }
    }
}
</script>
