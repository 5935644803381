<template>
    <modal-dialog
        id="avatar-picker"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span class="h3">{{ $root.translate("Pick your favourite!") }}</span>
        </template>

        <div class="d-flex">
            <default-button
                v-for="avatar in avatars"
                :key="avatar.id"
                class="btn-avatar"
                @click.prevent="pick(avatar)"
            >
                <figure
                    class="avatar avatar-xl"
                >
                    <img :src="avatar.url" :alt="avatar.name">
                </figure>
            </default-button>
        </div>
    </modal-dialog>
</template>

<script>
import { mapGetters } from "vuex"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "AvatarPicker",
    mixins: [misc_utilities],
    components: {
        ModalDialog,
        DefaultButton
    },
    methods: {
        pick (avatar) {
            this.$emit("pick", avatar.id)
            this.$nextTick(() => {
                this.shown = false
            })
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        avatars () {
            const avatar_type = this.constants("attachment_types", "TYPE_AVATAR").id
            return this.entity_records("attachment").filter(attachment => attachment.type === avatar_type)
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.btn-avatar {
    padding: 0;
    height: auto;

    &, &:active, &.active, &:focus, &:visited {
        background: transparent;
    }
    @include hover-supported {
        background: transparent;
    }
}
#avatar-picker {
    .modal-container {
        max-width: 460px;
    }
    .d-flex {
        flex-wrap: wrap;
        justify-content: center;

        .btn-avatar {
            margin: $unit-2;
        }
    }
}
</style>
