<template>
    <span v-if="stacked" class="la-stack">
        <i v-for="individual_glyph in glyph" :key="individual_glyph.glyph" :class="dynamic_icon_classes(individual_glyph)" :title="title||false" aria-hidden="true"></i>
    </span>
    <i v-else :class="dynamic_icon_classes(glyph)" :title="title||false" aria-hidden="true"></i>
</template>

<script type="text/javascript">
export default {
    name: "OpenIcon",
    methods: {
        dynamic_icon_classes (glyph) {
            let css_classes = {
                [this.library]: true
            }
            if(this.library === "hop") {
                css_classes[`icon-${glyph.glyph || glyph}`] = true
                if(this.size) css_classes[`hop-${this.size}`] = true
            } else {
                css_classes[`la-${glyph.glyph || glyph}`] = true
                if(this.stacked) {
                    if(glyph.css) css_classes = { ...css_classes, ...glyph.css }
                    else css_classes["la-stack-1x"] = true
                } else if(this.size) css_classes[`la-${this.size}`] = true

                if(this.spin) css_classes["la-spin"] = true

                if(this.rotate) css_classes[`la-rotate-${this.rotate}`] = true

                if(this.flip) css_classes[`la-flip-${this.rotate}`] = true
            }

            return css_classes
        }
    },
    computed: {
        stacked () {
            return Array.isArray(this.glyph) && this.glyph.length > 1
        },
        dynamic_stack_classes () {
            const css_classes = {}
            if(this.size) css_classes[`la-${this.size}`] = true
            return css_classes
        }
    },
    props: {
        glyph: { // "glyph" OR [ { glyph: "...", css: {...} }, ... ]
            validator: prop => {
                return (typeof prop === "string") || Array.isArray(prop)
            },
            required: true
        },
        library: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(las|lab|hop)$/i),
            default: "las"
        },
        title: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            validator: prop => {
                return !prop || !!prop.match(/^(xs|1x|lg|2x|3x|4x|5x|6x|7x|8x|9x|10x)$/i)
            },
            default: ""
        },
        spin: {
            type: Boolean,
            default: false
        },
        rotate: {
            type: Number,
            validator: prop => {
                return (prop === 0) || (prop === 90) || (prop === 180) || (prop === 270)
            },
            default: 0
        },
        flip: {
            type: String,
            validator: prop => {
                return !prop || !!prop.match(/^(horizontal|vertical)$/i)
            },
            default: ""
        }
    }
}
</script>
