<template>
    <div class="btn-copy-link">
        <div
            class="tile tile-centered list-tile"
        >
            <div class="tile-content">
                <div class="tile-title">
                    <base-link
                        :title="$root.translate('Copy link to share via email')"
                        :href="href"
                        @click.prevent="copy_to_clipboard"
                        :class="{ copied }"
                        class="btn btn-link copy-container"
                    >
                        <span v-if="copied" class="animated tada">{{ copied }}</span>
                        <span ref="target" v-else>{{ adjusted_href }}</span>
                    </base-link>
                </div>
            </div>
            <div class="tile-action">
                <base-link
                    :title="$root.translate('Copy link to share via email')"
                    :href="href"
                    @click.prevent="copy_to_clipboard"
                    :class="{ copied }"
                    class="btn btn-link copy-container"
                >
                    <open-icon glyph="link" />
                </base-link>
            </div>
        </div>
        <div
            v-if="!!error"
            class="text-error"
        >
            <small>{{ error }}</small>
        </div>
    </div>
</template>

<script>
// import utilities from "../mixins/Is"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

let timer

export default {
    // mixins: [utilities],
    components: {
        BaseLink,
        OpenIcon
    },
    created () {
        window.addEventListener("resize", this.window_resized)
    },
    mounted () {
        this.window_resized()
        setTimeout(this.window_resized, 300)
    },
    beforeDestroy () {
        window.removeEventListener("resize", this.window_resized)
    },
    methods: {
        window_resized () {
            const max_chars = 46
            const max_width = 459
            const container = this.$refs.target.parentNode
            this.max_length = Math.round((container.getBoundingClientRect().width * max_chars) / max_width) || 0
        },
        select_node_text (node) {
            if(document.body.createTextRange) {
                const range = document.body.createTextRange()
                range.moveToElementText(node)
                range.select()
            } else if(window.getSelection) {
                const selection = window.getSelection()
                const range = document.createRange()
                range.selectNodeContents(node)
                selection.removeAllRanges()
                selection.addRange(range)
            }
        },
        copy_to_clipboard () {
            if(timer) {
                clearTimeout(timer)
                timer = null
            }
            this.select_node_text(this.$refs.target)

            let clipboard_content = this.href
            if(this.text) clipboard_content = `${this.text}\n\n${clipboard_content}`
            this.$copyText(clipboard_content).then(event => {
                this.copied = this.$root.translate("Copied!")
                timer = setTimeout(() => {
                    this.copied = false
                }, 2500)
            }, () => {
                this.error = this.$root.translate("Could not copy the link. Try your usual keyboard shortcut instead.")
            })
        }
    },
    computed: {
        adjusted_href () {
            if(!!this.max_length && (this.href.length > this.max_length)) {
                const ellipsis = "..."
                const shortened_length = this.max_length - ellipsis.length
                const prefix_length = Math.round(shortened_length * 0.666)
                const suffix_length = shortened_length - prefix_length
                return `${this.href.substr(0, prefix_length)}${ellipsis}${this.href.substr(this.href.length - suffix_length, suffix_length)}`
            }
            return this.href
        }
    },
    props: {
        href: {
            type: String,
            required: true
        },
        text: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            max_length: 0,
            copied: false,
            error: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.btn-copy-link {
    .tile-title {
        .btn-link.copy-container {
            display: inline-block;
            max-width: 100%;
            padding-right: 0;
            &.copied {
                color: $success-color;

                i {
                    color: $success-color;
                }

                &, &:hover, &:active, &:focus, &:visited { text-decoration: none; }
            }
        }
    }
}
</style>
