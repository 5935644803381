<template>
    <base-toggle-input
        v-bind="base_toggle_input"
        :checked="is_checked"
        @change.prevent="toggle"
    >
        <slot></slot>
    </base-toggle-input>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import BaseToggleInput from "./BaseToggleInput"

export default {
    name: "DefaultToggleInput",
    mixins: [is_nibnut_component],
    components: {
        BaseToggleInput
    },
    methods: {
        toggle () {
            if(this.is_checked) this.$emit("input", this.falseValue, this.name)
            else this.$emit("input", this.trueValue, this.name)
        }
    },
    computed: {
        base_toggle_input () {
            const { value, trueValue, falseValue, ...base_toggle_input } = this.$props
            return base_toggle_input
        },
        is_checked () {
            return this.value === this.trueValue
        }
    },
    props: {
        ...BaseToggleInput.props,
        value: {
            default: false
        },
        trueValue: {
            default: true
        },
        falseValue: {
            default: false
        }
    }
}
</script>
