<template>
    <form
        role="form"
        v-on="$listeners"
    >
        <p v-if="hasRequiredFields" class="form-requirements screen-reader-text">
            ({{ $root.translate("Fields marked with an asterisk (*) are required") }})
        </p>
        <slot></slot>
    </form>
</template>

<script>
export default {
    name: "BaseForm",
    props: {
        hasRequiredFields: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style lang="scss">
.form-requirements {
    text-align: center;
    font-size: 0.8em;
}
</style>
