<template>
    <page
        id="schoolboards-list"
        :title="$root.translate('School Board:::School Boards', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div class="page-outer-container">
            <div class="page-inner-container">
                <h3>
                    {{ $root.translate('School Board:::School Boards', {}, 2) }}
                </h3>
                <data-table
                    id="schoolboards-list"
                    entity-name="School Board:::School Boards"
                    :columns="visible_columns"
                    :rows="filtered_rows"
                    :search="state.search"
                    :total="state.total"
                    :found="state.found"
                    :current-page="state.page"
                    :total-pages="nb_pages"
                    :can-add="true"
                    @sort="sort_by"
                    @search="search"
                    @page="goto_page"
                    @add="record_add"
                    @click="record_edit"
                >
                    <template
                        v-slot:buttons
                    >
                        <base-link
                            :href="export_url"
                            class="btn btn-link"
                            download
                        >
                            <open-icon glyph="file-csv" :title="$root.translate('Export Data (CSV)')" />
                        </base-link>
                    </template>
                    <template
                        v-slot:summary="{ row }"
                    >
                        <h6>{{ row.name }}</h6>
                    </template>
                    <template
                        v-slot:tbody="{ row, field }"
                    >
                        <div v-if="field === '_buttons'" class="text-right">
                            <default-button
                                flavor="link"
                                color="error"
                                @click.prevent.stop="confirm_record_delete(row)"
                            >
                                <open-icon glyph="close" library="hop" />
                            </default-button>
                        </div>
                        <span v-else>{{ row[field] }}</span>
                    </template>
                </data-table>
            </div>
        </div>
        <modal-dialog
            id="schoolboard-editor-dialog"
            :show.sync="editing"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("School Board Editor") }}</span>
            </template>
            <base-form
                v-if="!!edited_record"
                :has-required-fields="true"
                @submit.prevent="record_create"
            >
                <form-input
                    id="name"
                    v-model="edited_record.name"
                    name="name"
                    :required="true"
                    :saving="saving('name')"
                    :error="has_error('name')"
                    @input="record_save"
                >
                    <template v-slot:label>{{ $root.translate("Name") }}</template>
                </form-input>
                <form-input
                    id="code"
                    v-model="edited_record.code"
                    name="code"
                    :required="!!edited_record.id"
                    :saving="saving('code')"
                    :error="has_error('code')"
                    @input="record_save"
                >
                    <template v-slot:label>{{ $root.translate("Code/Identifier") }}</template>
                </form-input>
            </base-form>

            <template v-slot:footer>
                <div v-if="!!edited_record && !edited_record.id" class="text-center">
                    <default-button
                        :disabled="creating"
                        class="mr-2"
                        @click.prevent="record_edit_stop"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :waiting="creating"
                        :disabled="creating"
                        color="primary"
                        class="ml-2"
                        @click.prevent="record_create"
                    >
                        {{ $root.translate("Create") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import {
    ModalDialog,
    BaseForm,
    FormInput,
    DefaultButton,
    BaseLink,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_page, is_remote_data_table_source, handles_saving, confirms],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        DefaultButton,
        BaseLink,
        OpenIcon
    },
    methods: {
        record_edit (schoolboard) {
            this.edited_record = schoolboard
            this.editing = true
        },
        record_add () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "schoolboard" }
            ).then(record => {
                this.record_edit(record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        record_edit_stop () {
            this.editing = false
            this.edited_record = {}
        },
        record_save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                if(this.edited_record.id) {
                    return this.save_data_for_record_id(this.entity, this.edited_record.id, this.edited_record)
                }
            }
            return Promise.resolve()
        },
        record_create () {
            if(this.edited_record && !this.edited_record.id) {
                this.creating = true
                this.save_data_for_record_id(this.entity, 0, this.edited_record).then(() => {
                    this.refresh()
                    this.record_edit_stop()
                }).catch(this.receive_error).then(() => {
                    this.creating = false
                })
            } else this.record_edit_stop()
        },
        confirm_record_delete (schoolboard) {
            if(!schoolboard || !schoolboard.id) this.edited_record = null
            else {
                this.edited_record = schoolboard
                this.confirm(
                    {
                        type: "error",
                        title: this.$root.translate("Remove a school board"),
                        message: this.$root.translate("Are you sure you want to remove {title}?", { title: schoolboard.name }),
                        cancel: this.$root.translate("Keep"),
                        ok: this.$root.translate("Delete")
                    },
                    "delete-record"
                )
            }
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_record.id
                    }
                ).then(() => {
                    this.done_confirming()
                    this.edited_record = null
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    computed: {
        fields () {
            return ["fieldset::list"]
        }
    },
    data () {
        return {
            entity: "schoolboard",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            edited_record: {},
            editing: false,
            creating: false
        }
    }
}
</script>
