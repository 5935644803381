import {
    format
} from "date-fns"

const string_utilities = {
    methods: {
        /* // **** STRIPE SUPPORT
        stripe_amount (number, recurrence, recurrence_count, format = "0,0.00", force_as_amount) {
            if(!number && !force_as_amount) return this.$root.translate("Free")
            let text = this.nibnut_filter("nibnut.currency", [number / 100, format, force_as_amount ? "" : this.$root.translate("Free")])
            if(recurrence) {
                text += " / "
                if(recurrence_count > 1) text += `${recurrence_count} ${recurrence}s`
                else text += `${recurrence}`
            }
            return text
        },
        stripe_plan_regular_price (plan) {
            if(!plan) return 0
            return this.stripe_amount(plan.amount, plan.recurrence, plan.recurrence_count)
        },
        stripe_plan_price (plan, coupon, raw) {
            if(!plan) return 0
            let price = plan.amount / 100
            if(coupon) {
                if(coupon.amount_off) price -= (coupon.amount_off / 100)
                else price -= price * (coupon.percent_off / 100)
                if(price < 0) price = 0
            }
            if(raw) return price
            return this.stripe_amount(price * 100, plan.recurrence, plan.recurrence_count)
        },
        coupon_terms (coupon) {
            const terms = []
            if(coupon) {
                if(coupon.amount_off) terms.push(this.nibnut_filter("nibnut.currency", [coupon.amount_off / 100]))
                else terms.push(`${this.nibnut_filter("nibnut.number", [coupon.percent_off / 100, "0,0.00"])}%`)
            }
            terms.push(this.$root.translate("off"))
            switch (coupon.duration) {
            case "once":
                terms.push(this.$root.translate("once"))
                break
            case "forever":
                terms.push(this.$root.translate("forever"))
                break
            default:
                terms.push(this.$root.translate("1 month:::{count} months", { count: coupon.duration_in_months }, coupon.duration_in_months))
                break
            }
            return terms.join(" ")
        },
        */
        string_concat () {
            // (separator, component1, component2, ...)
            if(arguments.length < 2) return ""
            const separator = arguments[0]
            const components = Array.from(arguments).slice(1).flat()
            return components.filter(component => !!component).join(separator)
        },
        address_block_line (line, address) {
            if(line === 1) {
                return address.line1
            } else if(line === 2) {
                return this.string_concat(", ", address.city, this.string_concat(" ", address.state, address.zip))
            }
            return ""
        },
        address_one_line (address) {
            return this.string_concat(", ", this.address_block_line(1, address), this.address_block_line(2, address))
        },
        full_name (record) {
            return this.string_concat(" ", record.first_name, record.last_name)
        }
    },
    computed: {
        app_version () {
            // eslint-disable-next-line
            if(process.env.VUE_APP_VERSION) return process.env.VUE_APP_VERSION
            return ""
        },
        current_year () {
            return format(new Date(), "yyyy")
        }
    }
}

export default string_utilities
