<template>
    <div
        v-if="!!record"
        class="walker card"
    >
        <div class="card-header text-left">
            <div class="d-flex align-items-center">
                <avatar
                    :url="url"
                    :initials="initials(record)"
                    class="flex-static"
                />
                <div class="flex-grow ml-4">
                    {{ record.first_name }} {{ record.last_name }}
                </div>
            </div>
        </div>
        <div class="card-body">
            <form-input
                :id="field_id('school_name')"
                v-model="record.school_name"
                name="school_name"
                :required="true"
                :saving="saving('school_name')"
                :error="has_error('school_name')"
                @input="save"
            >
                <template v-slot:label>{{ $root.translate("Which school does the walker attend?") }}</template>
            </form-input>
            <form-select
                :id="field_id('schoolboard_id')"
                name="schoolboard_id"
                v-model="record.schoolboard_id"
                data-source="schoolboard"
                :empty-value="null"
                :show-all="true"
                :required="true"
                :saving="saving('schoolboard_id')"
                :error="has_error('schoolboard_id')"
                @input="save"
            >
                <template v-slot:label>{{ $root.translate("Which School Board is the walker’s school in?") }}</template>
            </form-select>
        </div>
    </div>
</template>

<script>
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import is_avatar_owner from "@/custom/mixins/IsAvatarOwner"

import {
    FormInput,
    FormSelect
} from "@/nibnut/components"

export default {
    name: "WalkerVerification",
    mixins: [profile_utilities, handles_saving, is_avatar_owner],
    components: {
        FormInput,
        FormSelect
    },
    methods: {
        field_id (name) {
            return `${name}-${this.record.id}`
        },
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id && (this.record.id > 0)) return this.save_field_for_record_id(this.entity, this.record.id, value, field)
            }
            return Promise.resolve()
        }
    },
    computed: {
        url () {
            if(!this.record) return ""
            const avatar = this.avatar_by_id(this.record.avatar_id)
            return avatar ? avatar.url : ""
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "walker"
        }
    }
}
</script>
