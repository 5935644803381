import { mapGetters } from "vuex"

import addl_string_utilities from "@/custom/mixins/AddlStringUtilities"

import Avatar from "@/custom/components/Avatar"

const is_avatar_owner = {
    name: "IsAvatarOwner",
    mixins: [addl_string_utilities],
    components: {
        Avatar
    },
    methods: {
        avatar_by_id (avatar_id) {
            if(avatar_id) return this.entity_records("attachment").find(avatar => avatar.id === avatar_id) || {}
            return {}
        }
    },
    computed: {
        ...mapGetters(["entity_records"])
    }
}

export default is_avatar_owner
