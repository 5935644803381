<template>
    <div class="authentication-panel panel-walkers page-outer-container">
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            :has-required-fields="false"
            class="page-inner-container"
            @submit.prevent.stop="login"
        >
            <div class="panel-header">
                <app-logo />
            </div>
            <div class="panel-body">
                <h1>{{ $root.translate("Choose a profile") }}</h1>
                <h6 v-if="auto_join_team">
                    {{ $root.translate("The profile you select will join team \"{name}\"", { name: auto_join_team.name }) }}
                </h6>
                <ul class="d-flex justify-content-center">
                    <li
                        v-for="walker in walkers"
                        :key="walker.id"
                    >
                        <default-button
                            flavor="link"
                            size="sm"
                            @click.prevent="pick(walker)"
                        >
                            <avatar
                                :url="url(walker)"
                                :initials="initials(walker)"
                            />
                            <div>{{ walker.first_name }} {{ walker.last_name }}</div>
                        </default-button>
                    </li>
                </ul>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import orderBy from "lodash/orderBy"

import is_panel from "./IsPanel"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import is_avatar_owner from "@/custom/mixins/IsAvatarOwner"

export default {
    mixins: [is_panel, profile_utilities, is_avatar_owner],
    mounted () {
        this.reload()
        // this.maybe_move_on()
    },
    watch: {
        $route: "reload",
        profile_id: "reload",
        walker_uuid: "reload",
        "walkers.length": "maybe_move_on"
    },
    methods: {
        reload () {
            if(!!this.profile_id && !this.walker_uuid) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "walker",
                        query: { user_id: this.profile_id }
                    }
                ).then(() => {
                    this.maybe_move_on()
                })
            }
        },
        pick (walker) {
            this.$store.dispatch(
                "SET_CURRENT_WALKER",
                { walker_uuid: walker.uuid, team_id: this.auto_join_team ? this.auto_join_team.id : 0 }
            ).then(() => {
                if(this.walker_uuid) {
                    this.$store.dispatch("UNREQUEST_LOGIN")
                    this.$store.dispatch("AUTO_JOIN_TEAM", { team: null })
                }
                if((this.$route.meta.regional_admin_route || this.$route.meta.admin_route) && !this.is_at_least_regional_admin) this.$router.replace({ name: "home" })
            })
        },
        maybe_move_on () {
            if(this.profile_id) {
                if(!this.walker_uuid && (this.walkers.length === 1)) this.pick(this.walkers[0])
                else {
                    this.$store.dispatch("BOOTSRAPPED")
                    /*
                    this.$store.dispatch(
                        "FETCH_RECORDS",
                        {
                            entity: "walker",
                            query: { user_id: this.profile_id }
                        }
                    ).then(() => {
                        this.$store.dispatch("BOOTSRAPPED")
                    })
                    */
                }
            }
        },
        url (walker) {
            const avatar = this.avatar_by_id(walker.avatar_id)
            return avatar ? avatar.url : ""
        }
    },
    computed: {
        ...mapState(["walker_uuid", "auto_join_team"]),
        walkers () {
            return orderBy(this.entity_records("walker").filter(walker => walker.user_id === this.profile.id), ["first_name", "last_name"], ["asc", "asc"])
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.panel-walkers {
    .btn {
        font-family: $body-font-family;
        font-weight: bold;
        figure + div { margin-top: $unit-1; }
    }
}
</style>
