<template>
    <page
        id="trip-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            v-if="!!edited_record && !confirmed"
            :has-required-fields="false"
            class="page-outer-container"
            @submit.prevent.stop="record_save"
        >
            <section class="page-inner-container text-center">
                <h3>
                    {{ $root.translate('Log my walk') }}
                </h3>
                <form-date-input
                    id="executed_on"
                    ref="first_field"
                    name="executed_on"
                    v-model="edited_record.executed_on"
                    :required="false"
                    position="bottom"
                    placeholder="00/00/00"
                    :max="today"
                    :saving="saving('executed_on')"
                    :error="has_error('executed_on')"
                    class="col-4 col-sm-12 mb-8"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Date") }}</template>
                </form-date-input>
                <form-input
                    id="distance"
                    :value="edited_record.distance / 100"
                    name="distance"
                    type="number"
                    :min="1"
                    :step="1"
                    :required="true"
                    placeholder="0 metres"
                    :saving="saving('distance')"
                    :error="has_error('distance')"
                    class="col-4 col-sm-12 mb-8"
                    @input="save_distance"
                >
                    <template v-slot:label>{{ $root.translate("Distance") }} <small>{{ $root.translate("metres") }}</small></template>
                    <template v-slot:hint>
                        <div class="text-small text-primary text-normal mb-1">{{ $root.translate("1 km = 1000 metres") }}</div>
                        <base-link
                            href="https://www.google.com/maps/dir/?api=1&origin=&destination=current+location&travelmode=walking"
                            target="_blank"
                            class="btn btn-light btn-sm text-normal"
                        >
                            {{ $root.translate("Estimate distance") }}
                        </base-link>
                    </template>
                </form-input>

                <trip-category-input
                    id="category"
                    v-model="edited_record.category"
                    name="category"
                    :required="true"
                    :saving="saving('category')"
                    :error="has_error('category')"
                    class="mb-8"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Purpose") }}</template>
                </trip-category-input>
            </section>

            <div class="text-center mt-16">
                <default-button
                    color="secondary"
                    :waiting="creating_record"
                    @click.prevent="record_save"
                >
                    <span v-if="!edited_record.id">{{ $root.translate("Log") }}</span>
                    <span v-else>{{ $root.translate("Done!") }}</span>
                </default-button>
            </div>
        </base-form>
        <base-form
            v-else-if="!!edited_record && !!confirmed"
            :has-required-fields="false"
            class="page-outer-container"
            @submit.prevent.stop="done"
        >
            <section class="page-inner-container text-center">
                <h3>
                    {{ $root.translate('Logged') }}
                </h3>

                <div class="log-results">
                    <div class="log-result-distance">
                        <span v-if="trip_distance < 1000">
                            <span class="text-large">{{ trip_distance | nibnut.number("0,0") }}</span><span class="text-small">m</span>
                        </span>
                        <span v-else>
                            <span class="text-large">{{ trip_kilometers | nibnut.number("0,0") }}</span>.{{ trip_kilometers_decimals | nibnut.number("00") }}<span class="text-small">km</span>
                        </span>
                    </div>
                    <div class="log-result-date">
                        {{ $root.translate("on {day}, {month} {date}", { day: nibnut_filter("nibnut.date", [edited_record.executed_on, "ccc"]), month: nibnut_filter("nibnut.date", [edited_record.executed_on, "LLL"]), date: nibnut_filter("nibnut.date", [edited_record.executed_on, "dd"]) }) }}
                    </div>
                </div>
            </section>

            <div class="text-center mt-16">
                <default-button
                    color="secondary"
                    @click.prevent="done"
                >
                    {{ $root.translate("Done!") }}
                </default-button>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>

    </page>
</template>

<script type="text/javascript">
import {
    parseISO,
    format
} from "date-fns"

import { is_record_editor_page } from "@/nibnut/mixins"

import {
    BaseForm,
    FormInput,
    FormDateInput,
    DefaultButton,
    BaseLink
} from "@/nibnut/components"
import {
    TripCategoryInput
} from "@/custom/components"

export default {
    mixins: [is_record_editor_page],
    components: {
        BaseForm,
        FormInput,
        FormDateInput,
        DefaultButton,
        BaseLink,
        TripCategoryInput
    },
    methods: {
        grab_record_id () {
            const int_record_id = parseInt(this.$route.params.id)
            this.record_id = (`${int_record_id}` === this.$route.params.id) ? int_record_id : this.$route.params.id
            this.confirmed = false
        },
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        },
        save_distance (value, field) {
            this.save(value * 100, field)
        },
        record_save () {
            const edited_record = { ...this.edited_record }
            if(!edited_record.id) {
                if(typeof edited_record.executed_on === "string") edited_record.executed_on = parseISO(edited_record.executed_on)
                edited_record.executed_on = format(edited_record.executed_on, "yyyy-MM-dd")
                this.creating_record = true
                this.save_data_for_record_id(this.entity, 0, edited_record).then(record => {
                    this.creating_record = false
                    if(record) this.confirmed = true
                })
            } else this.done()
        },
        done () {
            this.$router.go(-1)
        }
    },
    computed: {
        page_title () {
            return this.$root.translate("Log my walk")
        },
        today () {
            return new Date()
        },
        trip_distance () {
            if(this.edited_record) return this.edited_record.distance / 100
            return 0
        },
        trip_kilometers () {
            return Math.floor(this.trip_distance / 1000)
        },
        trip_kilometers_decimals () {
            return (this.trip_distance / 1000) - this.trip_kilometers
        }
    },
    data () {
        return {
            entity: "trip",
            entityName: "Trip:::Trips",
            confirmed: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#trip-editor {
    .form-input {
        border-color: transparent;
        background-color: $gray-color-light;
    }
    .log-results {
        display: inline-block;
        text-align: center;
        background-color: #F0F0F0;
        border-radius: 10rem;
        padding: 1rem 2.8rem;
        margin: 0 auto;

        .log-result-distance {
            font-size: 2rem;
            line-height: 2rem;
            .text-large {
                font-size: 3.75rem;
            }
            .text-small {
                font-size: 1rem;
            }
        }

        @media (max-width: $size-sm) {
            width: auto;
        }
    }
}
</style>
