<template>
    <div class="remote-page">
        <prismic-rich-text v-if="!!remoteData.title" :field="remoteData.title" />

        <slot></slot>

        <prismic-rich-text v-if="!!remoteData.content" :field="remoteData.content" />
        <section
            v-for="(section, index) in remoteData.sections"
            :key="`section-${index}`"
            :class="{ [section.slice_type]: true }"
        >
            <template v-if="section.slice_type === 'introduction'">
                <prismic-rich-text v-if="!!section.primary.content" :field="section.primary.content" />
            </template>
            <template v-if="section.slice_type === 'content'">
                <prismic-rich-text v-if="!!section.primary.content" :field="section.primary.content" />
            </template>
            <template v-if="section.slice_type === 'images'">
                <div class="columns">
                    <div
                        v-for="item in section_images(section)"
                        :key="item.image.url"
                        class="column col-sm-12"
                    >
                        <div
                            class="nibnut-aspect-ratio-box nibnut-ratio-square"
                        >
                            <div>
                                <picture class="img-responsive">
                                    <source
                                        v-for="(screen_width, size) in sizes_for_image(item.image)"
                                        :key="size"
                                        :media="`(max-width: ${screen_width-1}px)`"
                                        :srcset="item.image[size].url"
                                    />
                                    <base-image
                                        :src="item.image.url"
                                        :alt="item.image.alt || ''"
                                        class="img-responsive"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </section>
    </div>
</template>

<script type="text/javascript">
export default {
    name: "RemotePageContent",
    components: {
    },
    methods: {
    },
    computed: {
    },
    props: {
        remoteData: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.remote-page {
    font-weight: normal;

    h1 {
        text-align: center;
        margin-bottom: 5rem;
    }

    @media(max-width:$size-md) {
        h1 {
            text-align: left;
        }
    }
}
</style>
