<template>
    <page
        id="walker-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            v-if="!!edited_record"
            :has-required-fields="false"
            class="page-outer-container"
            @submit.prevent.stop="login"
        >
            <section class="page-inner-container">
                <div class="columns align-items-center justify-content-center">
                    <div class="column flex-static">
                        <avatar
                            :url="avatar.url"
                            :initials="record_initials"
                            color="gray"
                        />
                    </div>
                    <div class="column flex-static text-bold">
                        {{ $root.translate("Hi, {name}", { name: full_name(edited_record) }) }}
                    </div>
                </div>
                <div class="columns align-items-center justify-content-center mt-2">
                    <div class="column flex-static">
                        <default-button
                            color="light"
                            size="sm"
                            @click="pick_avatar(save_avatar)"
                        >
                            {{ $root.translate("Edit") }}
                        </default-button>
                    </div>
                    <div class="column flex-static text-bold d-invisible">
                        {{ $root.translate("Hi, {name}", { name: full_name(edited_record) }) }}
                    </div>
                </div>
            </section>
            <section class="page-inner-container">
                <h3>
                    <open-icon glyph="user" size="lg" library="hop" />
                    {{ $root.translate('Walker Profile') }}
                </h3>
                <walker-editor
                    :record="edited_record"
                    class="content"
                    @input="save"
                />
            </section>

            <section v-if="!!edited_record.id" class="page-inner-container">
                <h3>
                    {{ $root.translate('My Past Trips') }}
                </h3>
                <trips
                    :walker-id="edited_record.id"
                />
            </section>

            <div class="text-center">
                <default-button
                    v-if="!!edited_record && !!edited_record.id"
                    color="primary"
                    @click.prevent="confirm_record_delete"
                >
                    {{ $root.translate("Delete Walker") }}
                </default-button>
                <div v-else class="columns">
                    <div class="column">
                        <default-button
                            @click.prevent="$router.go(-1)"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                    </div>
                    <div class="column text-right">
                        <default-button
                            color="primary"
                            @click.prevent="record_save"
                        >
                            {{ $root.translate("Create") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>

        <avatar-picker
            :show.sync="picking_avatar"
            @pick="picked_callback"
        />
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"
import { is_avatar_owner, is_avatar_picker, addl_profile_utilities } from "@/custom/mixins"

import {
    BaseForm,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    WalkerEditor
} from "@/custom/components"
import Trips from "./Trips"

export default {
    mixins: [is_record_editor_page, is_avatar_owner, is_avatar_picker, addl_profile_utilities],
    components: {
        BaseForm,
        DefaultButton,
        OpenIcon,
        WalkerEditor,
        Trips
    },
    watch: {
        walker_uuid: "reload"
    },
    methods: {
        grab_record_id () {
            if(!this.is_at_least_administrator) this.record_id = this.walker_uuid
            else {
                const int_id = parseInt(this.$route.params.id)
                this.record_id = (`${int_id}` === `${this.$route.params.id}`) ? int_id : this.$route.params.id
                if(!this.record_id) this.record_id = this.walker_uuid
            }
        },
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        },
        save_avatar (avatar_id) {
            this.save(avatar_id, "avatar_id")
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.first_name }),
                    message: this.$root.translate("Do you really want to delete this walker? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            if(this.record_id === this.walker_uuid) {
                this.$store.dispatch("SET_CURRENT_WALKER", { walker_uuid: 0 })
                this.$router.replace({ name: "profile" })
            } else this.$router.go(-1)
        }
    },
    computed: {
        page_title () {
            const record = this.edited_record
            const entity_name = this.$root.translate(this.entityName, {}, 1)
            if(!record || !record.id) return this.$root.translate(`New ${entity_name}`)
            return record.first_name || entity_name
        },
        can_reload () {
            return this.profile_id && this.walker_uuid
        },
        me () {
            const record = this.edited_record
            return !!this.walker_uuid && !!record && ((this.walker_uuid === record.uuid) || (this.walker_uuid === record.id))
        },
        cleanup_before_reload () {
            return false
        },
        record_initials () {
            return this.initials(this.edited_record)
        },
        avatar () {
            if(!this.edited_record) return {}
            return this.avatar_by_id(this.edited_record.avatar_id)
        }
    },
    data () {
        return {
            entity: "walker",
            entityName: "Walker:::Walkers"
        }
    }
}
</script>
