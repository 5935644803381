import string_utilities from "@/nibnut/mixins/StringUtilities"

const addl_string_utilities = {
    mixins: [string_utilities],
    methods: {
        initials (record) {
            if(record) {
                const initials = []
                if(record.first_name) initials.push(record.first_name[0])
                if(record.last_name) initials.push(record.last_name[0])
                if(!record.first_name && !record.last_name && record.name) initials.push(record.name[0])
                return initials.join("").toUpperCase()
            }
            return ""
        }
    }
}

export default addl_string_utilities
