<template>
    <ul class="milestones">
        <li
            v-for="(milestone, index) in filtered_rows"
            :key="milestone.id"
            :class="{ active: !index || (kilometers >= milestone.sort_order) }"
        >
            {{ milestone.title }}
            <div v-if="milestone.sort_order > 1">
                {{ milestone.sort_order | nibnut.number("0,0") }}&nbsp;km
            </div>
            <div
                class="walking-stick"
                :style="stick_style(milestone, index)"
            ></div>
        </li>
    </ul>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

export default {
    name: "Milestones",
    mixins: [is_remote_data_table_source],
    watch: {
        localization: "reset"
    },
    methods: {
        stick_style (milestone, index) {
            if(!this.kilometers || (milestone.sort_order <= 1)) return false
            const unit_6 = 1.2
            if(this.kilometers >= milestone.sort_order) return { height: `${unit_6}rem` }
            const previous_milestone_km = index ? this.filtered_rows[index - 1].sort_order : 0
            const distance = milestone.sort_order - previous_milestone_km
            const delta = this.kilometers - previous_milestone_km
            const progression = delta / distance
            return { bottom: `calc(100% + (${unit_6}rem * (1 - ${progression})))`, height: `${unit_6 * progression}rem` }
        }
    },
    computed: {
        ...mapState(["localization"]),
        fields () {
            return ["fieldset::display"]
        }
    },
    props: {
        kilometers: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "custom_text",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "sort_order",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                type: 4,
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.milestones {
    display: inline-block;
    margin: 0;
    & > li {
        text-align: center;
        font-size: 0.7rem;
        background-color: $brand-yellow;
        border-radius: 0.9rem;
        line-height: 1.1em;
        padding: $unit-2 $unit-4;

        & + li {
            position: relative;
            margin: $unit-10 0;

            &:before {
                content: " ";
                position: absolute;
                bottom: 100%;
                width: $border-width-lg;
                height: $unit-6;
                margin: $unit-2 0;
                left: 50%;
                transform: translate(-50%, 0);
                background: $primary-color;
                z-index: $zindex-1;
            }
            & > div.walking-stick {
                position: absolute;
                bottom: 100%; // calc(100% + ($unit-6 * (1 - progression)))
                width: $border-width-lg;
                height: 0; // $unit-6 * progression
                margin: $unit-2 0;
                left: 50%;
                transform: translate(-50%, 0);
                background: $brand-yellow;
                z-index: $zindex-2;
            }
        }

        &.active {
            background-color: $primary-color;
            color: white;
        }
    }
}
</style>
