<template>
    <div class="authentication">
        <welcome
            v-if="current_panel_id==='welcome'"
            @goto="current_panel_id=$event"
        />
        <slides
            v-else-if="current_panel_id==='slides'"
            @goto="current_panel_id=$event"
        />
        <login
            v-else-if="current_panel_id==='login'"
            v-model="email"
            :label-less="labelLess"
            :dismissable="dismissable"
            @goto="current_panel_id=$event"
            @dismiss="dismiss"
        />
        <lost-password
            v-else-if="current_panel_id==='password'"
            v-model="email"
            :label-less="labelLess"
            :dismissable="dismissable"
            @goto="current_panel_id=$event"
            @dismiss="dismiss"
        />
        <reset-password
            v-else-if="current_panel_id==='reset'"
            v-model="email"
            :label-less="labelLess"
            :dismissable="dismissable"
            @goto="current_panel_id=$event"
            @dismiss="dismiss"
        />
        <verification
            v-else-if="current_panel_id==='verification'"
            @goto="current_panel_id=$event"
        />
        <walker-picker
            v-else-if="current_panel_id==='walkers'"
            @goto="current_panel_id=$event"
        />
        <img src="@/assets/img/Walkers.png" class="hide-sm" />
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

import Welcome from "./Welcome"
import Slides from "./Slides"
import Login from "./Login"
import LostPassword from "./LostPassword"
import ResetPassword from "./ResetPassword"
import Verification from "./Verification"
import WalkerPicker from "./WalkerPicker"

export default {
    mixins: [profile_utilities],
    components: {
        Welcome,
        Slides,
        Login,
        LostPassword,
        ResetPassword,
        Verification,
        WalkerPicker
    },
    watch: {
        profile_id: "maybe_dismiss",
        walker_uuid: "maybe_dismiss",
        // "app_context.settings": "maybe_switch_panel",
        "login_request.panel_id": "maybe_switch_panel",
        current_panel_id: "control_language_picker"
    },
    mounted () {
        this.maybe_switch_panel()
    },
    methods: {
        dismiss () {
            if(this.dismissable) this.$store.dispatch("UNREQUEST_LOGIN")
        },
        maybe_dismiss () {
            if(this.profile_id && this.walker_uuid) this.shown = false
            else if(!this.walker_uuid) this.$store.dispatch("REQUEST_LOGIN", { panel_id: "walkers" }) // this.current_panel_id = "walkers"
        },
        maybe_switch_panel () {
            if(this.shown) { // && !!this.app_context && !!this.app_context.settings) {
                if(this.login_request.panel_id === true) {
                    if(!this.is_app_user) this.current_panel_id = "welcome"
                    else this.current_panel_id = "login"
                } else if((this.login_request.panel_id === "signup") || (this.current_panel_id === "signup")) {
                    const route = { name: this.login_request.panel_id }
                    const matches = window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/)
                    if(matches) route.params = { invitation: matches[1] }
                    this.$router.replace(route).catch(() => {})
                } else this.current_panel_id = this.login_request.panel_id
            }
        },
        control_language_picker () {
            var node = document.getElementById("guest-language-picker")
            if(node) {
                if(this.current_panel_id && this.current_panel_id.match(/^(welcome|login|reset)$/i)) node.classList.remove("d-hide")
                else node.classList.add("d-hide")
            }
        }
    },
    computed: {
        ...mapState(["profile_id", "walker_uuid", "login_request", "app_context"]),
        shown: {
            get () {
                return (!this.profile_id || !this.walker_uuid) && !!this.login_request && !!this.login_request.panel_id
            },
            set (show) {
                if(!show) this.dismiss()
            }
        },
        dismissable () {
            if(this.$route.meta) return !this.$route.meta.admin_route && !this.$route.meta.regional_admin_route && !this.$route.meta.login_required
            // eslint-disable-next-line
            return !!process.env.VUE_APP_DEFAULT_PUBLIC_PATH && process.env.VUE_APP_DEFAULT_PUBLIC_PATH.match(/^\//)
        }
    },
    props: {
        labelLess: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            current_panel_id: "",
            email: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.authentication {
    position: relative;
    background: $brand-orange no-repeat bottom left url('~@/assets/img/Graphic_intro_desktop.png');
    background-size: 52%;
    min-height: 100vh;

    .authentication-panel {
        position: relative;
        padding-top: 3rem;
        padding-bottom: 8rem;
        text-align: center;
        min-height: 100vh;
        z-index: $zindex-2;
        @media (max-width: $size-sm) {
            padding-bottom: 3rem;
        }

        .img-responsive {
            margin: 0 auto;
            @media (max-width: $size-sm) {
                max-width: 260px;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            height: 100%;

            .panel-header, .panel-footer {
                flex: 0 0 auto;
            }
            .panel-header {
                margin-bottom: 1.5rem;
            }
            .panel-body {
                flex: 1 0 auto;

                p {
                    white-space: normal;
                }
            }
        }
        & + img {
            display: none;
        }

        &.panel-welcome {
            h1 {
                max-width: 334px;
                margin: 0 auto;
            }
            & + img {
                display: inline;
            }
        }
        &.panel-slides {
            figure {
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.panel-login {
            .text-controls {
                margin-top: $unit-4;
                label.form-label {
                    padding: 0;
                }
                label.form-label, .bnt.btn-link {
                    font-size: 0.7rem;
                }
            }
        }
    }
    & > img {
        position: absolute;
        bottom : 0;
        right: 10%;
        width: 45vw;
        z-index: $zindex-1;
    }
    @media (max-width: $size-sm) {
        background: $brand-orange no-repeat center bottom url('~@/assets/img/Graphic_intro.png');
    }
}
</style>
