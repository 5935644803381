<template>
    <div
        v-if="!!record"
        class="walker"
    >
        <div
            class="tile tile-centered list-tile"
        >
            <div class="tile-icon">
                <open-icon glyph="user" size="lg" library="hop" />
            </div>
            <div class="tile-content">
                <div class="tile-title">
                    {{ record.first_name }} {{ record.last_name }}<span v-if="isCaptain"> ({{ $root.translate("Captain") }})</span>
                </div>
            </div>
            <div v-if="!isCaptain" class="tile-action">
                <default-button
                    @click.prevent="remove"
                >
                    <open-icon glyph="close" size="lg" library="hop" />
                </default-button>
            </div>
        </div>
    </div>
</template>

<script>
import { profile_utilities } from "@/nibnut/mixins"

import {
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "Member",
    mixins: [profile_utilities],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        remove () {
            this.$emit("delete", this.record)
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        isCaptain: {
            type: Boolean,
            default: false
        }
    }
}
</script>
