<template>
    <modal-dialog
        id="confirm"
        :show.sync="shown"
        :dismissable="dismissable"
        :class="{ [`modal-${type}`]: true }"
        class="modal-confirmation"
    >
        <template v-slot:title>
            <slot name="title">
                <span
                    class="h5"
                >
                    <open-icon
                        v-if="!!icon"
                        :glyph="icon"
                    />
                    {{ title }}
                </span>
            </slot>
        </template>
        <template v-slot:footer>
            <slot name="footer">
                <default-button
                    :disabled="waiting"
                    class="mr-2"
                    @click.prevent="dismiss(false)"
                >
                    {{ cancel }}
                </default-button>
                <default-button
                    :disabled="waiting"
                    :waiting="waiting"
                    :class="{ [`btn-${type}`]: true }"
                    class="ml-2"
                    @click.prevent="dismiss(true)"
                >
                    {{ ok }}
                </default-button>
            </slot>
        </template>

        <slot>
            <p class="text-center">{{ message }}</p>
        </slot>
    </modal-dialog>
</template>

<script type="text/javascript">
import ModalDialog from "./ModalDialog"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "Confirmation",
    components: {
        ModalDialog,
        DefaultButton,
        OpenIcon
    },
    methods: {
        dismiss (confirmed) {
            if(confirmed) this.$emit("confirmed")
            else this.$emit("cancel")
        }
    },
    computed: {
        shown: {
            get: function () {
                return true
            },
            set: function (show) {
                if(!show) this.dismiss(false)
            }
        }
    },
    props: {
        type: {
            type: String,
            validator: prop => !!prop && prop.match(/^(success|error|warning)$/),
            default: "warning",
            required: true
        },
        title: {
            type: String,
            default: "",
            required: true
        },
        message: {
            type: String,
            default: "",
            required: true
        },
        icon: {
            type: String,
            default: ""
        },
        dismissable: {
            type: Boolean,
            default: false
        },
        waiting: {
            type: Boolean,
            default: false
        },
        cancel: {
            type: String
        },
        ok: {
            type: String
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.modal.modal-confirmation{
    .modal-container {
        padding: 0;

        .modal-header,
        .modal-body,
        .modal-footer {
            padding: $unit-4 $unit-8;
        }
    }

    &.modal-success {
        .modal-container > .modal-header {
            background-color: $success-color;
            color: $light-color;
        }
    }
    &.modal-warning {
        .modal-container > .modal-header {
            background-color: $warning-color;
            color: $light-color;
        }
    }
    &.modal-error {
        .modal-container > .modal-header {
            background-color: $error-color;
            color: $light-color;
        }
    }
}
</style>
