<template>
    <figure
        :class="{ [`${color}-color`]: true }"
        class="avatar avatar-xl"
        :data-initial="url ? null : initials"
    >
        <img v-if="url" :src="url" :alt="initials">
    </figure>
</template>

<script>
export default {
    name: "Avatar",
    props: {
        url: {
            type: String,
            default: ""
        },
        initials: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            validator: prop => !!prop && prop.match(/^(light|gray)$/),
            default: "light"
        }
    }
}
</script>
