<template>
    <div class="authentication-panel panel-welcome page-outer-container">
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            :has-required-fields="false"
            class="page-inner-container"
            @submit.prevent.stop="login"
        >
            <div class="panel-header">
                <app-logo />
            </div>
            <div class="panel-body">
                <div
                    v-if="!loaded"
                    class="loader-container"
                >
                    <loader size="sm" />
                </div>
                <h1 v-else-if="text">{{ text.title }}</h1>
            </div>

            <div v-if="loaded" class="panel-footer text-center">
                <default-button
                    id="last_focusable"
                    @click.prevent="goto('slides')"
                >
                    {{ $root.translate("Let's go!") }}
                </default-button>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import { mapState } from "vuex"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import is_panel from "./IsPanel"

import Loader from "@/custom/components/Loader"

export default {
    mixins: [is_panel, is_remote_data_table_source, profile_utilities],
    components: {
        Loader
    },
    watch: {
        localization: "reset"
    },
    methods: {
        post_load () {
            if(this.page_autoscroll) this.page_autoscroll()
            this.loaded = true
        },
        filter_rows (rows) {
            return rows
        }
    },
    computed: {
        ...mapState(["localization"]),
        state_identifier () {
            return "welcome"
        },
        can_refresh () {
            return true
        },
        fields () {
            return ["fieldset::display"]
        },
        text () {
            const type = this.constants("custom_text_types", "TYPE_WELCOME_TEXT").id
            return this.rows.find(text => text.type === type) || null
        }
    },
    data () {
        return {
            entity: "custom_text",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: "group",
                filter: "welcome",
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            loaded: false
        }
    }
}
</script>
