const routes = [
    { path: "/", name: "home", component: () => import("@/views/Dashboard"), meta: { login_required: true, back: null } },
    { path: "/signup", name: "signup", component: () => import("@/views/user/Edit"), meta: { login_required: false, back: { name: "home", params: { panel_id: "login" } } } },

    { path: "/account", name: "profile", component: () => import("@/views/user/Edit"), meta: { login_required: true, back: { name: "home" } } },
    { path: "/users", name: "user.list", component: () => import("@/views/user/List"), meta: { admin_route: true, scroll_position: true, back: { name: "home" } } },
    { path: "/user/:id?", name: "user.edit", component: () => import("@/views/user/Edit"), meta: { admin_route: true, back: { name: "user.list" } } },

    { path: "/profile", name: "walker.profile", component: () => import("@/views/walker/Edit"), meta: { login_required: true, back: true } },
    { path: "/walkers", name: "walker.list", component: () => import("@/views/walker/List"), meta: { admin_route: true, back: { name: "home" } } },
    { path: "/walker/:id?", name: "walker.edit", component: () => import("@/views/walker/Edit"), meta: { admin_route: true, back: { name: "walker.list" } } },

    { path: "/trip/:id?", name: "trip.edit", component: () => import("@/views/trip/Edit"), meta: { login_required: true, back: true } },

    { path: "/teams", name: "team.list", component: () => import("@/views/team/List"), meta: { regional_admin_route: true, scroll_position: true, back: { name: "home" } } },
    { path: "/team/:id/dashboard", name: "team.dashboard", component: () => import("@/views/team/Dashboard"), meta: { login_required: true, back: true } },
    { path: "/team/:id?", name: "team.edit", component: () => import("@/views/team/Edit"), meta: { login_required: true, back: true } },

    { path: "/faq", name: "faq", component: () => import("@/views/Faq/View"), meta: { login_required: false, back: true } },
    { path: "/challenges", name: "challenges", component: () => import("@/views/achievement/Challenges"), meta: { admin_route: true, back: true } },
    { path: "/schoolboards", name: "schoolboards", component: () => import("@/views/schoolboard/List"), meta: { admin_route: true, back: true } },
    { path: "/settings", name: "settings", component: () => import("@/views/Settings"), meta: { admin_route: true, back: { name: "home" } } },

    { path: "/page/:slug", name: "page", component: () => import("@/views/RemotePage") }
]

export default routes
