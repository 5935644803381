import misc_utilities from "@/nibnut/mixins/MiscUtilities"

const calculator_utilities = {
    name: "CalculatorUtilities",
    mixins: [misc_utilities],
    methods: {
        standardized_distance (distance) {
            return distance / 100
        },
        steps_for_distance (distance) {
            return (this.standardized_distance(distance) / 1000) * this.setting("steps_per_km")
        },
        ghgs_for_distance (distance) {
            return (this.standardized_distance(distance) / 1000) * this.setting("kg_per_km") // returns kilometers
        }
    }
}

export default calculator_utilities
