<template>
    <div
        v-if="!!record"
        :class="{ active }"
        class="walker expandable-list"
    >
        <div
            class="tile tile-centered list-tile"
        >
            <div class="tile-icon">
                <open-icon glyph="user" size="lg" library="hop" />
            </div>
            <div class="tile-content">
                <div class="tile-title">
                    <span v-if="!!isCaptain">{{ $root.translate("Team Captain") }}</span>
                    <span v-else-if="!record.first_name && !record.last_name">{{ `${$root.translate("Walker")} #${index + 1}` }}</span>
                    <span v-else>{{ record.first_name }} {{ record.last_name }}</span>
                    <span v-if="!!record.team_captain"> ({{ record.team_name }}, {{ $root.translate("Team Captain") }})</span>
                    <span v-else-if="!!record.team_name"> ({{ record.team_name }})</span>
                </div>
            </div>
            <div class="tile-action">
                <default-button
                    v-if="!!record.id && !alwaysExpanded"
                    flavor="link"
                    @click.prevent="toggle"
                >
                    <span v-if="expanded">{{ $root.translate("Hide") }}</span>
                    <span v-else>{{ $root.translate("Edit") }}</span>
                </default-button>
                <default-button
                    v-if="deletable && !record.team_captain"
                    @click.prevent="remove"
                >
                    <open-icon glyph="close" size="lg" library="hop" />
                </default-button>
            </div>
        </div>
        <walker-editor
            :record="record"
            :can-pick-avatar="true"
            :can-set-school="!isCaptain"
            :field-id-suffix="isCaptain ? 'captain' : ''"
            :disabled="saving() && !record.id"
            :saving-field-ids="saving_field_ids"
            :errors="errors"
            class="content"
            @input="save"
            @pick-avatar="$emit('pick-avatar', save_avatar)"
        >
            <form-toggle-input
                v-if="!profile_id && !!teamName"
                id="_team_member"
                name="_team_member"
                type="checkbox"
                v-model="record._team_member"
                :required="false"
                class="text-left pl-1"
            >
                {{ $root.translate('Add to my team') }}
            </form-toggle-input>
        </walker-editor>

        <div v-if="!!profile_id && !record.id && !isCaptain" class="columns mt-5">
            <div class="column col-6">
                <default-button
                    :block="true"
                    @click.prevent="$emit('reset')"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
            </div>
            <div class="column col-6">
                <default-button
                    color="primary"
                    :block="true"
                    @click.prevent="create_walker"
                >
                    {{ $root.translate("Save") }}
                </default-button>
            </div>
        </div>
    </div>
</template>

<script>
import { profile_utilities, handles_saving } from "@/nibnut/mixins"

import {
    FormToggleInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    WalkerEditor
} from "@/custom/components"

export default {
    name: "Walker",
    mixins: [profile_utilities, handles_saving],
    components: {
        FormToggleInput,
        DefaultButton,
        OpenIcon,
        WalkerEditor
    },
    mounted () {
        this.grab_record_id()
    },
    watch: {
        record: "grab_record_id",
        savingErrors: "sync_errors"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.record ? this.record.id : 0
        },
        sync_errors () {
            if(!this.savingErrors) this.clear_all_errors()
            else {
                Object.keys(this.savingErrors).forEach(key => {
                    this.add_error(key, this.savingErrors[key])
                })
            }
        },
        toggle () {
            if(!!this.record && !!this.record.id) this.expanded = !this.expanded
        },
        remove () {
            this.$emit("delete", this.record)
        },
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.profile_id && !!value) {
                    if(this.record.id && (this.record.id > 0)) return this.save_field(this.record[field], field)
                    return
                }
                if(this.record.id && (this.record.id > 0)) return this.save_field(this.record[field], field)
            }
            return Promise.resolve()
        },
        save_avatar (avatar_id) {
            this.save(avatar_id, "avatar_id")
        },
        create_walker () {
            this.save_data_for_record_id(this.entity, 0, this.record).then(record => {
                this.$emit("reset")
            }).catch(this.receive_error)
        }
    },
    computed: {
        active () {
            return this.expanded || this.alwaysExpanded
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        deletable: {
            type: Boolean,
            default: false
        },
        alwaysExpanded: {
            type: Boolean,
            default: false
        },
        isCaptain: {
            type: Boolean,
            default: false
        },
        teamName: {
            type: String,
            default: ""
        },
        savingErrors: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            entity: "walker",
            expanded: false
        }
    }
}
</script>
