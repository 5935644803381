<template>
    <page
        id="user-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            v-if="!!edited_record"
            :has-required-fields="false"
            class="page-outer-container"
            @submit.prevent.stop="login"
        >
            <section class="page-inner-container">
                <h3>
                    <open-icon glyph="locker" size="lg" library="hop" />
                    {{ $root.translate('Account Info') }}
                </h3>
                <form-input
                    id="first_focusable"
                    ref="first_field"
                    v-model="edited_record.email"
                    name="email"
                    type="email"
                    :required="true"
                    :maybe-autofilled="true"
                    :placeholder="`${$root.translate('Email')}*`"
                    :saving="saving('email')"
                    :error="has_error('email')"
                    @input="save"
                />
                <password-editor
                    v-if="!profile_id"
                    id="password"
                    v-model="edited_record.password"
                    type="password"
                    name="password"
                    :required="true"
                    :maybe-autofilled="true"
                    :placeholder="`${$root.translate('Password')}*`"
                    :saving="saving('password')"
                    :error="has_error('password')"
                    @input="save"
                    @blur="save"
                />
                <feedback-button
                    v-else-if="!me"
                    :status="send_password_status"
                    :waiting="sending_password"
                    @click.prevent="send_reset_password"
                    class="mt-2"
                >
                    {{ $root.translate("Send a 'Reset Password' Link") }}
                    <template v-slot:message>
                        <div
                            :class="{ 'text-success': (send_password_status === true), 'text-error': (send_password_status === false) }"
                            class="text-center"
                        >
                            {{ send_password_message }}
                        </div>
                    </template>
                </feedback-button>
                <default-button
                    v-else
                    flavor="link"
                    :block="true"
                    @click.prevent="change_password"
                >
                    {{ $root.translate("Change my password") }}
                </default-button>
                    <form-input
                        v-if="sign_up_regional_administrator"
                        id="first_name"
                        v-model="new_walkers[0].first_name"
                        name="first_name"
                        :required="true"
                        :placeholder="`${$root.translate('First Name')}*`"
                        :saving="saving('first_name')"
                        :error="has_error('first_name')"
                    />
                    <form-input
                        v-if="sign_up_regional_administrator"
                        id="last_name"
                        v-model="new_walkers[0].last_name"
                        name="last_name"
                        :required="true"
                        :maxlength="1"
                        :placeholder="`${$root.translate('Last Name Initial')}*`"
                        :saving="saving('last_name')"
                        :error="has_error('last_name')"
                />
                <language-input
                    id="language"
                    v-model="edited_record.language"
                    name="language"
                    :required="true"
                    :saving="saving('language')"
                    :error="has_error('language')"
                    @input="save_language"
                >
                    <template v-slot:label>{{ $root.translate("Choose your language") }}</template>
                </language-input>
                <label class="form-label text-small text-center">{{ $root.translate("*Required fields") }}</label>
            </section>

            <!--
                SIGNUP: if role is captain+, show team component (name, school, captain walker)
                EDIT CAPTAIN+: show "Create team" or Team name button (edit url)
                EDIT OTHER USERS: show team name if team member
                //-->
            <section
                v-if="!!can_have_team"
                class="page-inner-container text-center"
            >
                <h3>
                    <open-icon glyph="user" size="lg" library="hop" />
                    {{ $root.translate('My Team') }}
                </h3>
                <label
                    v-if="!!auto_join_team && !profile_id"
                    class="form-label"
                >
                    {{ auto_join_team.name }}
                </label>
                <label
                    v-else-if="!!team && !!team.id && ((!profile_id && !role_is_at_least_captain(role)) || (!!edited_record && !role_is_at_least_captain(edited_record.role)))"
                    class="form-label"
                >
                    {{ team.name }}
                </label>
                <div
                    v-else-if="!profile_id"
                >
                    <form-input
                        id="team_name"
                        v-model="team_name"
                        name="team_name"
                        :required="false"
                        :live="true"
                        :placeholder="`${$root.translate('Team Name')}`"
                        :saving="saving('team_name')"
                        :error="has_error('team_name')"
                    />
                    <form-input
                        v-if="!!team_name"
                        id="team_school_name"
                        v-model="team_school_name"
                        name="team_school_name"
                        :required="!!team_name"
                        :placeholder="`${$root.translate('School Name')} ${!!team_name ? '*' : ''}`"
                        :saving="saving('team_school_name')"
                        :error="has_error('team_school_name')"
                        @input="captain.school_name = $event"
                    />
                    <form-select
                        v-if="!!team_name"
                        id="team_school_board"
                        name="team_school_board"
                        v-model="team_school_board"
                        data-source="schoolboard"
                        :empty-value="null"
                        :show-all="true"
                        :required="!!team_name"
                        :saving="saving('team_school_board')"
                        :error="has_error('team_school_board')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("School Board") }}<span v-if="!!team_name"> *</span></template>
                    </form-select>
                    <walker
                        v-if="captain"
                        :record="captain"
                        :index="0"
                        :deletable="false"
                        :always-expanded="true"
                        :is-captain="true"
                        class="mt-5"
                        @pick-avatar="pick_avatar(save_avatar)"
                    />
                </div>
                <div
                    v-else-if="!team"
                >
                    <h3>{{ $root.translate("Create your team!") }}</h3>
                    <div class="text-center">
                        <base-link
                            ref="create_team_proxy"
                            :href="{ name: 'team.edit', params: { id: 0 } }"
                            :title="$root.translate('Create your team!')"
                            class="btn btn-light btn-lg btn-add d-none"
                        />
                        <default-button
                            color="light"
                            size="lg"
                            :title="$root.translate('Create your team!')"
                            class="btn-add"
                            @click.prevent="create_team"
                        >
                            <open-icon glyph="add" library="hop" size="2x" />
                        </default-button>
                    </div>
                </div>
                <base-link
                    v-else
                    :href="{ name: 'team.edit', params: { id: team.uuid } }"
                    class="btn btn-light"
                >
                    {{ $root.translate("Edit {name}", { name: team.name }) }}
                </base-link>
            </section>

            <section v-if="!sign_up_captain && !sign_up_regional_administrator" class="page-inner-container">
                <h3>
                    <open-icon glyph="user" size="lg" library="hop" />
                    {{ $root.translate('Walker Profile(s)') }}
                </h3>
                <walkers
                    :user-id="edited_record.id || 0"
                    :team-name="(!!auto_join_team && !profile_id) ? '' : team_name"
                    :errors="errors"
                    @added="maybe_hold_walker"
                    @deleted="maybe_unhold_walker"
                />
            </section>

            <section
                v-if="is_at_least_administrator"
                class="page-inner-container"
            >
                <h3>
                    {{ $root.translate('Admin Zone') }}
                </h3>
                <form-select
                    id="role"
                    name="role"
                    v-model="edited_record.role"
                    :data-source="filtered_roles"
                    :required="true"
                    :saving="saving('role')"
                    :error="has_error('role')"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Role") }}</template>
                </form-select>
                <div
                    v-if="!!edited_record&&!!edited_record.invitation_expires_at"
                    class="mt-2 text-center"
                >
                    <label
                        :class="{'text-error': user_invitation_expired, 'text-light-gray': !user_invitation_expired}"
                        class="form-label"
                    >
                        <span v-if="user_invitation_expired">{{ $root.translate("Invitation expired on") }}</span>
                        <span v-else>{{ $root.translate("Invitation valid until") }}</span>
                        {{ edited_record.invitation_expires_at | nibnut.date("yyyy-MM-dd '@' HH:mm") }}
                    </label>
                    <default-button
                        :title="$root.translate('Re-send invitation')"
                        :block="true"
                        @click.prevent="resend_invitation(edited_record)"
                    >
                        <open-icon glyph="share" /> {{ $root.translate('Re-send invitation') }}
                    </default-button>
                </div>
            </section>

            <div class="text-center mt-16">
                <default-button
                    v-if="!profile_id"
                    color="primary"
                    :waiting="joining"
                    @click.prevent.stop="signup"
                >
                    {{ $root.translate("Join now!") }}
                </default-button>
                <default-button
                    v-else-if="!!edited_record && !!edited_record.id"
                    color="primary"
                    @click.prevent="confirm_record_delete"
                >
                    {{ $root.translate("Delete Account") }}
                </default-button>
                <div v-else class="columns">
                    <div class="column">
                        <default-button
                            @click.prevent="$router.go(-1)"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                    </div>
                    <div class="column text-right">
                        <default-button
                            color="primary"
                            @click.prevent="record_save"
                        >
                            {{ $root.translate("Create") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>

        <vue-recaptcha
            v-if="!profile_id && !!recaptcha_key"
            ref="recaptcha"
            :sitekey="recaptcha_key"
            :load-recaptcha-script="true"
            size="invisible"
            @verify="verify_recaptcha"
            class="recaptcha my-3"
        />

        <modal-dialog
            id="password-editor-dialog"
            :show.sync="changing_password"
        >
            <template v-slot:title><span class="h5">{{ $root.translate("Change my password") }}</span></template>
            <base-form
                :has-required-fields="true"
                @submit.prevent="save_password"
            >
                <password-editor
                    id="password-editor"
                    name="password"
                    v-model="new_password"
                    :required="true"
                >
                    <template v-slot:label>{{ $root.translate("Enter your new password") }}</template>
                </password-editor>
            </base-form>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="saving('password')"
                        class="mr-2"
                        @click.prevent="changing_password=false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :waiting="saving('password')"
                        :disabled="saving('password')"
                        color="primary"
                        class="ml-2"
                        @click.prevent="save_password"
                    >
                        {{ $root.translate("Change password") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <avatar-picker
            v-if="!sign_up_regional_administrator"
            :show.sync="picking_avatar"
            @pick="picked_callback"
        />
        <reinvite-dialog
            :show.sync="reinviting"
            :user="invitee"
        />

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { mapState } from "vuex"
import {
    isBefore
} from "date-fns"

import { is_record_editor_page, is_user_inviter, handles_recaptcha } from "@/nibnut/mixins"
import { is_avatar_picker, language_utilities } from "@/custom/mixins"

import {
    BaseForm,
    FormInput,
    FormSelect,
    FeedbackButton,
    DefaultButton,
    ModalDialog,
    PasswordEditor,
    BaseLink,
    OpenIcon
} from "@/nibnut/components"
import {
    LanguageInput
} from "@/custom/components"
import Walkers from "./Walkers"
import Walker from "./Walker"
import VueRecaptcha from "vue-recaptcha"

export default {
    mixins: [is_record_editor_page, is_user_inviter, handles_recaptcha, is_avatar_picker, language_utilities],
    components: {
        BaseForm,
        FormInput,
        FormSelect,
        FeedbackButton,
        DefaultButton,
        ModalDialog,
        PasswordEditor,
        BaseLink,
        OpenIcon,
        LanguageInput,
        Walkers,
        Walker,
        VueRecaptcha
    },
    mounted () {
        this.load_invitation()
    },
    watch: {
        $route: "load_invitation"
    },
    methods: {
        grab_record_id () {
            if(!!this.profile_id && (this.$route.name === "signup")) this.$router.replace({ name: "home" })
            else if(!this.is_at_least_administrator) this.record_id = this.profile_id
            else {
                const int_id = parseInt(this.$route.params.id)
                this.record_id = (`${int_id}` === `${this.$route.params.id}`) ? int_id : this.$route.params.id
                if(!this.record_id) this.record_id = this.profile_id
            }
        },
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        },
        send_reset_password () {
            if(!this.profile_id) return
            this.sending_password = true
            this.$store.dispatch("SEND_PASSWORD_RESET", {
                email: this.edited_record.email
            }).then(data => {
                this.send_password_status = true
                this.send_password_message = data.message
            }).catch(error => {
                this.send_password_status = false
                this.send_password_message = error.message
            }).then(() => {
                this.sending_password = false
            })
        },
        change_password () {
            if(!this.profile_id) return
            this.new_password = ""
            this.changing_password = true
        },
        save_password () {
            if(!this.profile_id) return
            this.save(this.new_password, "password").then(() => {
                if(!this.has_error("password")) this.changing_password = false
            })
        },
        save_language (value, field) {
            if(!this.profile_id || this.me) this.translate_to(value)
            this.save(value, field)
        },
        save_avatar (avatar_id) {
            this.captain.avatar_id = avatar_id
        },
        load_invitation () {
            if((window.location.search || (this.$route.params && this.$route.params.invitation)) && !this.profile_id) {
                const matches = window.location.search ? window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/) : [null, this.$route.params.invitation]
                if(matches) {
                    const token = matches[1]

                    this.fetching = true
                    this.$store.dispatch("LOAD_INVITATION", {
                        token
                    }).then(invitation_data => {
                        if(invitation_data._signup_forbidden) {
                            this.$router.replace({
                                name: invitation_data._redirect_to || "home",
                                params: {
                                    invitation: this.$route.params.invitation,
                                    invitation_data,
                                    id: invitation_data._existing_team_id || 0
                                }
                            })
                        } else {
                            this.invitation_token = token
                            this.role = invitation_data.role
                            this.email = invitation_data.email
                            this.team_name = invitation_data.team_name
                            this.team_school_name = invitation_data.team_school_name
                            this.team_school_board = invitation_data.team_school_board
                            this.new_walkers.unshift({
                                first_name: "",
                                last_name: "",
                                avatar_id: 0,
                                year_of_birth: "",
                                school_name: this.team_school_name,
                                school_board: this.team_school_board
                            })
                        }
                    }).catch(this.receive_error).then(() => {
                        this.fetching = false
                    })
                }
            } else if((this.$route.name === "signup") && !this.profile_id && this.auto_join_team) {
                this.team_name = this.auto_join_team.name
                this.team_school_name = this.auto_join_team.school_name
                this.team_school_board = this.auto_join_team.school_board
            }
        },
        resend_invitation () {
            if(this.edited_record.email) {
                this.$store.dispatch(
                    "REINVITE",
                    { email: this.edited_record.email }
                ).then(() => {
                    this.$success(this.$root.translate("Invitation link re-sent! Check your inbox... (or junk folder, just in case)"))
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        maybe_hold_walker (walker) {
            if(!!this.edited_record && !this.edited_record.id) {
                if((this.$route.name === "signup") && !this.profile_id && this.auto_join_team) {
                    walker.team_id = this.auto_join_team.id
                    walker.school_name = this.auto_join_team.school_name
                    walker.school_board = this.auto_join_team.school_board
                }
                this.new_walkers.push(walker)
            }
        },
        maybe_unhold_walker (walker) {
            if(!!this.edited_record && !this.edited_record.id) {
                const index = this.new_walkers.findIndex(new_walker => new_walker.id === walker.id)
                if(index >= 0) this.new_walkers.splice(index, 1)
            }
        },
        signup () {
            let new_walkers
            const signup = () => {
                this.joining = true
                this.clear_all_errors()
                const data = {
                    ...this.edited_record,
                    recaptcha: this.recaptcha
                }
                if(!this.record_id) {
                    data.invitation_token = this.invitation_token
                    data.team_name = this.team_name
                    data.team_school_name = this.team_school_name
                    data.team_school_board = this.team_school_board
                }
                this.$store.dispatch("SIGNUP", {
                    data,
                    set_profile: false
                }).then(user => {
                    this.record_id = user.id
                    this.invitation_token = null

                    let captain = this.team_name ? this.entity_records("walker").find(walker => walker.user_id === user.id) : null
                    const walker_uuid = captain ? captain.uuid : 0
                    const team = captain ? this.entity_records("team").find(team => team.captain_id === captain.id) : null
                    const rewind = (error) => {
                        this.joining = false
                        this.$store.dispatch( // delete useer created, which will delete any walker created
                            "RECORD_DELETE",
                            {
                                entity: "user",
                                id: user.id
                            }
                        ).then(() => {
                            this.receive_error(error)
                        }).catch(() => {})
                    }
                    const save_walker = () => {
                        if(new_walkers.length) {
                            const data = new_walkers[0]
                            if(captain && !this.auto_join_team) {
                                this.$store.dispatch("RECORD_SAVE", {
                                    entity: "walker",
                                    id: captain.id,
                                    data: {
                                        avatar_id: data.avatar_id,
                                        first_name: data.first_name,
                                        last_name: data.last_name,
                                        year_of_birth: data.year_of_birth,
                                        school_name: data.school_name,
                                        school_board: data.school_board,
                                        _team_member: true
                                    }
                                }).then(() => {
                                    captain = null
                                    new_walkers.shift()
                                    save_walker()
                                }).catch(rewind)
                            } else {
                                data.user_id = user.id
                                if(this.auto_join_team) data.team_id = this.auto_join_team.id
                                else if(team && data._team_member) data.team_id = team.id
                                this.$store.dispatch("CREATE_RECORD", {
                                    entity: "walker",
                                    data
                                }).then(() => {
                                    new_walkers.shift()
                                    save_walker()
                                }).catch(rewind)
                            }
                        } else {
                            this.joining = false
                            this.invitation_token = ""
                            this.team_name = ""
                            this.team_school_name = ""
                            this.team_school_board = null
                            // this.$store.dispatch("UNREQUEST_LOGIN")
                            this.$store.dispatch("SET_PROFILE", { profile: user })
                            if(walker_uuid) {
                                this.$store.dispatch(
                                    "SET_CURRENT_WALKER",
                                    { walker_uuid, team_id: this.auto_join_team ? this.auto_join_team.id : 0 }
                                ).then(() => {
                                    this.$store.dispatch("AUTO_JOIN_TEAM", { team: null })
                                    if(this.$route.name !== "home") this.$router.replace({ name: "home" })
                                })
                            }
                        }
                    }
                    save_walker()
                }).catch(error => {
                    this.joining = false
                    if(error) this.receive_error(error)
                })
            }
            setTimeout(() => {
                new_walkers = [...this.new_walkers]
                if(this.sign_up_captain && !this.team_name) this.$error(this.$root.translate("Please name your team"))
                else if(this.team_name && !this.captain.first_name) this.$error(this.$root.translate("Please fill out the team captain's info"))
                else {
                    if(this.can_have_team && !this.team_name) new_walkers.shift()
                    new_walkers = new_walkers.filter(walker => !!walker.first_name)
                    if(!this.sign_up_captain && !this.sign_up_regional_administrator && !new_walkers.length) this.$error(this.$root.translate("Please set at least one walker. (even if it's yourself!)"))
                    else {
                        if(this.$refs.recaptcha) {
                            this.verification_callback = () => {
                                if(this.recaptcha) signup()
                                this.verification_callback = null
                            }
                            this.$refs.recaptcha.execute()
                        } else signup()
                    }
                }
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.$root.translate("Do you really want to delete this account? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            if(this.record_id === this.profile_id) this.$router.push({ name: "dashboard" })
            else this.$router.push({ name: "user.list" })
        },
        create_team () {
            var node = this.$refs.create_team_proxy
            if(!!node && !!node.$el && !!node.$el.click) node.$el.click()
        }
    },
    computed: {
        ...mapState(["auto_join_team"]),
        page_title () {
            if(!this.profile_id) return this.$root.translate("Sign up")
            const record = this.edited_record
            const entity_name = this.$root.translate(this.entityName, {}, 1)
            if(!record || !record.id) return this.$root.translate(`New ${entity_name}`)
            return record.name || record.title || record.email || entity_name
        },
        reload_query () {
            return { relation_ids: this.relation_ids, fields: ["fieldset::defaut", "ns::walker;fieldset::list", "ns::team;fieldset::list"] }
        },
        me () {
            const record = this.edited_record
            return !!this.profile_id && !!record && ((this.profile_id === record.uuid) || (this.profile_id === record.id))
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        filtered_roles () {
            if(!this.is_developer) return this.roles.filter(role => role.id !== this.constants("roles", "ROLE_DEVELOPER").id)
            return this.roles
        },
        user_invitation_expired () {
            const record = this.edited_record
            if(!!record && !!record.invitation_expires_at) return isBefore(record.invitation_expires_at, new Date())
            return false
        },
        can_have_team () {
            if(!this.profile_id) return !!this.auto_join_team || (this.role_is_at_least_captain(this.role) && !this.sign_up_regional_administrator)
            if(!this.edited_record) return false
            return this.role_is_at_least_captain(this.edited_record.role)
        },
        team () {
            if(!this.profile_id) return { id: null, name: this.team_name, school_name: this.team_school_name, school_board: this.team_school_board }
            const record = this.edited_record
            return this.entity_records("team").find(team => team.computed_captain_profile_id === record.id)
        },
        captain () {
            return this.new_walkers[0]
        },
        sign_up_captain () {
            return !this.profile_id && this.role_is_captain(this.role) && !this.auto_join_team
        },
        sign_up_regional_administrator () {
            return !this.profile_id && this.role_is_regional_admin(this.role) && !this.auto_join_team
        }
    },
    data () {
        return {
            entity: "user",
            entityName: "User:::Users",
            relation_ids: ["walker", "team"],

            changing_password: false,
            new_password: "",

            sending_password: false,
            send_password_status: null,
            send_password_message: "",

            invitation_token: "",
            team_name: "",
            team_school_name: "",
            team_school_board: null,
            role: 0,
            joining: false,
            new_walkers: []
        }
    }
}
</script>
