<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    class="form-label disabled-field"
                >
                    {{ display_value }}
                </label>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <base-date-input
            v-bind="$props"
            v-on="$listeners"
        >
            <template v-slot:left_addon><slot name="left_addon"></slot></template>
        </base-date-input>
    </form-group>
</template>

<script>
import {
    parseISO,
    format
} from "date-fns"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import BaseDateInput from "./BaseDateInput"

export default {
    name: "FormDateInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        BaseDateInput
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        display_value () {
            if(!this.value) return ""
            return format(parseISO(this.value), this.dateFormat)
        }
    },
    props: {
        ...BaseDateInput.props
    }
}
</script>
