<template>
    <div>
        <ul class="tab tab-block">
            <li
                v-for="tab in tabs"
                :key="tab.id"
                :class="{ active: (tab.id === currentTabId) }"
                class="tab-item"
            >
                <a
                    href
                    @click.prevent="$emit('click', tab.id)"
                >
                    <slot name="tab" :tab="tab">
                        {{ tab.title }}
                    </slot>
                </a>
            </li>
        </ul>

        <div>
            <keep-alive>
                <slot></slot>
            </keep-alive>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Array,
            default () {
                return [] // { id: "", title: "" }
            }
        },
        currentTabId: {
            type: String,
            default: ""
        }
    }
}
</script>
