<template>
    <page
        id="settings"
        :title="$root.translate('Settings')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div v-if="!!edited_record" class="columns">
            <div class="column col-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("Content") }}</div>
                    </div>
                    <div class="card-body">
                        <label class="form-label h6">{{ $root.translate("Welcome Screen") }}</label>
                        <div class="columns">
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="title_EN"
                                    v-model="welcome_text.title_EN"
                                    name="title_EN"
                                    :required="false"
                                    :saving="saving('title_EN')"
                                    :error="has_error('title_EN')"
                                    @input="save_text(welcome_text, ...arguments)"
                                >
                                    <template v-slot:left_addon>
                                        <span class="input-group-addon">
                                            EN
                                        </span>
                                    </template>
                                </form-input>
                            </div>
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="title_FR"
                                    v-model="welcome_text.title_FR"
                                    name="title_FR"
                                    :required="false"
                                    :saving="saving('title_FR')"
                                    :error="has_error('title_FR')"
                                    @input="save_text(welcome_text, ...arguments)"
                                >
                                    <template v-slot:left_addon>
                                        <span class="input-group-addon">
                                            FR
                                        </span>
                                    </template>
                                </form-input>
                            </div>
                        </div>
                        <label class="form-label h6 mt-5">{{ $root.translate("Welcome Slides") }}</label>
                        <slides />

                        <label class="form-label h6 mt-5">{{ $root.translate("Resources URL") }}</label>
                        <div class="columns">
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="resources_page_url_en"
                                    v-model="edited_record.resources_page_url_en"
                                    name="resources_page_url_en"
                                    :required="false"
                                    :saving="saving('resources_page_url_en')"
                                    :error="has_error('resources_page_url_en')"
                                    @input="save"
                                >
                                    <template v-slot:left_addon>
                                        <span class="input-group-addon">
                                            EN
                                        </span>
                                    </template>
                                </form-input>
                            </div>
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="resources_page_url_fr"
                                    v-model="edited_record.resources_page_url_fr"
                                    name="resources_page_url_fr"
                                    :required="false"
                                    :saving="saving('resources_page_url_fr')"
                                    :error="has_error('resources_page_url_fr')"
                                    @input="save"
                                >
                                    <template v-slot:left_addon>
                                        <span class="input-group-addon">
                                            FR
                                        </span>
                                    </template>
                                </form-input>
                            </div>
                        </div>

                        <label class="form-label h6 mt-5">{{ $root.translate("OSTA Resources URL") }}</label>
                        <div class="columns">
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="alt_resources_page_url_en"
                                    v-model="edited_record.alt_resources_page_url_en"
                                    name="alt_resources_page_url_en"
                                    :required="false"
                                    :saving="saving('alt_resources_page_url_en')"
                                    :error="has_error('alt_resources_page_url_en')"
                                    @input="save"
                                >
                                    <template v-slot:left_addon>
                                        <span class="input-group-addon">
                                            EN
                                        </span>
                                    </template>
                                </form-input>
                            </div>
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="alt_resources_page_url_fr"
                                    v-model="edited_record.alt_resources_page_url_fr"
                                    name="alt_resources_page_url_fr"
                                    :required="false"
                                    :saving="saving('alt_resources_page_url_fr')"
                                    :error="has_error('alt_resources_page_url_fr')"
                                    @input="save"
                                >
                                    <template v-slot:left_addon>
                                        <span class="input-group-addon">
                                            FR
                                        </span>
                                    </template>
                                </form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-6 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("Calculations") }}</div>
                    </div>
                    <div class="card-body">
                        <div class="columns">
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="kg_per_km"
                                    v-model="edited_record.kg_per_km"
                                    name="kg_per_km"
                                    type="float"
                                    :required="false"
                                    :floatStep="0.005"
                                    :saving="saving('kg_per_km')"
                                    :error="has_error('kg_per_km')"
                                    @input="save"
                                >
                                    <template v-slot:left_addon>
                                        <span class="input-group-addon">
                                            1 Km =
                                        </span>
                                    </template>
                                    <template v-slot:label>{{ $root.translate("GHGs") }}</template>
                                    <template v-slot:right_addon>
                                        <span class="input-group-addon">
                                            Kg
                                        </span>
                                    </template>
                                </form-input>
                            </div>
                            <div class="column col-6 col-sm-12">
                                <form-input
                                    id="steps_per_km"
                                    v-model="edited_record.steps_per_km"
                                    name="steps_per_km"
                                    type="number"
                                    :required="false"
                                    :saving="saving('steps_per_km')"
                                    :error="has_error('steps_per_km')"
                                    @input="save"
                                >
                                    <template v-slot:left_addon>
                                        <span class="input-group-addon">
                                            1 Km =
                                        </span>
                                    </template>
                                    <template v-slot:label>{{ $root.translate("Distance") }}</template>
                                    <template v-slot:right_addon>
                                        <span class="input-group-addon">
                                            Steps
                                        </span>
                                    </template>
                                </form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-6 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ $root.translate("User Management") }}</div>
                    </div>
                    <div class="card-body">
                        <invitation-expiries
                            id="default_invitation_delay"
                            name="default_invitation_delay"
                            v-model="edited_record.default_invitation_delay"
                            :required="true"
                            :saving="saving('default_invitation_delay')"
                            :error="has_error('default_invitation_delay')"
                            @input="save"
                        />
                    </div>
                </div>
            </div>
        </div>

        <default-button
            v-if="!!edited_record"
            :block="true"
            color="error"
            class="my-8"
            @click.prevent="confirm_reset"
        >
            {{ $root.translate("Reset Data...") }}
        </default-button>

        <div v-if="!!edited_record && is_developer" class="card">
            <div class="card-header">
                <div class="card-title h5">{{ $root.translate("Developer Zone") }}</div>
            </div>
            <div class="card-body">
                <bitbucket-connector
                    :record="edited_record"
                />
            </div>
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
        >
            <template v-slot:title>
                <span
                    class="h5"
                >
                    <open-icon
                        v-if="!!confirmation_props.icon"
                        :glyph="confirmation_props.icon"
                    />
                    {{ confirmation_props.title }}
                </span>
            </template>
            <template v-slot:footer>
                <default-button
                    :disabled="waiting"
                    class="mr-2"
                    @click.prevent="done_confirming"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    :disabled="waiting || (verification !== VERIFICATION)"
                    :waiting="waiting"
                    :class="{ [`btn-${confirmation_props.type}`]: true }"
                    class="ml-2"
                    @click.prevent="confirmed"
                >
                    {{ $root.translate("Reset") }}
                </default-button>
            </template>

            <p class="text-center">
                {{ this.$root.translate("This will hide all teams, trips and walker challenges. It will also reset team membership and walker treasures.") }}
            </p>
            <p class="text-center">
                {{ this.$root.translate("To confirm, please type-in '{verification}' below.", { verification: this.VERIFICATION }) }}
            </p>

            <form-input
                id="verification"
                v-model="verification"
                name="verification"
                :required="true"
                :placeholder="VERIFICATION"
            />
        </confirmation>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"

import { FormInput, InvitationExpiries, DefaultButton, OpenIcon } from "@/nibnut/components"
import Slides from "./settings/Slides"
import BitbucketConnector from "./settings/BitbucketConnector"

export default {
    mixins: [is_record_editor_page],
    components: {
        FormInput,
        InvitationExpiries,
        DefaultButton,
        OpenIcon,
        Slides,
        BitbucketConnector
    },
    created () {
        this.VERIFICATION = "CLEAN SLATE"
    },
    methods: {
        grab_record_id () {
            this.record_id = 1
        },
        save_text (custom_text, value, field) {
            this.save_field_for_record_id("custom_text", custom_text.id, value, field)
        },
        confirm_reset () {
            this.verification = ""
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("RESET APPLICATION DATA"),
                    message: ""
                },
                "reset"
            )
        },
        confirmed () {
            if(this.confirming === "reset") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_record.id,
                        passthru: true
                    }
                ).then(() => {
                    this.done_confirming()
                    this.$success(this.$root.translate("Application has been reset."))
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    computed: {
        welcome_text () {
            const type = this.constants("custom_text_types", "TYPE_WELCOME_TEXT").id
            return this.entity_records("custom_text").find(custom_text => custom_text.type === type)
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings",
            verification: ""
        }
    }
}
</script>
