<template>
    <base-link
        v-if="links.length === 1"
        :href="links[0].url"
        class="btn btn-link"
        download
    >
        <open-icon glyph="file-csv" :title="$root.translate('Export All Data (CSV)')" />
    </base-link>
    <div
        v-else
        :class="{ active: picking }"
        class="dropdown"
    >
        <default-button
            flavor="link"
            @click.prevent="start_picking"
        >
            <open-icon glyph="file-csv" :title="$root.translate('Export Data (CSV)')" />
        </default-button>
        <ul
            ref="menu"
            class="menu text-left text-small"
        >
            <li
                v-for="link in links"
                :key="link.url"
                class="menu-item"
            >
                <base-link
                    :href="link.url"
                    class="btn btn-link"
                    download
                >
                    {{ link.title }}
                </base-link>
            </li>
        </ul>
    </div>
</template>

<script>
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ExportButton",
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    methods: {
        start_picking () {
            setTimeout(() => {
                document.body.addEventListener("click", this.maybe_stop_picking)
            }, 200)
            this.picking = true
        },
        stop_picking () {
            this.picking = false
            document.body.removeEventListener("click", this.maybe_stop_picking)
        },
        maybe_stop_picking (event) {
            if(event.target && !this.$refs.menu.contains(event.target)) {
                this.stop_picking()
            }
        },
        pick () {
            setTimeout(() => {
                this.stop_picking()
            }, 200)
        }
    },
    props: {
        links: {
            type: Array, // [ { title: "", url: "" }, ... ]
            required: true
        }
    },
    data () {
        return {
            picking: false
        }
    }
}
</script>
