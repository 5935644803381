<template>
    <div ref="map" class="nibnut-map-container">
    </div>
</template>

<script type="text/javascript">
const coordinate = (latitude_or_longitude) => {
    if(typeof latitude_or_longitude === "string") return parseFloat(latitude_or_longitude)
    return latitude_or_longitude
}

export default {
    name: "DefaultMap",
    mounted () {
        this.setup()
    },
    beforeDestroy () {
        if(window.google) {
            this.marker_events_listeners.forEach(listener => {
                window.google.maps.event.removeListener(listener)
            })
        }
    },
    watch: {
        locations: "mark_locations"
    },
    methods: {
        setup () {
            if(!window.google) console.error("DefaultMap component expects Google API to be installed")
            else {
                let tries = 0
                const setup = () => {
                    if(this.$refs.map && !this.map) {
                        this.map = new window.google.maps.Map(this.$refs.map, {
                            mapTypeControl: true,
                            center: { lat: 45.573272, lng: -75.782089 },
                            zoom: this.initialZoom
                        })

                        this.popup = new window.google.maps.InfoWindow({
                            content: ""
                        })

                        this.refresh_map()
                    } else if(++tries <= 10) setTimeout(setup, 100)
                }
                setup()
            }
        },
        refresh_map () {
            if(this.map) {
                setTimeout(() => {
                    window.google.maps.event.trigger(this.map, "resize")
                    this.mark_locations(true)
                }, 500)
            }
        },
        mark_locations (center_map = false) {
            if(!this.map || !this.locations || !this.locations.length) return

            const active_marker_ids = []
            this.locations.forEach((location, index) => {
                const coordinates = new window.google.maps.LatLng(coordinate(location.latitude), coordinate(location.longitude))
                if(!!coordinates.lat() || !!coordinates.lng()) {
                    if(center_map && !index) this.map.setCenter(coordinates)

                    const marker_id = location.id || `${coordinates.lat()}x${coordinates.lng()}`
                    if(!this.markers[marker_id]) {
                        this.markers[marker_id] = new window.google.maps.Marker({
                            position: coordinates,
                            animation: window.google.maps.Animation.DROP,
                            title: location.title || "",
                            map: this.map
                        })
                        if(this.bubbleBuilder) {
                            this.marker_events_listeners.push(
                                this.markers[marker_id].addListener("click", () => {
                                    const content = this.bubbleBuilder(location)
                                    if(content) this.popup.setContent(content)
                                    this.popup.open(this.map, this.markers[marker_id])
                                })
                            )
                        }
                    } else this.markers[marker_id].setPosition(coordinates)

                    active_marker_ids.push(marker_id)
                }
            })

            Object.keys(this.markers).forEach(marker_id => {
                const marker = this.markers[marker_id]
                if(active_marker_ids.indexOf(marker_id) >= 0) marker.setMap(this.map)
                else marker.setMap(null)
            })
        }
    },
    computed: {
    },
    props: {
        locations: {
            type: Array, // [ { latitude: ..., longitude: ...(, id: ..., title: ..., color: ...) } ]
            default () {
                return []
            }
        },
        initialZoom: {
            type: Number,
            validator: prop => (prop >= 0) && (prop <= 18),
            default: 15
        },
        bubbleBuilder: {
            type: Function
        }
    },
    data () {
        return {
            map: null,
            popup: null,
            markers: {},
            marker_events_listeners: []
        }
    }
}
</script>

<style lang="scss">
.nibnut-map-container {
    height: 100%;
}
</style>
