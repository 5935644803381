<template>
    <div
        :class="{ [size]: true, [color]: true }"
        class="la-square-jelly-box loader"
    >
        <div></div>
        <div></div>
    </div>
</template>

<script type="text/javascript">
/*
https://codepen.io/kjbrum/pen/BraCg
https://connoratherton.com/loaders
http://anastawfeek.github.io/ArtWorx-xLoader/
https://github.danielcardoso.net/load-awesome/animations.html
*/
export default {
    name: "Loader",
    props: {
        size: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(xs|sm|md|lg|xl)$/),
            default: "md"
        },
        color: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(gray|light|primary)$/),
            default: "primary"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.loader {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    font-size: 0;
    color: #fff;
    box-sizing: border-box;

    & > div {
        position: relative;
        display: inline-block;
        float: none;
        background-color: currentColor;
        border: 0 solid currentColor;
        box-sizing: border-box;

        &:nth-child(1), &:nth-child(2) {
            position: absolute;
            left: 0;
            width: 100%;
        }
        &:nth-child(1) {
            top: -25%;
            z-index: $zindex-1;
            height: 100%;
            border-radius: 10%;
            animation: square-jelly-box-animate .6s -.1s linear infinite;
        }
        &:nth-child(2) {
            bottom: -9%;
            height: 10%;
            background: #000;
            border-radius: 50%;
            opacity: .2;
            animation: square-jelly-box-shadow .6s -.1s linear infinite;
        }
    }

    &.gray {
        color: $dark-color;
    }
    &.primary {
        color: $primary-color;
    }

    &.xs {
        width: 0.4rem;
        height: 0.4rem;
    }
    &.sm {
        width: 16px;
        height: 16px;
    }
    &.lg {
        width: 64px;
        height: 64px;
    }
    &.xl {
        width: 96px;
        height: 96px;
    }
}

@keyframes square-jelly-box-animate {
    17% {
        border-bottom-right-radius: 10%;
    }
    25% {
        transform: translateY(25%) rotate(22.5deg);
    }
    50% {
        border-bottom-right-radius: 100%;
        transform: translateY(50%) scale(1, .9) rotate(45deg);
    }
    75% {
        transform: translateY(25%) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}
@keyframes square-jelly-box-shadow {
    50% {
        transform: scale(1.25, 1);
    }
}
</style>
