<template>
    <page
        id="team-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            v-if="!!edited_record"
            :has-required-fields="false"
            class="page-outer-container"
        >
            <section v-if="!!edited_record.id" class="page-inner-container">
                <div class="columns align-items-center justify-content-center">
                    <div class="column flex-static">
                        <avatar
                            :url="avatar.url"
                            :initials="record_initials"
                            color="gray"
                        />
                    </div>
                    <div class="column flex-static text-bold">
                        {{ $root.translate("Hi, {name}", { name: edited_record.name }) }}
                    </div>
                </div>
                <div class="columns align-items-center justify-content-center mt-2">
                    <div class="column flex-static">
                        <default-button
                            color="light"
                            size="sm"
                            @click="pick_avatar(save_avatar)"
                        >
                            {{ $root.translate("Edit") }}
                        </default-button>
                    </div>
                    <div class="column flex-static text-bold d-invisible">
                        {{ $root.translate("Hi, {name}", { name: edited_record.name }) }}
                    </div>
                </div>
            </section>
            <section class="page-inner-container">
                <h3 v-if="!!edited_record.id">
                    <open-icon glyph="user" size="lg" library="hop" />
                    {{ $root.translate('Team Info') }}
                </h3>
                <h3 v-else>
                    {{ $root.translate('Create a team!') }}
                </h3>
                <form-input
                    id="first_focusable"
                    ref="first_field"
                    v-model="edited_record.name"
                    name="name"
                    :required="true"
                    :maybe-autofilled="true"
                    :placeholder="`${$root.translate('Team name')}*`"
                    :saving="saving('name')"
                    :error="has_error('name')"
                    @input="save"
                />
                <form-input
                    id="school_name"
                    v-model="edited_record.school_name"
                    name="school_name"
                    :required="false"
                    :placeholder="`${$root.translate('School name')}*`"
                    :saving="saving('school_name')"
                    :error="has_error('school_name')"
                    @input="save"
                />
                <form-select
                    id="schoolboard_id"
                    name="schoolboard_id"
                    v-model="edited_record.schoolboard_id"
                    data-source="schoolboard"
                    :empty-label="$root.translate('School Board')"
                    :show-all="true"
                    :required="false"
                    :saving="saving('schoolboard_id')"
                    :error="has_error('schoolboard_id')"
                    @input="save"
                />
                <div v-if="!edited_record.id" class="text-center mt-5 mb-10">
                    <div v-if="!!avatar.url">
                        <avatar
                            :url="avatar.url"
                            :initials="record_initials"
                        />
                    </div>
                    <default-button
                        color="light"
                        @click.prevent="pick_avatar(save_avatar)"
                    >
                        {{ $root.translate("Pick an avatar!") }}
                    </default-button>
                </div>
                <label class="form-label text-small text-center">{{ $root.translate("*Required fields") }}</label>
            </section>
            <section class="page-inner-container">
                <h3>
                    {{ $root.translate('Captain') }}
                </h3>
                <h6 v-if="walkers.length > 1" class="text-normal text-center mb-4">{{ $root.translate("Please select your team captain") }}...</h6>
                <div class="d-flex align-items-center justify-content-center">
                    <div
                        v-for="walker in walkers"
                        :key="walker.id"
                        :class="{ active: (walker.id === edited_record.captain_id) }"
                        class="captain"
                    >
                        <default-button
                            flavor="link"
                            @click.prevent="edited_record.captain_id = walker.id"
                        >
                            <avatar
                                :url="walker_avatar(walker).url"
                                :initials="walker_initials(walker)"
                                :color="(walker.id === edited_record.captain_id) ? 'gray' : 'light'"
                            />
                            <div>{{ full_name(walker) }}</div>
                        </default-button>
                    </div>
                </div>
                <div v-if="!new_captain" class="text-small text-center mt-4">
                    <default-button
                        size="sm"
                        @click.prevent="add_captain"
                    >
                        <span v-if="!!walkers.length">{{ $root.translate("Or create a new walker...") }}</span>
                        <span v-else>{{ $root.translate("Create a new walker...") }}</span>
                    </default-button>
                </div>
            </section>

            <section
                v-if="!!edited_record.id && !!team_members.length"
                class="page-inner-container"
            >
                <h3>
                    {{ $root.translate('Team Members') }}
                </h3>
                <members
                    :team-id="edited_record.id"
                    :captain-id="edited_record.captain_id"
                />
            </section>

            <section
                v-if="!!edited_record.id"
                class="page-inner-container mt-10"
            >
                <div id="invite-card" class="invite-card">
                    <h3>
                        {{ $root.translate('Invite members to your team') }}
                    </h3>
                    <p>{{ $root.translate("Copy the link below to invite your team mates by email") }}</p>
                    <copy-link-button
                        :href="invitation_url"
                        :text="$root.translate('Hi! You\'re being invited to join a team on Hop! Click on this link to get started:')"
                    />
                </div>
            </section>

            <div class="page-inner-container mt-10">
                <div
                    v-if="!!edited_record && !!edited_record.id"
                    class="text-center"
                >
                    <default-button
                        color="primary"
                        @click.prevent="confirm_record_delete"
                    >
                        {{ $root.translate("Delete Team") }}
                    </default-button>
                </div>
                <div v-else class="columns">
                    <div class="column">
                        <default-button
                            @click.prevent="$router.go(-1)"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                    </div>
                    <div class="column text-right">
                        <default-button
                            color="secondary"
                            @click.prevent="record_save"
                        >
                            {{ $root.translate("Create!") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>

        <avatar-picker
            :show.sync="picking_avatar"
            @pick="picked_callback"
        />
        <modal-dialog
            id="new-captain-dialog"
            :show.sync="adding_captain"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("New Captain") }}</span>
            </template>
            <base-form
                v-if="!!captain_shell"
                :has-required-fields="true"
                @submit.prevent="create_captain"
            >
                <walker-editor
                    v-if="!!captain_shell"
                    :record="captain_shell"
                    :can-pick-avatar="false"
                    :can-set-school="false"
                    :saving-field-ids="saving_field_ids"
                    :errors="errors"
                    @pick-avatar="pick_avatar(save_captain_avatar)"
                />
            </base-form>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="creating_captain"
                        class="mr-2"
                        @click.prevent="adding_captain = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        type="submit"
                        :waiting="creating_captain"
                        :disabled="creating_captain"
                        color="primary"
                        class="ml-2"
                        @click.prevent="create_captain"
                    >
                        {{ $root.translate("Create") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page, string_utilities, ui_utilities } from "@/nibnut/mixins"
import { is_avatar_owner, is_avatar_picker, addl_profile_utilities } from "@/custom/mixins"

import {
    ModalDialog,
    BaseForm,
    FormInput,
    FormSelect,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    WalkerEditor,
    CopyLinkButton
} from "@/custom/components"
import Members from "./Members"

export default {
    mixins: [is_record_editor_page, is_avatar_owner, is_avatar_picker, addl_profile_utilities, string_utilities, ui_utilities],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        FormSelect,
        DefaultButton,
        OpenIcon,
        WalkerEditor,
        CopyLinkButton,
        Members
    },
    watch: {
        walker_uuid: "reload"
    },
    methods: {
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        },
        post_load () {
            if(this.edited_record && !this.edited_record.id && !this.edited_record.captain_id) {
                const walkers = this.walkers
                if(walkers && (walkers.length === 1)) this.edited_record.captain_id = walkers[0].id
            } else if(this.edited_record && !this.edited_record.id && (this.$route.params && this.$route.params.invitation_data)) {
                if(this.$route.params.invitation_data.team_name) this.edited_record.name = this.$route.params.invitation_data.team_name
                if(this.$route.params.invitation_data.team_school_name) this.edited_record.school_name = this.$route.params.invitation_data.team_school_name
                if(this.$route.params.invitation_data.team_school_board) this.edited_record.schoolboard_id = this.$route.params.invitation_data.team_school_board
            }
        },
        save_avatar (avatar_id) {
            this.save(avatar_id, "avatar_id")
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.$root.translate("Do you really want to delete this team? Walkers will keep their own statistics. There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.$router.go(-1)
        },
        walker_avatar (walker) {
            return this.avatar_by_id(walker.avatar_id)
        },
        walker_initials (walker) {
            return this.initials(walker)
        },
        add_captain () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "walker" }
            ).then(record => {
                this.captain_shell = record
                this.captain_shell.team_id = this.edited_record.id
                this.adding_captain = true
            }).catch(error => {
                this.$error(error.message)
            })
        },
        save_captain_avatar (avatar_id) {
            if(this.captain_shell) this.captain_shell.avatar_id = avatar_id
        },
        create_captain () {
            // on save dialog, IF new team, hold walker in captain ; ELSE create new walker on save ; once new walker created, save edited_record.captain_id to new walker id
            if(!this.edited_record.id) {
                this.new_captain = { ...this.captain_shell }
                this.save(this.new_captain.id, "captain_id")
                this.adding_captain = false
            } else {
                this.creating_captain = true
                this.$store.dispatch("CREATE_RECORD", {
                    entity: "walker",
                    data: this.captain_shell
                }).then(captain => {
                    this.save(captain.id, "captain_id")
                }).catch(this.receive_error).then(() => {
                    this.creating_captain = false
                })
            }
        },
        record_save () {
            const edited_record = this.edited_record
            if(!edited_record.captain_id) {
                this.$error(this.$root.translate("Please assign a walker as the team's captain."))
                return
            }
            const is_new = !edited_record.id
            if(is_new) this.creating_record = true
            return this.save_data_for_record_id(this.entity, edited_record.id || 0, edited_record).then(record => {
                if(is_new) {
                    this.record_id = record.id
                    if(this.new_captain) {
                        this.new_captain.team_id = record.id
                        this.$store.dispatch("CREATE_RECORD", {
                            entity: "walker",
                            data: this.new_captain
                        }).then(captain => {
                            this.save_data_for_record_id(this.entity, record.id, { captain_id: captain.id })
                        }).catch(this.receive_error)
                    } else this.finalize_save(record)
                }
            })
        },
        saved_data (record, is_new, data) {
            if(this.record_id) this.finalize_save(record)
        },
        finalize_save (record) {
            this.creating_record = false
            this.new_captain = null
            this.$router.replace({ name: `${this.entity}.edit`, params: { id: record.uuid || record.id } })
        }
    },
    computed: {
        page_title () {
            const record = this.edited_record
            const entity_name = this.$root.translate(this.entityName, {}, 1)
            if(!record || !record.id) return this.$root.translate(`New ${entity_name}`)
            return record.name || entity_name
        },
        can_reload () {
            return this.profile_id && this.walker_uuid
        },
        record_initials () {
            return this.initials(this.edited_record)
        },
        avatar () {
            if(!this.edited_record) return {}
            return this.avatar_by_id(this.edited_record.avatar_id)
        },
        walkers () {
            const walkers = this.entity_records("walker").filter(walker => {
                return (walker.user_id === this.profile.id)
            })
            if(this.new_captain) walkers.push(this.new_captain)
            return walkers
        },
        team_members () {
            const team = this.edited_record
            if(!team) return []
            return this.entity_records("walker").filter(walker => walker.team_id === team.id)
        },
        captain () {
            const team = this.edited_record
            if(!team) return {}
            return this.entity_records("walker").find(walker => walker.id === team.captain_id)
        },
        invitation_url () {
            return `https://${window.location.hostname}/t/${this.edited_record.uuid}`
        }
    },
    data () {
        return {
            entity: "team",
            entityName: "Team:::Teams",
            relation_ids: ["walker", "user"],
            new_captain: null, // the one we will save upon creating the team
            captain_shell: null,
            adding_captain: false,
            creating_captain: false
        }
    }
}
</script>
