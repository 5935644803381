<template>
    <div id="log-your-first-trip">
        <h2>{{ $root.translate("Ready to log your first trip?") }}</h2>
        <h5 class="log-trip">{{ $root.translate("Log a new walk!") }}</h5>
        <add-trip-button
            :walker-id="walkerId"
        />
    </div>
</template>

<script>
import AddTripButton from "./AddTripButton"

export default {
    name: "LogFirstTrip",
    components: {
        AddTripButton
    },
    props: {
        walkerId: {
            type: Number,
            default: 0 // defaults to current walker
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#log-your-first-trip {
    text-align: center;
    h2 {
        background-color: $gray-color-light;
        padding: ($control-padding-y-lg * 2) ($control-padding-x-lg * 2);
        border-radius: $unit-6;
        margin-bottom: $unit-16;
    }
    h6 {
        font-family: $body-font-family;
        margin-bottom: $unit-4;
        font-weight: bold;
    }
}
</style>
