<template>
    <select
        v-bind="input_props"
        v-on="$listeners"
        :class="{ [`select-${size}`]: true }"
        class="form-select"
    >
        <option
            v-if="!required"
            :value="emptyValue"
            :selected="value === emptyValue"
        >
            {{ emptyLabel }}
        </option>
        <option
            v-for="option in options"
            :key="option[idField]"
            :value="option[idField]"
            :selected="value === option[idField]"
            :disabled="(!!disabledField && !!option[disabledField]) || (!!enabledField && !option[enabledField])"
        >
            <slot name="option" :option="option">
                {{ option[labelField] }}
            </slot>
        </option>
    </select>
</template>

<script>
export default {
    name: "BaseDropdown",
    computed: {
        input_props () {
            const { value, size, idField, labelField, emptyValue, emptyLabel, options, ...props } = this.$props
            return props
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // object.idField value
            default: null
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        disabledField: {
            type: String,
            default: "" // empty = no disabling
        },
        enabledField: {
            type: String,
            default: "" // empty = no enabling
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default () {
                return []
            }
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
