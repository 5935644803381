<template>
    <page
        id="users-list"
        :title="$root.translate('User:::Users', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div class="page-outer-container">
            <div class="page-inner-container">
                <h3>
                    {{ $root.translate('User:::Users', {}, 2) }}
                </h3>
                <data-table
                    id="users-list"
                    :columns="visible_columns"
                    :rows="filtered_rows"
                    :search="state.search"
                    :total="state.total"
                    :found="state.found"
                    :current-page="state.page"
                    :total-pages="nb_pages"
                    :can-add="true"
                    :empty-add-button-title="$root.translate('Invite a new User...')"
                    :add-button-title="$root.translate('Invite a new User...')"
                    @sort="sort_by"
                    @search="search"
                    @page="goto_page"
                    @add="prepare_invitation"
                    @click="record_edit(entity, $event.uuid)"
                >
                    <template
                        v-slot:controls
                    >
                        <div>
                            <form-dropdown
                                id="role"
                                name="role"
                                :value="state.filter"
                                :options="roles"
                                :empty-value="0"
                                :empty-label="$root.translate('Any Role')"
                                :required="false"
                                @input="filter('role', $event)"
                            />
                        </div>
                    </template>
                    <template
                        v-slot:buttons
                    >
                        <export-button
                            :links="export_links"
                        />
                    </template>
                    <template
                        v-slot:summary="{ row }"
                    >
                        <h6>{{ row.name }}</h6>
                        <p class="text-small">
                            <mailto-link
                                :value="row.email"
                            >
                                {{ row.email }}
                            </mailto-link>
                        </p>
                    </template>
                    <template
                        v-slot:tbody="{ row, field }"
                    >
                        <div v-if="field === 'name'">
                            {{ row.name }}
                            <div v-if="!!row.invitation_expires_at" class="text-gray text-small">
                                <span v-if="invitation_expired(row)">{{ $root.translate("Invitation expired on") }}</span>
                                <span v-else>{{ $root.translate("Invitation valid until") }}</span>
                                {{ row.invitation_expires_at | nibnut.date("yyyy-MM-dd '@' HH:mm") }}
                                <default-button
                                    :title="$root.translate('Re-send invitation')"
                                    flavor="link"
                                    size="sm"
                                    @click.prevent.stop="resend_invitation(row)"
                                >
                                    <open-icon glyph="share" />
                                </default-button>
                            </div>
                        </div>
                        <span v-else-if="field === 'role'">
                            {{ role_name(row.role) }}
                        </span>
                        <span v-else-if="field === 'email'">
                            <mailto-link
                                :value="row.email"
                            >
                                {{ row.email }}
                            </mailto-link>
                        </span>
                        <span v-else>{{ row[field] }}</span>
                    </template>
                </data-table>
            </div>
        </div>

        <invite-user-dialog
            :show.sync="inviting"
            :user="invitee"
        />
        <reinvite-dialog
            :show.sync="reinviting"
            :user="invitee"
        />

    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, crud_utilities, is_user_inviter } from "@/nibnut/mixins"

import {
    DefaultButton,
    MailtoLink,
    FormDropdown,
    OpenIcon
} from "@/nibnut/components"
import {
    ExportButton
} from "@/custom/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, is_user_inviter],
    components: {
        DefaultButton,
        MailtoLink,
        FormDropdown,
        OpenIcon,
        ExportButton
    },
    computed: {
        can_refresh () {
            return this.is_public_page || (this.profile_id && this.is_at_least_administrator)
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        export_links () {
            const links = [
                { title: this.$root.translate("Current Edition Data"), url: this.export_url }
            ]
            if(this.is_at_least_administrator) {
                const all_data_state = {
                    ...this.state,
                    archived: true
                }
                links.push({ title: this.$root.translate("All Data"), url: this.build_export_url(all_data_state) })
            }
            return links
        }
    },
    data () {
        return {
            entity: "user",
            columns: {
                email: { label: "Email", sort: null, type: "alpha" },
                role: { label: "Role", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "email",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
#users-list {
    .btn-group {
        flex-wrap: nowrap;
    }
}
</style>
