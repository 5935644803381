<template>
    <div class="nibnut">
        <div class="nibnut-app">
            <div
                :class="{ navigating }"
                class="body"
            >
                <main>
                    <router-view></router-view>
                </main>
                <app-sidenav
                    v-if="!!profile_id"
                    :navigating="navigating"
                    :installable="!is_installed && installable"
                    @navigate="set_navigation"
                    @install="maybe_show_installation_prompt"
                />
                <div id="guest-navbar">
                    <base-link
                        v-if="!!setting('alt_resources_page_url') && ((!profile_id || !walker_uuid))"
                        id="guest-resources1"
                        :href="setting('alt_resources_page_url')"
                        target="_blank"
                        class="btn btn-sm btn-language"
                    >
                        <span class="show-sm">{{ $root.translate("OSTA") }}</span>
                        <span class="hide-sm">{{ $root.translate("OSTA Resources") }}</span>
                    </base-link>
                    <base-link
                        v-if="!!setting('resources_page_url') && ((!profile_id || !walker_uuid))"
                        id="guest-resources2"
                        :href="setting('resources_page_url')"
                        target="_blank"
                        class="btn btn-sm btn-language"
                    >
                        {{ $root.translate("Resources") }}
                    </base-link>
                    <router-link
                        :to="{ name: 'faq' }"
                        v-slot="{ href, navigate }"
                        custom
                    >
                        <base-link
                            v-if="(!profile_id || !walker_uuid)"
                            id="guest-faq"
                            :href="href"
                            class="btn btn-sm btn-language"
                            @click.native="navigate_to(navigate, $event)"
                        >
                            {{ $root.translate("FAQ") }}
                        </base-link>
                    </router-link>
                    <default-button
                        v-if="(!profile_id || !walker_uuid) && ($route.name !== 'signup')"
                        id="guest-language-picker"
                        size="sm"
                        class="btn-language"
                        @click.prevent="translate_to(alternate_language)"
                    >
                        <span v-if="localization === 'en'">{{ $root.translate("FR") }}</span>
                        <span v-else>{{ $root.translate("EN") }}</span>
                    </default-button>
                </div>
                <default-button
                    v-if="!!profile_id && !!walker_uuid"
                    class="btn-menu"
                    @click.prevent="navigating = !navigating"
                >
                    <open-icon glyph="ellipsis-v" size="2x" />
                </default-button>
                <default-button
                    v-if="!!$route.meta && !!$route.meta.back && (!$route.meta.login_required || (!!$route.meta.login_required && !!this.profile_id && !!this.walker_uuid))"
                    class="btn-back"
                    @click.prevent="go_back"
                >
                    <open-icon glyph="arr" size="lg" library="hop" />
                </default-button>
                <div v-if="!!setting('env')" class="env-marker">{{ setting('env') }}</div>
            </div>
            <app-footer />
        </div>

        <offline v-if="offline" />
        <div v-else-if="bootstrapping" class="full_page_loader">
            <div>
                <app-logo />
                <loader size="lg" />
            </div>
        </div>

        <modal-dialog
            v-if="!!app_context && !!app_context.settings"
            id="pwa-install-prompt"
            :show.sync="showing_installation_prompt"
        >
            <template v-slot:title>
                <span class="h5">{{ install_prompt_title }}</span>
            </template>

            <div>
                <p class="mb-8">{{ $root.translate("You can add the {application_name} application to your home screen, so it's easy to access:", { application_name: app_context.settings.application_name }) }}</p>
                <div v-if="!!install_event" class="btn_install">
                    <base-link
                        href="#"
                        @click.prevent="install"
                    >
                        {{ $root.translate("Install on my device") }}
                    </base-link>
                </div>
                <p v-else v-html="install_prompt_ios_instructions"></p>
                <p class="text-small mt-8">
                    {{ $root.translate("You can install the app at any time through the navigation menu. (top right corner)") }}
                </p>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        color="primary"
                        size="sm"
                        @click.prevent="showing_installation_prompt = false"
                    >
                        {{ $root.translate("Not now") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <system-message
            v-if="is_safari&&!!system_message.message"
            :message="system_message"
            :countdown="message_countdown"
            @click="dismiss_message"
        />
        <transition v-else name="flip" enter-active-class="animated flipInX" leave-active-class="animated flipOutX" mode="out-in">
            <system-message
                v-if="!!system_message.message"
                :message="system_message"
                :countdown="message_countdown"
                @click="dismiss_message"
            />
        </transition>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { ui_utilities, string_utilities } from "@/nibnut/mixins"
import { language_utilities } from "@/custom/mixins"

import AppFooter from "./AppFooter"
import AppSidenav from "./AppSidenav"
import SystemMessage from "./SystemMessage"
import { Offline } from "@/nibnut/dialogs"
import { Loader } from "@/custom/components"
import { AppLogo, ModalDialog, BaseLink, DefaultButton, OpenIcon } from "@/nibnut/components"

import ios_action_button from "@/assets/img/Navigation_Action_2x.png"

let timer = null

const is_ios = () => {
    if(!window.MSStream) {
        const user_agent = window.navigator.userAgent
        if(/iphone|ipad|ipod/i.test(user_agent)) {
            const start = user_agent.indexOf("OS ")
            return ((start < 0) || (window.Number(user_agent.substr(start + 3, 3).replace("_", ".")) > 10))
        }
    }
    return false
}
const is_ios_installed = () => {
    return ("standalone" in window.navigator) && window.navigator.standalone
}

export default {
    components: {
        AppFooter,
        AppSidenav,
        SystemMessage,
        Offline,
        AppLogo,
        ModalDialog,
        BaseLink,
        DefaultButton,
        OpenIcon,
        Loader
    },
    mixins: [language_utilities, ui_utilities, string_utilities],
    created () {
        try {
            this.is_safari = navigator.appVersion && navigator.appVersion.match(/Safari/) && !navigator.appVersion.match(/Chrome/)
            if(this.is_safari) {
                setTimeout(() => {
                    document.body.className += " is-safari"
                }, 500)
            }
        } catch (e) {
        }

        document.addEventListener("pwa-updated", this.app_updated, { once: true })
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            if(this.app_updating) return
            this.app_updating = true
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName)
                })
            })
            window.location.reload(true)
        })

        const requested_language = window.location.search.match(/(?:\?|&)lang=(en|fr)/)
        if(requested_language && (this.localization !== requested_language[1])) this.translate_to(requested_language[1])

        const matches = window.location.pathname.match(/^\/t\/(.+)$/i)
        if(matches) {
            if(matches[1]) {
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "team",
                        id: matches[1]
                    }
                ).then(team => {
                    this.$store.dispatch("AUTO_JOIN_TEAM", { team }).then(() => {
                        this.$router.replace({ name: "signup" })
                    })
                }).catch(() => {
                })
            }
            this.$router.replace("/")
        }

        window.addEventListener("beforeinstallprompt", this.bootstrap_installation)
        window.addEventListener("online", this.online_status_changed)
        window.addEventListener("offline", this.online_status_changed)
    },
    mounted () {
        this.load()
    },
    beforeRouteLeave () {
        this.set_navigation(false)
    },
    beforeDestroy () {
        window.removeEventListener("beforeinstallprompt", this.bootstrap_installation)
        window.removeEventListener("online", this.online_status_changed)
        window.removeEventListener("offline", this.online_status_changed)
    },
    watch: {
        "system_message.message": "system_message_changed",
        "login_request.panel_id": "show_next_post_login_screen"
    },
    methods: {
        load () {
            this.$store.dispatch("LOAD_PROFILE").then(() => {
                this.maybe_show_login_message()
            }).catch(() => {
                // ignore any error
            })
        },
        bootstrap_installation (install_event) {
            install_event.preventDefault()
            this.install_event = install_event
        },
        set_navigation (navigating = null) {
            if(navigating === null) navigating = !this.navigating
            this.navigating = navigating
        },
        go_back () {
            if(!!this.$route.meta && !!this.$route.meta.back) {
                if(this.$route.meta.back === true) this.$router.go(-1)
                else this.$router.push(this.$route.meta.back)
            }
        },
        online_status_changed () {
            this.$store.dispatch("EVALUATE_ONLINE_STATUS")
        },
        show_next_post_login_screen () {
            if(!!this.profile_id && !this.login_request.panel_id) {
                const introduced = this.$cookie.get("pwa-introduced")
                // if(!introduced || (!introduced && this.installable_manually)) {
                if(!introduced && this.installable) {
                    this.$cookie.set("pwa-introduced", true, { expires: "1Y" })
                    this.showing_installation_prompt = true
                }
            }
        },
        system_message_changed () {
            if(timer) {
                clearInterval(timer)
                timer = null
            }
            if(this.system_message.message) {
                this.message_countdown = this.system_message.dismiss_after || 7000
                const step = 10
                timer = setInterval(() => {
                    this.message_countdown -= step
                    if(this.message_countdown <= 0) {
                        clearInterval(timer)
                        timer = null
                        this.dismiss_message()
                    }
                }, step)
            }
        },
        dismiss_message (event) {
            const system_message = this.system_message
            const system_message_id = system_message ? system_message.message_id : null
            this.$store.dispatch("SYSTEM_MESSAGE", {
                type: "warning",
                message: "",
                dismiss_after: 0,
                message_id: null
            })
            if(!!system_message_id && (system_message_id === "update-app")) {
                this.update_app()
            }
        },
        app_updated (event) {
            this.app_registration = event.detail
            this.$warn(this.$root.translate("A new version of this app is available. Click here to upgrade now."), 30000, "update-app")
        },
        update_app () {
            if(!this.app_registration || !this.app_registration.waiting) return
            this.app_registration.waiting.postMessage({ type: "SKIP_WAITING" })
        },
        maybe_show_installation_prompt () {
            this.set_navigation(false)
            // ** user clicked the button... If we're not on iOS, trigger the browser prompt directly.
            // else show the install instructions dialog
            if(this.installable_manually) this.showing_installation_prompt = true
            else if(this.installable) this.install()
        },
        install () {
            this.showing_installation_prompt = false
            if(!this.install_event) return

            this.install_event.prompt()
            // Wait for the user to respond to the prompt
            this.install_event.userChoice.then(choice => {
                if(choice.outcome === "accepted") {
                    // console.log("User accepted the A2HS prompt")
                } else {
                    // console.log("User dismissed the A2HS prompt")
                }
                this.install_event = null
            })
        }
    },
    computed: {
        ...mapState(["offline", "bootstrapping", "system_message", "login_request"]),
        installable () {
            return (is_ios() && !is_ios_installed()) || !!this.install_event // (!!this.install_event && !this.$cookie.get("do-not-install"))
        },
        is_installed () {
            return !!window.matchMedia("(display-mode: standalone)").matches || is_ios_installed()
        },
        installable_manually () {
            return !this.install_event && is_ios() && !is_ios_installed()
        },
        install_prompt_title () {
            if(!this.install_event) return this.$root.translate("Install on my device")
            return this.$root.translate("How to install on my device")
        },
        install_prompt_ios_instructions () {
            const instructions = this.$root.translate("Simply tap the [image] icon at the bottom of your screen, and then select the 'Add to Home Screen' option.")
            const matches = instructions.match(/^(.*?)\[([^\]]+?)\](.*?)$/)
            if(matches) {
                return `${matches[1]}<img src="${ios_action_button}" style="height: 1em;" class="mx-3" />${matches[3]}`
            }
            return instructions
        }
    },
    data () {
        return {
            is_safari: false,
            install_event: null,
            showing_installation_prompt: false,
            navigating: false,
            message_countdown: 0,

            reporting_bug: false,

            app_updating: false,
            app_registration: null
        }
    }
}
</script>

<style lang="scss">
@use "sass:math";
@use "sass:list";
@import "@/assets/sass/app";

.nibnut {
    & > .nibnut-app {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        & > div.body {
            $button-size: 2rem;

            position: relative;
            flex: 1 0 auto;
            max-width: 100%;
            overflow-x: hidden;
            z-index: $zindex-1;

            & > .env-marker {
                position: fixed;
                font-size: 0.8em;
                left: $unit-4;
                top: $unit-4;
                padding: $unit-1 $unit-2;
                border-radius: 0.5em;
                background-color: fuchsia;
                color: white;
            }

            & > #guest-navbar > .btn,
            & > .btn {
                border-radius: 50%;
                z-index: $zindex-3;

                & > span {
                    position: absolute;
                    display: inline-block;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -45%);
                }
                &:not(.btn-language) {
                    position: fixed;
                    height: 2rem;
                    width: 2rem;
                    background-color: white;
                    color: $brand-color;
                    box-shadow: 0 0.2rem 0.2rem 0rem rgb(0 0 0 / 20%);
                    border: 0;
                }

                &.btn-language {
                    @include button-variant($brand-yellow);
                    flex: 0 0 auto;
                    font-size: 0.6rem;
                    height: 0.9rem;
                    line-height: 0.9rem;
                    padding: 0.1rem 0.5rem;
                    border-radius: 0.5rem;
                    text-transform: uppercase;
                    transition: transform 0.3s ease-in-out;
                    color: $brand-brown;

                    & > span {
                        position: relative;
                        top: 0.35rem;
                    }

                    &:active,
                    &.active,
                    &:focus {
                        color: $brand-brown;
                    }
                    @include hover-supported {
                        color: $brand-brown;
                    }
                }
                &.btn-menu {
                    top: $layout-spacing-lg;
                    // right: $layout-spacing-lg;
                    right: Max(list.slash(calc(100vw - 980px), 2), $layout-spacing-lg);
                    transition: transform 0.3s ease-in-out;
                }
                &.btn-back {
                    bottom: 48px;
                    // left: math.div(980px, 2);
                    left: Max(list.slash(calc(100vw - 980px), 2), $layout-spacing-lg);
                    & > span {
                        transform: translate(-50%, -55%);
                    }
                }
            }
            & > nav.nibnut-sidenav {
                position: fixed;
                top: 0;
                height: 100vh;
                width: 100vw;
                left: 100vw; // 0
                color: white;
                background-color: $brand-color;
                opacity: 0;
                overflow: auto;
                // pointer-events: none;
                z-index: $zindex-2;
                transition: opacity 0.3s ease-in-out;

                & > ul.nav {
                    margin: ($button-size + $layout-spacing-lg) auto 0 auto;
                    width: 80%;
                    max-width: 960px;

                    a,
                    .btn {
                        &:active, &.active, &:focus, &:visited {
                            background: $bg-color-light;
                        }
                        @include hover-supported {
                            background: $bg-color-light;
                        }
                    }
                    a {
                        &, &:visited {
                            color: white;
                        }
                        &:active, &:focus {
                            color: $brand-color;
                        }
                        @include hover-supported {
                            color: $brand-color;
                        }
                    }
                    .btn {
                        border-radius: 1rem;
                    }
                }
            }

            &.navigating {
                & > .btn {
                    &.btn-menu {
                        transform: rotate(90deg);
                        box-shadow: none;
                    }
                    &.btn-back {
                        display: none;
                    }
                }
                & > nav.nibnut-sidenav {
                    left: 0;
                    opacity: 1;
                    // pointer-events: auto;
                }
            }
        }
        & > footer {
            flex: 0 0 100%;
            bottom: 0;
            text-align: center;
            font-weight: normal;
            color: $dark-color;
            background: $gray-color-light no-repeat bottom right url('~@/assets/img/Graphic_footer_desktop.png');
            padding: 3rem 0;

            img.img-responsive {
                margin: 0 auto;
            }

            section {
                margin-bottom: 3rem;

                .columns.bordered {
                    & > .column {
                        padding: 0 $layout-spacing-lg;

                        &:first-child {
                            text-align: right;
                        }
                        &:last-child {
                            text-align: left;
                        }
                    }
                    & > .column + .column {
                        border-left: 1px solid $dark-color;
                    }

                    &.legalese {
                        justify-content: center;
                        font-weight: normal;
                        width: 100%;
                        margin: auto;
                        & > .column {
                            flex: 0 0;
                            white-space: nowrap;
                            &:first-child, &:last-child {
                                text-align: center;
                            }

                            a {
                                font-family: $body-font-family;
                            }
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
            @media (max-width: $size-sm) {
                background: $gray-color-light no-repeat bottom center url('~@/assets/img/Graphic_footer.png');
                section {
                    .columns {
                        margin-left: 0;
                        margin-right: 0;
                    }
                    .columns.bordered {
                        & > .column {
                            &:first-child {
                                text-align: center;
                                margin-bottom: $unit-4;
                            }
                            &:last-child {
                                text-align: center;
                            }
                        }
                        & > .column + .column {
                            border: 0;
                        }
                    }
                    .columns.justify-content-center.align-items-center {
                        img {
                            max-width: 2.5rem;
                        }
                    }
                    .text-xsmall {
                        max-width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    & > .full_page_loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        text-align: center;
        background-color: $bg-color;
        transition: all 0.3s ease-in-out;
        z-index: $zindex-5;

        & > div {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            & > img {
                max-height: 5rem;
                margin: 0 auto $unit-8  auto;
            }
            & > .loader {
                position: relative;
                display: inline-block;
            }
        }
    }
    & > .toast {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: $top-nav-height;
        z-index: $zindex-system-messages;

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100%;
            overflow-y: auto;
        }

        progress {
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -1px;
            width: auto;
            color: $light-color;

            &::-webkit-progress-bar {
                background: transparent;
            }
            &::-webkit-progress-value {
                background: $light-color;
            }
            &::-moz-progress-bar {
                background: $light-color;
            }
        }
        .btn-clear {
            position: absolute;
            top: 50%;
            right: $control-padding-x-lg;
            transform: translate(0, -50%);
        }

        &.toast-primary progress {
            background: $primary-color;
        }
        &.toast-success progress {
            background: $success-color;
        }
        &.toast-warning progress {
            background: $warning-color;
        }
        &.toast-error progress {
            background: $error-color;
        }
    }
    .popover-container {
        .calendar-container {
            .calendar-body {
                .calendar-date {
                    & > .btn {
                        height: 1.133rem;
                    }
                }
            }
        }
    }
    .page-outer-container {
        margin: 0 auto;
        max-width: 980px;
        box-sizing: border-box;
    }
    .page-inner-container {
        margin: 0 auto;
        width: 70%;
        max-width: 680px;
        box-sizing: border-box;
    }
    @media (max-width: $size-sm) {
        .page-outer-container,
        .page-inner-container {
            width: 100%;
            max-width: none;
        }
        .page-outer-container {
            width: calc(100% - 50px);
            margin: 0 auto;
        }
        .page-inner-container {
            margin: 0;
        }
    }
}
</style>
