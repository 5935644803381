const is_nibnut_component = {
    methods: {
        has_slot (slot_name) {
            return !!this.$slots && !!this.$slots[slot_name]
        }
    },
    computed: {
        identifier () {
            return this.id || this.name
        }
    }
}

export default is_nibnut_component
