<template>
    <base-link
        href="#close"
        :aria-label="$root.translate('Close')"
        aria-hidden="true"
        @click.prevent="$emit('click')"
    />
</template>

<script>
import BaseLink from "@/nibnut/components/Links/BaseLink"

export default {
    name: "DismissLink",
    components: {
        BaseLink
    }
}
</script>
