<template>
    <ul v-if="!!walker" class="treasures">
        <li
            v-for="treasure in filtered_rows"
            :key="treasure.id"
            :class="{ active: achieved(treasure) }"
        >
            <div
                class="nibnut-aspect-ratio-box nibnut-ratio-square"
            >
                <div>
                    <img v-if="!treasure.icon && achieved(treasure)" :src="treasure.on_image_url" />
                    <img v-else-if="!treasure.icon" :src="treasure.off_image_url" />
                    <div
                        v-else
                        :class="{ active: achieved(treasure) }"
                        class="treasure-icon"
                    >
                        <open-icon :glyph="treasure.icon" library="hop" />
                    </div>
                </div>
            </div>
            <div>
                <div class="treasure-title">{{ treasure.title }}</div>
                <div class="treasure-subtitle">{{ goal(treasure) }}</div>
            </div>
        </li>
    </ul>
</template>

<script type="text/javascript">
import is_achievement_panel from "@/custom/mixins/IsAchievementPanel"

import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "Treasures",
    mixins: [is_achievement_panel],
    components: {
        OpenIcon
    },
    computed: {
        state_identifier () {
            return "treasures"
        }
    },
    data () {
        return {
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "id",
                sort_dir: "asc",
                filter_on: "type",
                filter: "treasure",
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
@use "sass:math";
@import "@/assets/sass/variables";

.treasures {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > li {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0 0 33.33%;
        width: 33.33%;
        padding: $unit-2 $unit-3;
        color: $brand-gray;
        text-align: center;

        .treasure-icon {
            flex: 1 0 auto;
            background-color: $brand-gray-light;
            padding: $unit-3;
            border-radius: 50%;
            color: $brand-gray;
            width: 100%;
            height: 100%;

            & > i.hop {
                font-size: 4rem;
                line-height: 1em;
            }

            @media (max-width: $size-sm) {
                padding: math.div($unit-3, 2);
                & > i.hop {
                    font-size: 2.1rem;
                    line-height: 0.8em;
                }
            }
        }
        & > div:last-child {
            flex: 0 0 auto;
            margin-top: $unit-2;
            line-height: 1em;
            white-space: nowrap;
            text-align: center;

            .treasure-title {
                text-transform: uppercase;
                font-size: 0.8rem;
            }
            .treasure-subtitle {
                font-size: 0.6rem;
            }
            @media (max-width: $size-sm) {
                .treasure-title,
                .treasure-subtitle {
                    font-size: 0.5rem;
                }
            }
        }

        &.active {
            color: $primary-color;
            .treasure-icon {
                background-color: $brand-orange;
                color: $primary-color;
            }
        }

        @media (max-width: $size-sm) {
            &:last-child {
                width: 100%;
            }
        }
    }
}
</style>
