import handles_errors from "./HandlesErrors"

const crud_utilities = {
    mixins: [handles_errors],
    methods: {
        record_edit (entity, id = 0) {
            this.$router.push({ name: `${entity}.edit`, params: { id } })
        },
        record_create (entity) {
            this.record_edit(entity)
        },
        record_shell (entity) {
            return this.$store.dispatch("FETCH_RECORD_SHELL", { entity }).then(record => {
                return record
            }).catch(error => {
                this.$error(error.message)
            })
        }
    }
}

export default crud_utilities
