<template>
    <nav
        :class="{ active: navigating }"
        class="nibnut-sidenav"
    >
        <ul class="nav">
            <li
                v-if="!!profile_id && (profile.walker_count > 1)"
                class="nav-item"
            >
                <base-link
                    href="#"
                    @click.prevent="switch_walker"
                >
                    {{ $root.translate("Switch Profile") }}
                </base-link>
            </li>
            <router-link
                :to="{ name: 'walker.profile' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("My Profile and Past Trips") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                :to="{ name: 'profile' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("My Account") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                :to="{ name: 'home' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("My Dashboard") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="!!is_captain && !profile.team_uuid"
                :to="{ name: 'team.edit' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("Create My Team") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-else-if="!!profile && !!profile.team_uuid"
                :to="{ name: 'team.dashboard', params: { id: profile.team_uuid } }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("My Team Dashboard") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                :to="{ name: 'faq' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("FAQs") }}
                    </base-link>
                </li>
            </router-link>
            <li
                v-if="!!setting('resources_page_url')"
                class="nav-item"
            >
                <base-link
                    :href="setting('resources_page_url')"
                    target="_blank"
                    @click.native="navigate_to(null, $event)"
                >
                    {{ $root.translate("Resources") }}
                </base-link>
            </li>
            <li
                v-if="!!setting('alt_resources_page_url')"
                class="nav-item"
            >
                <base-link
                    :href="setting('alt_resources_page_url')"
                    target="_blank"
                    @click.native="navigate_to(null, $event)"
                >
                    {{ $root.translate("OSTA Resources") }}
                </base-link>
            </li>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'challenges' }"
                v-slot="{ href, navigate }"
                custom
                class="mt-8"
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("Challenges") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'walker.list' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("Walkers") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_regional_admin"
                :to="{ name: 'team.list' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("Teams") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'user.list' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("Users") }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'schoolboards' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate('School Board:::School Boards', {}, 2) }}
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'settings' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        @click.native="navigate_to(navigate, $event)"
                    >
                        {{ $root.translate("Settings") }}
                    </base-link>
                </li>
            </router-link>
            <li
                class="nav-item mt-8"
            >
                <default-button
                    color="light"
                    @click.prevent="logout"
                >
                    {{ $root.translate("Sign Out") }}
                </default-button>
            </li>
            <li
                class="nav-item mt-5"
            >
                <default-button
                    @click.prevent="translate_to(alternate_language)"
                >
                    <span v-if="localization === 'en'">{{ $root.translate("FR") }}</span>
                    <span v-else>{{ $root.translate("EN") }}</span>
                </default-button>
            </li>
            <li
                v-if="installable"
                class="nav-item mt-8"
            >
                <base-link
                    href="#"
                    @click.prevent="$emit('install')"
                >
                    <open-icon glyph="arrow-circle-down" size="1x" />
                    <span>{{ $root.translate("Install") }}</span>
                </base-link>
            </li>
        </ul>
    </nav>
</template>

<script>
import { profile_utilities, misc_utilities, string_utilities } from "@/nibnut/mixins"
import { language_utilities } from "@/custom/mixins"

import { BaseLink, DefaultButton, OpenIcon } from "@/nibnut/components"

import nibnut_logo from "@/assets/img/nibnut.png"

export default {
    mixins: [profile_utilities, misc_utilities, string_utilities, language_utilities],
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    methods: {
        switch_walker () {
            this.navigate_to(null)
            this.$store.dispatch("SET_CURRENT_WALKER", { walker_uuid: null })
        },
        navigate_to (navigate, event) {
            if(navigate) navigate(event)
            this.$emit("navigate", false)
        },
        logout () {
            this.navigate_to(null)
            this.$store.dispatch("LOGOUT")
        }
    },
    computed: {
        nibnut_logo () {
            return nibnut_logo
        }
    },
    props: {
        navigating: {
            type: Boolean,
            required: true
        },
        installable: {
            type: Boolean,
            required: false
        }
    }
}
</script>
