var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nibnut-sidenav",class:{ active: _vm.navigating }},[_c('ul',{staticClass:"nav"},[(!!_vm.profile_id && (_vm.profile.walker_count > 1))?_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switch_walker.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Switch Profile"))+" ")])],1):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'walker.profile' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("My Profile and Past Trips"))+" ")])],1)]}}])}),_c('router-link',{attrs:{"to":{ name: 'profile' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("My Account"))+" ")])],1)]}}])}),_c('router-link',{attrs:{"to":{ name: 'home' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("My Dashboard"))+" ")])],1)]}}])}),(!!_vm.is_captain && !_vm.profile.team_uuid)?_c('router-link',{attrs:{"to":{ name: 'team.edit' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Create My Team"))+" ")])],1)]}}],null,false,1451201164)}):(!!_vm.profile && !!_vm.profile.team_uuid)?_c('router-link',{attrs:{"to":{ name: 'team.dashboard', params: { id: _vm.profile.team_uuid } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("My Team Dashboard"))+" ")])],1)]}}])}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'faq' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("FAQs"))+" ")])],1)]}}])}),(!!_vm.setting('resources_page_url'))?_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":_vm.setting('resources_page_url'),"target":"_blank"},nativeOn:{"click":function($event){return _vm.navigate_to(null, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Resources"))+" ")])],1):_vm._e(),(!!_vm.setting('alt_resources_page_url'))?_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":_vm.setting('alt_resources_page_url'),"target":"_blank"},nativeOn:{"click":function($event){return _vm.navigate_to(null, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("OSTA Resources"))+" ")])],1):_vm._e(),(_vm.is_at_least_administrator)?_c('router-link',{staticClass:"mt-8",attrs:{"to":{ name: 'challenges' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Challenges"))+" ")])],1)]}}],null,false,2501337553)}):_vm._e(),(_vm.is_at_least_administrator)?_c('router-link',{attrs:{"to":{ name: 'walker.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Walkers"))+" ")])],1)]}}],null,false,4162991860)}):_vm._e(),(_vm.is_at_least_regional_admin)?_c('router-link',{attrs:{"to":{ name: 'team.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Teams"))+" ")])],1)]}}],null,false,3285215023)}):_vm._e(),(_vm.is_at_least_administrator)?_c('router-link',{attrs:{"to":{ name: 'user.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Users"))+" ")])],1)]}}],null,false,2316522819)}):_vm._e(),(_vm.is_at_least_administrator)?_c('router-link',{attrs:{"to":{ name: 'schoolboards' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate('School Board:::School Boards', {}, 2))+" ")])],1)]}}],null,false,2692494204)}):_vm._e(),(_vm.is_at_least_administrator)?_c('router-link',{attrs:{"to":{ name: 'settings' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":href},nativeOn:{"click":function($event){return _vm.navigate_to(navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Settings"))+" ")])],1)]}}],null,false,3988201700)}):_vm._e(),_c('li',{staticClass:"nav-item mt-8"},[_c('default-button',{attrs:{"color":"light"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$root.translate("Sign Out"))+" ")])],1),_c('li',{staticClass:"nav-item mt-5"},[_c('default-button',{on:{"click":function($event){$event.preventDefault();return _vm.translate_to(_vm.alternate_language)}}},[(_vm.localization === 'en')?_c('span',[_vm._v(_vm._s(_vm.$root.translate("FR")))]):_c('span',[_vm._v(_vm._s(_vm.$root.translate("EN")))])])],1),(_vm.installable)?_c('li',{staticClass:"nav-item mt-8"},[_c('base-link',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('install')}}},[_c('open-icon',{attrs:{"glyph":"arrow-circle-down","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$root.translate("Install")))])],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }