<template>
    <div>
        <form-toggle-input
            id="bug_reporter_active"
            name="bug_reporter_active"
            type="checkbox"
            v-model="record.bug_reporter_active"
            :true-value="1"
            :false-value="0"
            :required="false"
            :saving="saving('bug_reporter_active')"
            :error="has_error('bug_reporter_active')"
            @input="save"
        >
            {{ $root.translate('Bug Reporter') }}
        </form-toggle-input>

        <div v-if="!!record.bug_reporter_active && !!setting('bitbucket_accessible')" class="pl-8 my-4">
            <img src="@/assets/img/bitbucket.png" class="mr-4" style="height: 1em; vertical-align: middle;" />
            <base-link
                v-if="!setting('bitbucket_accessible')"
                :href="setting('bitbucket_auth_url')"
                :title="$root.translate('Connect to your BitBucket Account')"
                class="btn btn-success btn-sm"
            >
                {{ $root.translate("Connect") }}
            </base-link>
            <default-button
                v-else
                color="error"
                size="sm"
                @click.prevent="disconnect"
            >
                {{ $root.translate("Disconnect") }}
            </default-button>
        </div>

        <div v-if="!!record.bug_reporter_active && !!setting('bitbucket_accessible')" class="pl-8">
            <span v-if="!!record.bitbucket_repo" class="text-italic">{{ $root.translate("Create issues in Bitbucket repo {name}", { name: record.bitbucket_repo }) }}</span>
            <em v-else>{{ $root.translate("No Bitbucket repo detected") }}</em>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { profile_utilities, handles_saving, misc_utilities } from "@/nibnut/mixins"

import { FormToggleInput, BaseLink, DefaultButton } from "@/nibnut/components"

export default {
    name: "BitbucketConnector",
    mixins: [profile_utilities, handles_saving, misc_utilities],
    components: {
        FormToggleInput,
        BaseLink,
        DefaultButton
    },
    methods: {
        grab_record_id () {
            this.record_id = 1
        },
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                if(this.edited_record.id) return this.save_field(this.edited_record[field], field)
            }
            return Promise.resolve()
        },
        disconnect () {
            this.save_field(false, "bitbucket_oauth")
        }
    },
    computed: {
        ...mapState(["app_context"])
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            record_id: 1,
            entity: "setting"
        }
    }
}
</script>
