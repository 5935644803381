<template>
    <page
        id="faq"
        :title="$root.translate('FAQs')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div :class="{ 'page-outer-container': !editing }">
            <section :class="{ 'page-inner-container': !editing }">
                <h2 class="text-center">
                    {{ $root.translate('FAQs') }}
                </h2>
                <div v-if="is_at_least_administrator" class="text-center mb-3">
                    <default-button
                        flavor="link"
                        @click.prevent="editing=!editing"
                    >
                        <span v-if="editing">{{ $root.translate("Done") }}</span>
                        <span v-else>{{ $root.translate("Edit") }}</span>
                    </default-button>
                </div>
                <div class="columns">
                    <div
                        :class="{ 'col-12': !editing, 'col-6': editing }"
                        class="column col-sm-12"
                    >
                        <h6 v-if="editing">{{ $root.translate("English") }}</h6>
                        <faq
                            v-for="faq in filtered_rows"
                            :key="faq.id"
                            :record="faq"
                            :editing="editing ? 'EN' : ''"
                            @remove="confirm_record_delete"
                        />
                    </div>
                    <div
                        v-if="editing"
                        class="column col-6 col-sm-12"
                    >
                        <h6>{{ $root.translate("French") }}</h6>
                        <faq
                            v-for="faq in filtered_rows"
                            :key="faq.id"
                            :record="faq"
                            :editing="editing ? 'FR' : ''"
                            @remove="confirm_record_delete"
                        />
                    </div>
                    <div v-if="editing && !edited_record" class="column col-12 text-center">
                        <default-button
                            v-if="!edited_record"
                            color="primary"
                            @click.prevent="add_faq"
                        >
                            {{ $root.translate("Add a new Q & A") }}
                        </default-button>
                    </div>
                    <div v-if="editing && edited_record" class="column col-6">
                        <faq
                            :record="edited_record"
                            :editing="editing ? 'EN' : ''"
                            @remove="stop_adding"
                        />
                    </div>
                    <div v-if="editing && edited_record" class="column col-6">
                        <faq
                            :record="edited_record"
                            :editing="editing ? 'FR' : ''"
                            @remove="stop_adding"
                        />
                    </div>
                    <div v-if="editing && edited_record" class="column col-6">
                        <default-button
                            :block="true"
                            @click.prevent="stop_adding"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                    </div>
                    <div v-if="editing && edited_record" class="column col-6">
                        <default-button
                            color="primary"
                            :block="true"
                            @click.prevent="create_faq"
                        >
                            {{ $root.translate("Add") }}
                        </default-button>
                    </div>
                </div>
            </section>
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import { is_page, is_remote_data_table_source, confirms } from "@/nibnut/mixins"

import { DefaultButton } from "@/nibnut/components"
import Faq from "./Faq"

export default {
    mixins: [is_page, is_remote_data_table_source, confirms],
    components: {
        DefaultButton,
        Faq
    },
    watch: {
        localization: "reset"
    },
    methods: {
        add_faq () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "custom_text" }
            ).then(record => {
                this.edited_record = record
                this.edited_record.type = 1
                this.edited_record.sort_order = -1 // auto assign sort order on save
            }).catch(error => {
                this.$error(error.message)
            })
        },
        create_faq () {
            if(this.edited_record) {
                this.$store.dispatch("CREATE_RECORD", {
                    entity: "custom_text",
                    data: this.edited_record
                }).then(record => {
                    this.current_records_ids.push(record.uuid)
                    this.stop_adding()
                }).catch(this.receive_error)
            }
        },
        confirm_record_delete (record) {
            if(!record.id) this.stop_adding()
            else {
                this.confirm(
                    {
                        type: "error",
                        title: this.$root.translate("Delete {title}", { title: record.title }),
                        message: this.$root.translate("Do you really want to delete this Q & A? There is no undo..."),
                        cancel: this.$root.translate("Keep"),
                        ok: this.$root.translate("Delete"),
                        record
                    },
                    "delete-record"
                )
            }
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete(this.confirmation_props.record)
            else this.done_confirming()
        },
        record_delete (record) {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: record.id
                }
            ).then(record => {
                this.done_confirming()
            }).catch(this.receive_error)
        },
        stop_adding () {
            this.edited_record = null
        }
    },
    computed: {
        ...mapState(["localization"]),
        fields () {
            if(this.is_at_least_administrator) return ["fieldset::editing"]
            return ["fieldset::display"]
        }
    },
    data () {
        return {
            entity: "custom_text",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "sort_order",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                type: 1,
                total: 0,
                found: 0
            },
            editing: false,
            edited_record: null
        }
    }
}
</script>
