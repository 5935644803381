import Vue from "vue"
import PrismicVue from "@prismicio/vue"
import linkResolver from "@/prismic-link-resolver"

import is_page from "@/nibnut/mixins/IsPage"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import language_utilities from "@/custom/mixins/LanguageUtilities"

import Page from "@/nibnut/components/Page"

const is_remote_page = {
    mixins: [is_page, profile_utilities, language_utilities],
    components: {
        Page
    },
    watch: {
        current_language: "load_current_page_slug"
    },
    created () {
        Vue.use(PrismicVue, {
            endpoint: "https://" + process.env.VUE_APP_PRISMIC_REPO + ".cdn.prismic.io/api/v2",
            linkResolver,
            apiOptions: { accessToken: process.env.VUE_APP_PRISMIC_ACCESS_TOKEN }
        })
        this.load_current_page_slug()
    },
    beforeRouteUpdate (to, from, next) {
        this.load_data(to.params.slug)
        next()
    },
    methods: {
        load_current_page_slug () {
            this.load_data(this.$route.params.slug)
        },
        load_data (page_slug) {
            this.waiting = true
            const lang = (this.current_language === "fr") ? "fr-ca" : "en-ca"
            this.$prismic.client.getByUID("privacy_policy", page_slug, { lang }).then(document => {
                Object.keys(this.remote_data).forEach(field => {
                    if(field === "sections") this.remote_data[field] = document.data.body || []
                    else if(field === "seo") this.remote_data[field] = document.data.body1 || null
                    else this.remote_data[field] = document.data[field] || null
                })
                // console.log("DEBUG", { prismic: document.data, local: this.remote_data })
            }).catch(() => {
                if(this.is_at_least_administrator) {
                    this.missing = true
                    this.page_status = 200
                } else if(!this.optional) this.page_status = 404
            }).then(() => {
                this.waiting = false
            })
        }
    },
    data () {
        return {
            page_slug: null,
            remote_data: {
                title: null,
                sections: [],
                seo: null,
                content: null
            },
            optional: false,
            missing: false
        }
    }
}

export default is_remote_page
