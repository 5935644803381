<template>
    <page
        id="challenges"
        :title="$root.translate('Challenges')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div :class="{ 'page-outer-container': !editing }">
            <section :class="{ 'page-inner-container': !editing }">
                <h3>
                    <open-icon glyph="flag" size="lg" library="hop" />
                    {{ $root.translate('Upcoming Challenges') }}
                </h3>
                <challenge
                    v-for="(challenge, index) in rows"
                    :key="challenge.id"
                    :record="challenge"
                    :custom-text="custom_text_for_challenge(challenge)"
                    :index="index"
                    @delete="confirm_record_delete"
                />
                <challenge
                    v-if="edited_record"
                    :record="edited_record"
                    :custom-text="edited_text"
                    :index="rows.length"
                    :always-expanded="true"
                    @reset="stop_adding"
                    @refresh="record_added"
                    @delete="confirm_record_delete"
                />
                <h3 v-if="!edited_record" class="mt-8">{{ $root.translate("Add another challenge!") }}</h3>
                <div v-if="!edited_record" class="text-center">
                    <default-button
                        color="light"
                        size="lg"
                        :title="$root.translate('Add another challenge!')"
                        class="btn-add"
                        @click.prevent="add_challenge"
                    >
                        <open-icon glyph="add" library="hop" size="2x" />
                    </default-button>
                </div>
            </section>
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import {
    parseISO
} from "date-fns"

import { is_page, is_remote_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import { DefaultButton, OpenIcon } from "@/nibnut/components"
import Challenge from "./Challenge"

export default {
    mixins: [is_page, is_remote_data_table_source, handles_saving, confirms],
    components: {
        Challenge,
        DefaultButton,
        OpenIcon
    },
    methods: {
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            rows = rows.filter(row => row.type === this.state.type)
            if(this.state.filter_on && !this.state.filter_on.match(/^_/) && this.state.filter) {
                const today = new Date()
                today.setHours(0, 0, 0, 0)
                return rows.filter((row) => {
                    if((this.state.filter_on === "ends_on") && (this.state.filter === "upcoming")) return parseISO(row.ends_on) >= today
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        custom_text_for_challenge (row) {
            if(!row || !row.id) return {}
            return this.entity_records("custom_text").find(custom_text => custom_text.id === row.custom_text_id) || {}
        },
        confirm_record_delete (record) {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {title}", { title: record.title }),
                    message: this.$root.translate("Do you really want to delete this Challenge? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete"),
                    record
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete(this.confirmation_props.record)
            else this.done_confirming()
        },
        record_delete (record) {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: record.id
                }
            ).then(() => {
                this.done_confirming()
            }).catch(this.receive_error)
        },
        add_challenge () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "achievement" }
            ).then(achievement => {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    { entity: "custom_text" }
                ).then(custom_text => {
                    this.edited_record = achievement
                    this.edited_text = custom_text
                }).catch(error => {
                    this.$error(error.message)
                })
            }).catch(error => {
                this.$error(error.message)
            })
        },
        record_added () {
            this.refresh()
            this.stop_adding()
        },
        stop_adding () {
            this.edited_record = null
        }
    },
    computed: {
        state_identifier () {
            return "upcoming-challenges"
        }
    },
    data () {
        return {
            entity: "achievement",
            relation_ids: ["custom_text"],
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "starts_on",
                sort_dir: "desc",
                filter_on: "ends_on",
                filter: "upcoming",
                archived: false,
                search: "",
                type: "challenge",
                total: 0,
                found: 0
            },
            editing: false,
            edited_record: null,
            edited_text: null
        }
    }
}
</script>
