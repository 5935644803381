<template>
    <div class="authentication-panel panel-lost-password page-outer-container">
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            :has-required-fields="true"
            class="page-inner-container"
            @submit.prevent.stop="send"
        >
            <div class="panel-header">
                <app-logo />
            </div>
            <div class="panel-body">
                <p class="text-bold">{{ $root.translate("Enter your email and we will send you a password reset link with instructions to reset your password.") }}</p>
                <form-input
                    id="first_focusable"
                    v-model="email"
                    name="email"
                    type="email"
                    :error="has_error('email')"
                    :required="true"
                    :placeholder="$root.translate('Email')"
                    @input="update_email"
                />
            </div>
            <div class="modal-footer">
                <div class="columns">
                    <div class="column text-left">
                        <default-button
                            @click.prevent="goto('login')"
                        >
                            {{ $root.translate("Login") }}
                        </default-button>
                    </div>
                    <div class="column text-right">
                        <default-button
                            type="submit"
                            color="primary"
                            :waiting="loading"
                        >
                            {{ $root.translate("Send") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "./IsPanel"

import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    mixins: [is_panel],
    components: {
        BaseForm,
        FormInput,
        DefaultButton
    },
    methods: {
        send () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                this.$store.dispatch("SEND_PASSWORD_RESET",
                    {
                        email: this.email
                    }
                ).then(data => {
                    this.$success(data.message)
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout)
        }
    }
}
</script>
