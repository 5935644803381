<template>
    <div
        v-if="!!record"
        :class="{ active }"
        class="challenge expandable-list"
    >
        <div
            class="tile tile-centered list-tile"
        >
            <div class="tile-icon">
                <open-icon glyph="flag" size="lg" library="hop" />
            </div>
            <div class="tile-content">
                <div class="tile-title">
                    <span v-if="!record.title">{{ `${$root.translate("Challenge")} #${index + 1}` }}</span>
                    <span v-else>{{ record.title }}</span>
                    <span v-if="!!record.id" class="text-small ml-2">
                        {{ record.starts_on | nibnut.date }} - {{ record.ends_on | nibnut.date }}
                        <i v-if="record.is_active" class="ml-4">{{ $root.translate("Ongoing") }}</i>
                    </span>
                </div>
            </div>
            <div class="tile-action">
                <default-button
                    v-if="!!record.id && !alwaysExpanded"
                    flavor="link"
                    @click.prevent="toggle"
                >
                    <span v-if="expanded">{{ $root.translate("Hide") }}</span>
                    <span v-else>{{ $root.translate("Edit") }}</span>
                </default-button>
                <default-button
                    @click.prevent="remove"
                >
                    <open-icon glyph="close" size="lg" library="hop" />
                </default-button>
            </div>
        </div>
        <div class="content">
            <div class="columns">
                <div class="column col-6 col-sm-12">
                    <form-input
                        :id="field_id('title_EN')"
                        v-model="customText.title_EN"
                        name="title_EN"
                        :required="true"
                        :placeholder="$root.translate('Title')"
                        :saving="saving('title_EN')"
                        :error="has_error('title_EN')"
                        @input="custom_text_save"
                    >
                        <template v-slot:left_addon>
                            <span class="input-group-addon">
                                EN
                            </span>
                        </template>
                    </form-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-input
                        :id="field_id('title_FR')"
                        v-model="customText.title_FR"
                        name="title_FR"
                        :required="true"
                        :placeholder="$root.translate('Title')"
                        :saving="saving('title_FR')"
                        :error="has_error('title_FR')"
                        @input="custom_text_save"
                    >
                        <template v-slot:left_addon>
                            <span class="input-group-addon">
                                FR
                            </span>
                        </template>
                    </form-input>
                </div>
                <div class="column col-6 col-sm-12 mt-5">
                    <form-editor
                        id="content_EN"
                        :data-version="customText.id"
                        name="content_EN"
                        v-model="customText.content_EN"
                        size="lg"
                        :headers="0"
                        :required="false"
                        :saving="saving('content_EN')"
                        :error="has_error('content_EN')"
                        @input="custom_text_save"
                    />
                </div>
                <div class="column col-6 col-sm-12 mt-5">
                    <form-editor
                        id="content_FR"
                        :data-version="customText.id"
                        name="content_FR"
                        v-model="customText.content_FR"
                        size="lg"
                        :headers="0"
                        :required="false"
                        :saving="saving('content_FR')"
                        :error="has_error('content_FR')"
                        @input="custom_text_save"
                    />
                </div>
                <div class="column col-6 col-sm-12 mt-5">
                    <form-date-input
                        id="starts_on"
                        name="starts_on"
                        v-model="record.starts_on"
                        :required="false"
                        position="top"
                        placeholder="00/00/00"
                        :saving="saving('starts_on')"
                        :error="has_error('starts_on')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("From") }}</template>
                    </form-date-input>
                </div>
                <div class="column col-6 col-sm-12 mt-5">
                    <form-date-input
                        id="ends_on"
                        name="ends_on"
                        v-model="record.ends_on"
                        :required="false"
                        position="top"
                        placeholder="00/00/00"
                        :saving="saving('ends_on')"
                        :error="has_error('ends_on')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("To") }}</template>
                    </form-date-input>
                </div>
                <div class="column col-6 col-sm-12 mt-5">
                    <form-dropdown
                        id="units"
                        name="units"
                        v-model="record.units"
                        :options="units"
                        :required="true"
                        :show-all="true"
                        :saving="saving('units')"
                        :error="has_error('units')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Units") }}</template>
                    </form-dropdown>
                </div>
                <div class="column col-2 col-sm-4 mt-5">
                    <form-input
                        id="gold"
                        name="gold"
                        v-model="record.gold"
                        type="stripe"
                        :required="true"
                        :saving="saving('gold')"
                        :error="has_error('gold')"
                        class="award-goal"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Gold") }}</template>
                    </form-input>
                </div>
                <div class="column col-2 col-sm-4 mt-5">
                    <form-input
                        id="silver"
                        name="silver"
                        v-model="record.silver"
                        type="stripe"
                        :required="true"
                        :saving="saving('silver')"
                        :error="has_error('silver')"
                        class="award-goal"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Silver") }}</template>
                    </form-input>
                </div>
                <div class="column col-2 col-sm-4 mt-5">
                    <form-input
                        id="bronze"
                        name="bronze"
                        v-model="record.bronze"
                        type="stripe"
                        :required="true"
                        :saving="saving('bronze')"
                        :error="has_error('bronze')"
                        class="award-goal"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Bronze") }}</template>
                    </form-input>
                </div>
            </div>

            <div v-if="!!profile_id && !record.id" class="columns mt-5">
                <div class="column col-6">
                    <default-button
                        :block="true"
                        @click.prevent="$emit('reset')"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                </div>
                <div class="column col-6">
                    <default-button
                        color="primary"
                        :block="true"
                        @click.prevent="create_challenge"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    parseISO,
    format
} from "date-fns"

import { profile_utilities, handles_saving } from "@/nibnut/mixins"

import {
    FormInput,
    FormEditor,
    FormDateInput,
    FormDropdown,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "Challenge",
    mixins: [profile_utilities, handles_saving],
    components: {
        FormInput,
        FormEditor,
        FormDateInput,
        FormDropdown,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.grab_record_id()
    },
    watch: {
        record: "grab_record_id"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.record ? this.record.id : 0
        },
        field_id (name) {
            if(!this.record || !this.record.id) return name
            return `${name}-${this.record.id}`
        },
        toggle () {
            if(!!this.record && !!this.record.id) this.expanded = !this.expanded
        },
        remove () {
            this.$emit("delete", this.record)
        },
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.profile_id && !!value) {
                    if(this.record.id && (this.record.id > 0)) return this.save_field(this.record[field], field)
                    return
                }
                if(this.record.id && (this.record.id > 0)) return this.save_field(this.record[field], field)
            }
            return Promise.resolve()
        },
        create_challenge () {
            const data = { ...this.record, _custom_text: this.customText }
            if(typeof data.starts_on === "string") data.starts_on = parseISO(data.starts_on)
            data.starts_on = format(data.starts_on, "yyyy-MM-dd")
            if(typeof data.ends_on === "string") data.ends_on = parseISO(data.ends_on)
            data.ends_on = format(data.ends_on, "yyyy-MM-dd")
            this.save_data_for_record_id(
                this.entity,
                0,
                data
            )
        },
        saved_data (record, is_new, data) {
            this.$emit("refresh")
        },
        custom_text_save (value, field) {
            if(this.customText) {
                if(this.customText[field] !== value) this.customText[field] = value
                if(this.customText.id) return this.save_data_for_record_id("custom_text", this.customText.id, { [field]: value, relation_ids: ["achievement"] })
            }
            return Promise.resolve()
        }
    },
    computed: {
        active () {
            return this.expanded || this.alwaysExpanded
        },
        units () {
            return Object.values(this.constants("units"))
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        customText: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        alwaysExpanded: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "achievement",
            expanded: false
        }
    }
}
</script>

<style lang="scss">
.award-goal input.form-input {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
</style>
