<template>
    <div class="add-trip-button big-add-btn">
        <base-link
            :href="{ name: 'trip.edit' }"
            class="btn btn-primary btn-add"
        >
            <open-icon glyph="add" library="hop" />
        </base-link>
    </div>
</template>

<script>
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "AddTripButton",
    components: {
        BaseLink,
        OpenIcon
    },
    props: {
        walkerId: {
            type: Number,
            default: 0 // defaults to current walker
        }
    }
}
</script>
