<template>
    <page
        id="walkers-list"
        :title="$root.translate('Walker:::Walkers', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div class="page-outer-container">
            <div class="page-inner-container">
                <h3>
                    {{ $root.translate('Walker:::Walkers', {}, 2) }}
                </h3>
                <data-table
                    id="walkers-list"
                    :columns="visible_columns"
                    :rows="filtered_rows"
                    :search="state.search"
                    :total="state.total"
                    :found="state.found"
                    :current-page="state.page"
                    :total-pages="nb_pages"
                    :can-add="false"
                    @sort="sort_by"
                    @search="search"
                    @page="goto_page"
                    @click="record_edit(entity, $event.uuid)"
                >
                    <template
                        v-slot:buttons
                    >
                        <export-button
                            :links="export_links"
                        />
                    </template>
                    <template
                        v-slot:summary="{ row }"
                    >
                        <h6>{{ row.first_name }} {{ row.last_name }}</h6>
                        <div class="text-center">
                            {{ $root.translate("1 trip walked:::{n} trips walked", { n: row.computed_total_trips || 0 }, row.computed_total_trips || 0) }}
                            &bull;
                            <span v-if="total_distance_for_row(row) < 1000">
                                {{ total_distance_for_row(row) | nibnut.number("0,0") }}<span>m</span>
                            </span>
                            <span v-else>
                                {{ total_distance_for_row(row) / 1000 | nibnut.number("0,0.00") }}<span>km</span>
                            </span>
                        </div>
                        <p class="text-small">
                            <mailto-link
                                :value="row.email"
                            >
                                {{ row.email }}
                            </mailto-link>
                        </p>
                    </template>
                    <template
                        v-slot:tbody="{ row, field }"
                    >
                        <span v-if="field === 'first_name'">
                            {{ row.first_name }} {{ row.last_name }}
                        </span>
                        <mailto-link
                            v-else-if="field === 'email'"
                            :value="row.email"
                        >
                            {{ row.email }}
                        </mailto-link>
                        <span v-else-if="field === 'computed_total_trips'">
                            {{ row.computed_total_trips | nibnut.number("0,0") }}
                        </span>
                        <span v-else-if="field === 'computed_total_distance'">
                            <span v-if="total_distance_for_row(row) < 1000">
                                {{ total_distance_for_row(row) | nibnut.number("0,0") }}<span>m</span>
                            </span>
                            <span v-else>
                                {{ total_distance_for_row(row) / 1000 | nibnut.number("0,0.00") }}<span>km</span>
                            </span>
                        </span>
                        <span v-else>{{ row[field] }}</span>
                    </template>
                </data-table>
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, crud_utilities } from "@/nibnut/mixins"

import {
    MailtoLink
} from "@/nibnut/components"
import {
    ExportButton
} from "@/custom/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities],
    components: {
        MailtoLink,
        ExportButton
    },
    methods: {
        total_distance_for_row (row) {
            return row.computed_total_distance / 100
        }
    },
    computed: {
        fields () {
            return ["fieldset::list"]
        },
        export_links () {
            const links = [
                { title: this.$root.translate("Current Edition Data"), url: this.export_url }
            ]
            if(this.is_at_least_administrator) {
                const all_data_state = {
                    ...this.state,
                    archived: true
                }
                links.push({ title: this.$root.translate("All Data"), url: this.build_export_url(all_data_state) })
            }
            return links
        }
    },
    data () {
        return {
            entity: "walker",
            columns: {
                first_name: { label: "Name", sort: "asc", type: "alpha" },
                email: { label: "Email", sort: false, type: "alpha" },
                computed_total_trips: { label: "Trips", sort: null, type: "numeric" },
                computed_total_distance: { label: "Distance", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "first_name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
