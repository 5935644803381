<template>
    <div>
        <walker
            v-for="(walker, index) in rows"
            :key="walker.id"
            :record="walker"
            :index="index"
            :deletable="(!!edited_walker || (rows.length > 1))"
            :always-expanded="!userId && (index === (rows.length - 1))"
            :team-name="teamName"
            @pick-avatar="pick_avatar"
            @delete="confirm_walker_delete"
        />
        <walker
            v-if="edited_walker"
            :record="edited_walker"
            :index="rows.length"
            :deletable="!!rows.length"
            :always-expanded="true"
            :team-name="teamName"
            :saving-errors="errors"
            @pick-avatar="pick_avatar"
            @reset="edited_walker = null"
            @delete="confirm_walker_delete"
        />
        <h3 v-if="!userId || !edited_walker" class="mt-8">{{ $root.translate("Add another walker!") }}</h3>
        <div v-if="!userId || !edited_walker" class="text-center">
            <default-button
                color="light"
                size="lg"
                :title="$root.translate('Add another walker!')"
                class="btn-add"
                @click.prevent="add_walker"
            >
                <open-icon glyph="add" library="hop" size="2x" />
            </default-button>
        </div>

        <avatar-picker
            :show.sync="picking_avatar"
            @pick="picked_callback"
        />
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

import { confirms, ui_utilities } from "@/nibnut/mixins"
import { is_avatar_picker } from "@/custom/mixins"

import {
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import Walker from "./Walker"

export default {
    name: "Walkers",
    mixins: [confirms, ui_utilities, is_avatar_picker],
    components: {
        DefaultButton,
        OpenIcon,
        Walker
    },
    mounted () {
        this.reset()
    },
    watch: {
        $route: "reset"
    },
    methods: {
        reset () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "walker" }
            ).then(record => {
                this.shell_record = record
                this.shell_record.user_id = this.userId
                this.shell_record.schoolboard_id = record.schoolboard_id || 0
                this.shell_record._team_member = true
                if(!this.shell_record.user_id || (this.profile_id && !this.rows.length)) this.add_walker()
            }).catch(error => {
                this.$error(error.message)
            })
        },
        create_walker () {
            if(this.edited_walker) {
                if(!this.userId) {
                    this.edited_walker.id = (new Date()).getTime() * -1
                    this.held_walkers.push(this.edited_walker)
                } else if(!!this.edited_walker.first_name && !!this.edited_walker.last_name) {
                    this.$store.dispatch("CREATE_RECORD", {
                        entity: "walker",
                        data: this.edited_walker
                    }).then(record => {
                        this.edited_walker = null
                    }).catch(this.receive_error)
                }
            }
        },
        add_walker () {
            this.create_walker()
            this.edited_walker = { ...this.shell_record }
            if(!this.userId) this.$emit("added", this.edited_walker)
            if(this.rows.length) {
                setTimeout(() => {
                    const node = document.getElementById("first_name")
                    if(node) {
                        node.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
                        node.focus()
                    }
                }, 250)
            }
        },
        confirm_walker_delete (walker) {
            if(!walker || !walker.id) this.edited_walker = null
            else if(walker.id < 0) this.walker_delete(walker)
            else {
                this.confirm(
                    {
                        type: "error",
                        title: this.$root.translate("Delete Walker"),
                        message: this.$root.translate("Do you really want to delete \"{name}\"? There is no undo...", { name: walker.first_name }),
                        cancel: this.$root.translate("Keep"),
                        ok: this.$root.translate("Delete"),
                        walker
                    },
                    "delete-record"
                )
            }
        },
        confirmed () {
            if(this.confirming === "delete-record") this.walker_delete(this.confirmation_props.walker)
            else this.done_confirming()
        },
        walker_delete (walker) {
            const index = this.held_walkers.findIndex(held_walker => held_walker.id === walker.id)
            if(index >= 0) {
                this.held_walkers.splice(index, 1)
                this.$emit("deleted", walker)
            } else if(walker.id > 0) {
                const proceed = () => {
                    this.$store.dispatch(
                        "RECORD_DELETE",
                        {
                            entity: "walker",
                            id: walker.id
                        }
                    ).then(record => {
                        this.$emit("deleted", record)
                        this.done_confirming()
                    }).catch(this.receive_error)
                }
                if(this.walker_uuid === walker.uuid) {
                    this.$store.dispatch(
                        "SET_CURRENT_WALKER",
                        { walker_uuid: null, team_id: 0 }
                    ).then(() => {
                        proceed()
                    })
                } else proceed()
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        ...mapState(["walker_uuid"]),
        rows () {
            if(!this.userId) return this.held_walkers
            return this.entity_records("walker").filter(walker => walker.user_id === this.userId)
        }
    },
    props: {
        userId: {
            type: Number,
            required: true
        },
        teamName: {
            type: String,
            default: ""
        },
        errors: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            shell_record: null,
            edited_walker: null,
            held_walkers: []
        }
    }
}
</script>
