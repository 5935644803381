import { mapState } from "vuex"

import handles_errors from "@/nibnut/mixins/HandlesErrors"

import BaseForm from "@/nibnut/components/BaseForm"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import AppLogo from "@/nibnut/components/AppLogo"

const is_panel = {
    mixins: [handles_errors],
    components: {
        BaseForm,
        DefaultButton,
        AppLogo
    },
    mounted () {
        this.update_email(this.value)
        setTimeout(() => {
            const fields = this.$refs.first_field ? this.$refs.first_field.$el.getElementsByTagName("input") : []
            const field = fields.length ? fields[0] : null
            if(field && !field.classList.contains("autofilled")) field.focus() // this causes autofilled values to not be registered
        }, 300)
    },
    watch: {
        value: "update_email"
    },
    methods: {
        update_email (email) {
            this.email = email
            this.$emit("input", this.email)
        },
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        },
        goto (panel_id) {
            if(panel_id === "signup") this.$router.replace({ name: panel_id })
            else this.$emit("goto", panel_id)
        },
        dismiss () {
            if(this.dismissable) this.$emit("dismiss")
        }
    },
    computed: {
        ...mapState(["app_context"]),
        can_signup () {
            return !!this.app_context && !!this.app_context.settings && !!this.app_context.settings.signups
        }
    },
    props: {
        value: { // email
            type: String,
            default: ""
        },
        labelLess: {
            type: Boolean,
            default: false
        },
        dismissable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            loading: false,
            email: "",
            autofill_timeout: 300
        }
    }
}

export default is_panel
