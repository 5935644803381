<template>
    <textarea
        v-bind="$props"
        v-on="$listeners"
        class="form-input"
    />
</template>

<script>
export default {
    name: "BaseTextarea",
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        rows: {
            type: Number,
            default: 3
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
