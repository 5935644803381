/*
<confirmation
    v-if="confirming"
    v-bind="confirmation_props"
    @cancel="done_confirming"
    @confirmed="..."
/>
*/

const confirms = {
    components: {
        Confirmation: () => import("@/nibnut/components/ModalDialog/Confirmation")
    },
    methods: {
        confirm (props, id = true) {
            this.confirmation_props = {
                type: "warning",
                icon: "exclamation-triangle",
                dismissable: false,
                title: "",
                message: "",
                cancel: this.$root.translate("No"),
                ok: this.$root.translate("Yes"),
                ...props
            }
            this.confirming = id
        },
        set_confirm_props (props = {}) {
            Object.keys(props).forEach(prop => {
                this.$set(this.confirmation_props, prop, props[prop])
            })
        },
        done_confirming () {
            this.confirming = false
            this.confirmation_props = {}
        }
    },
    data () {
        return {
            confirming: false, // true or confirmation ID
            confirmation_props: {}
        }
    }
}

export default confirms
