<template>
    <div class="authentication-panel panel-login page-outer-container">
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            :has-required-fields="false"
            class="page-inner-container"
            @submit.prevent.stop="login"
        >
            <div class="panel-header">
                <app-logo />
            </div>
            <div class="panel-body">
                <form-input
                    :id="dismissable ? 'email' : 'first_focusable'"
                    ref="first_field"
                    v-model="email"
                    name="email"
                    type="email"
                    :error="has_error('email')"
                    :required="true"
                    :maybe-autofilled="true"
                    :placeholder="`${$root.translate('Email')}*`"
                    @input="update_email"
                    @blur="update_email"
                />
                <password-input
                    id="password"
                    v-model="password"
                    type="password"
                    name="password"
                    :error="has_error('password')"
                    :required="true"
                    :placeholder="`${$root.translate('Password')}*`"
                    @blur="password=$event"
                />
                <div class="columns text-controls">
                    <div class="column column text-left">
                        <label class="form-label text-bold">{{ $root.translate("*Required fields") }}</label>
                    </div>
                    <div class="column text-right">
                        <default-button
                            flavor="link"
                            size="sm"
                            class="btn-true-link"
                            @click.prevent="goto('password')"
                        >
                            {{ $root.translate("Forgot password?") }}
                        </default-button>
                    </div>
                </div>
            </div>

            <div class="panel-footer">
                <div class="columns">
                    <div class="column text-left">
                        <default-button
                            color="primary"
                            @click.prevent="goto('signup')"
                        >
                            {{ $root.translate("Sign up") }}
                        </default-button>
                    </div>
                    <div class="column text-right">
                        <default-button
                            id="last_focusable"
                            type="submit"
                        >
                            {{ $root.translate("Login") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
import is_panel from "./IsPanel"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import PasswordInput from "@/nibnut/components/Inputs/PasswordInput"

export default {
    mixins: [is_panel, addl_profile_utilities],
    components: {
        FormInput,
        PasswordInput
    },
    methods: {
        login () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                const data = {
                    email: this.email,
                    password: encodeURIComponent(this.password)
                }
                if(this.$route.params && this.$route.params.invitation) data.invitation = this.$route.params.invitation
                this.$store.dispatch("LOGIN", data).then(() => {
                    this.$emit("input", "")
                    this.password = ""
                    // this.$store.dispatch("UNREQUEST_LOGIN")
                    this.maybe_show_login_message()
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        }
    },
    data () {
        return {
            password: ""
        }
    }
}
</script>
