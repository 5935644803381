import { mapState } from "vuex"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

const is_achievement_panel = {
    name: "IsAchievementPanel",
    mixins: [is_remote_data_table_source],
    watch: {
        localization: "reset"
    },
    methods: {
        walker_achievement (achievement) {
            if(!this.walker) return null
            return this.entity_records("walker_achievement").find(walker_achievement => (this.walker.id === walker_achievement.walker_id) && (achievement.id === walker_achievement.achievement_id))
        },
        goal (achievement) {
            const goal = this.nibnut_filter("nibnut.number", [achievement.gold / 100, "0,0"])
            if(achievement.units === this.constants("units", "UNIT_STEPS").id) return this.$root.translate("{n} steps", { n: goal })
            if(achievement.units === this.constants("units", "UNIT_TRIPS").id) return this.$root.translate("{n} trips", { n: goal })
            if(achievement.units === this.constants("units", "UNIT_KM").id) return this.$root.translate("{n} km", { n: this.nibnut_filter("nibnut.number", [achievement.gold / 100, "0,0"]) })
            if(achievement.units === this.constants("units", "UNIT_DAYS").id) return this.$root.translate("{n} days", { n: goal })
            if(achievement.units === this.constants("units", "UNIT_GHG").id) return this.$root.translate("{n} kg of GHG averted", { n: goal })
        },
        achieved (achievement, milestone = "gold") {
            const award = this.walker_achievement(achievement)
            return award.progression >= achievement[milestone]
        }
    },
    computed: {
        ...mapState(["localization"]),
        fields () {
            return ["fieldset::dashboard", "ns::walker_achievement;fieldset::dashboard"]
        }
    },
    props: {
        walker: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "achievement",
            relation_ids: ["walker_achievement"],
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "starts_on",
                sort_dir: "desc",
                filter_on: "type",
                filter: "challenge",
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}

export default is_achievement_panel
