<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    class="form-label disabled-field"
                >
                    {{ display_value }}
                </label>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <base-token-input
            ref="field"
            v-bind="input_props"
            :disabled="!editable"
            v-on="$listeners"
        >
            <template v-slot:selection><slot name="selection"></slot></template>
        </base-token-input>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import BaseTokenInput from "./BaseTokenInput"

export default {
    name: "FormTokenInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        BaseTokenInput
    },
    methods: {
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        input_props () {
            const { editable, saving, error, ...input_props } = this.$props
            return input_props
        },
        display_value () {
            return this.value.join(", ")
        }
    },
    props: {
        ...BaseTokenInput.props,
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
