import {
    parseISO
} from "date-fns"

const handles_dates = {
    methods: {
        standardized_range_object (date_data) {
            if((typeof date_data === "object") && !(date_data instanceof Date) && (typeof date_data.from !== "undefined")) {
                if(!(date_data.from instanceof Date)) date_data.from = parseISO(date_data.from)
                if(!(date_data.to instanceof Date)) date_data.to = parseISO(date_data.to)
                return date_data
            }
            const date = (date_data instanceof Date) ? date_data : parseISO(date_data)
            return { from: date, to: date }
        },
        standardized_date (date_data) {
            if(!date_data) return null
            if(date_data && (date_data instanceof Date)) return date_data
            return parseISO(date_data)
        }
    }
}

export default handles_dates
