import AvatarPicker from "@/custom/dialogs/AvatarPicker"

/*
    <avatar-picker
        :show.sync="picking_avatar"
        @pick="picked_callback"
    />
*/

const is_avatar_picker = {
    name: "IsAvatarPicker",
    components: {
        AvatarPicker
    },
    methods: {
        pick_avatar (callback) {
            this.picked_callback = callback
            this.picking_avatar = true
        }
    },
    data () {
        return {
            picking_avatar: false,
            picked_callback: () => {}
        }
    }
}

export default is_avatar_picker
