<template>
    <div>
        <member
            v-for="walker in rows"
            :key="walker.id"
            :record="walker"
            :is-captain="walker.id === captainId"
            @delete="confirm_walker_delete"
        />

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { is_data_table_source, string_utilities, confirms } from "@/nibnut/mixins"

import Member from "./Member"

export default {
    name: "Members",
    mixins: [is_data_table_source, string_utilities, confirms],
    components: {
        Member
    },
    methods: {
        confirm_walker_delete (walker) {
            if(!walker || !walker.id) this.edited_walker = null
            else {
                this.edited_walker = walker
                this.confirm(
                    {
                        type: "error",
                        title: this.$root.translate("Remove a team member"),
                        message: this.$root.translate("Are you sure you want to remove {name}? Their personal stats will not be deleted, but their rides will no longer count towards your team's total. The team member will be notified by email.", { name: this.full_name(walker) }),
                        cancel: this.$root.translate("Keep"),
                        ok: this.$root.translate("Delete"),
                        walker
                    },
                    "delete-record"
                )
            }
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch("RECORD_ACTION", {
                    entity: "team",
                    id: this.teamId,
                    action: "leave",
                    data: { walker_id: this.edited_walker.id },
                    method: "put"
                }).then(() => {
                    this.done_confirming()
                    this.edited_walker = null
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        rows () {
            if(!this.teamId) return []
            return this.entity_records("walker").filter(walker => walker.team_id === this.teamId)
        }
    },
    props: {
        teamId: {
            type: Number,
            required: true
        },
        captainId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            edited_walker: null
        }
    }
}
</script>
