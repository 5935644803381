<template>
    <log-first-trip
        v-if="!rows.length"
        :walker-id="walkerId"
    />
    <div v-else>
        <calendar
            :selection="calendar_selection"
            :highlights="rows.map(row => row.executed_on)"
            :max="today"
            @navigated="calendar_navigated"
            @click="calendar_select"
            class="mb-10"
        />
        <div
            v-for="trip in filtered_rows"
            :key="trip.id"
            class="tile tile-centered list-tile trip-tile"
        >
            <div class="tile-date">
                <span class="text-uppercase">{{ trip.executed_on | nibnut.date("MMM") }}</span>
                <h5>{{ trip.executed_on | nibnut.date("dd") }}</h5>
            </div>
            <div class="tile-content">
                <div class="tile-title">
                    <span v-if="trip_distance(trip) < 1000">{{ $root.translate("{number}m", { number: nibnut_filter("nibnut.number", [trip_distance(trip), "0,0"]) }) }}</span>
                    <span v-else>{{ $root.translate("{number}km", { number: nibnut_filter("nibnut.number", [trip_distance(trip) / 1000, "0,0.0"]) }) }}</span>
                </div>
                <div class="tile-title">{{ trip_category(trip) }}</div>
            </div>
            <div class="tile-action">
                <base-link
                    :href="{ name: 'trip.edit', params: { id: trip.uuid } }"
                    class="btn btn-link"
                    @click.prevent="toggle"
                >
                    {{ $root.translate("Edit") }}
                </base-link>
                <default-button
                    @click.prevent="confirm_trip_delete(trip)"
                >
                    <open-icon glyph="close" size="lg" library="hop" />
                </default-button>
            </div>
        </div>
        <div class="text-center mt-5">
            <add-trip-button
                :walker-id="walkerId"
            />
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { format, startOfMonth, endOfMonth, startOfDay, endOfDay, parseISO, isWithinInterval } from "date-fns"

import { is_remote_data_table_source, confirms, misc_utilities } from "@/nibnut/mixins"

import {
    DefaultButton,
    BaseLink,
    OpenIcon
} from "@/nibnut/components"
import {
    LogFirstTrip,
    Calendar,
    AddTripButton
} from "@/custom/components"

const default_from_date = format(startOfMonth(new Date()), "yyyy-MM-dd")
const default_to_date = format(endOfMonth(new Date()), "yyyy-MM-dd")

export default {
    name: "Trips",
    mixins: [is_remote_data_table_source, confirms, misc_utilities],
    components: {
        DefaultButton,
        BaseLink,
        OpenIcon,
        LogFirstTrip,
        Calendar,
        AddTripButton
    },
    watch: {
        walkerId: "reset"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                walker_id: this.walkerId
            })
        },
        trip_distance (trip) {
            return trip.distance / 100
        },
        trip_category (trip) {
            const category = Object.values(this.constants("categories")).find(category => category.id === trip.category) || {}
            return category.name || trip.category
        },
        calendar_navigated (date) {
            this.set_state_values({
                from_date: format(startOfMonth(date), "yyyy-MM-dd"),
                to_date: format(endOfMonth(date), "yyyy-MM-dd"),
                filter_on: "month"
            })
            this.reset()
        },
        calendar_select (date) {
            let from_date = format(startOfDay(date), "yyyy-MM-dd")
            let to_date = format(endOfDay(date), "yyyy-MM-dd")
            if((this.state.from_date === from_date) && (this.state.to_date === to_date) && (this.state.filter_on === "date")) {
                from_date = format(startOfMonth(date), "yyyy-MM-dd")
                to_date = format(endOfMonth(date), "yyyy-MM-dd")
                date = null
            }
            this.set_state_values({
                from_date,
                to_date,
                filter_on: date ? "date" : "month"
            })
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }

            if(this.state.filter_on) {
                const range = { start: parseISO(this.state.from_date), end: parseISO(this.state.to_date) }
                return rows.filter(row => {
                    const trip_date = parseISO(row.executed_on)
                    return isWithinInterval(trip_date, range) && (row.walker_id === this.walkerId)
                })
            }
            return rows
        },
        confirm_trip_delete (trip) {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete Trip"),
                    message: this.$root.translate("Do you really want to delete the trip from {date}? There is no undo...", { date: this.nibnut_filter("nibnut.date", [trip.executed_on]) }),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete"),
                    trip
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.trip_delete(this.confirmation_props.trip)
            else this.done_confirming()
        },
        trip_delete (trip) {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: "trip",
                    id: trip.id
                }
            ).then(record => {
                this.done_confirming()
            }).catch(this.receive_error)
        }
    },
    computed: {
        can_refresh () {
            return this.walkerId
        },
        calendar_selection () {
            if(this.state.filter_on === "date") return this.state.from_date
            return null
        },
        today () {
            return new Date()
        }
    },
    props: {
        walkerId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "trip",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "executed_on",
                sort_dir: "desc",
                filter_on: "month", // month or date
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                walker_id: 0,
                from_date: default_from_date,
                to_date: default_to_date
            },

            selected_trip: null
        }
    }
}
</script>

<style lang="scss">
@use "sass:math";
@import "@/assets/sass/variables";

.tile.tile-centered.list-tile.trip-tile {
    .tile-date {
        text-align: center;
        padding: math.div($control-padding-y, 2) $control-padding-x-sm;

        .text-uppercase {
            font-size: 0.7em;
        }
        h5 {
            position: relative;
            top: -0.2em;
            font-size: 1.5em;
            margin: 0;
        }
    }
    .tile-title {
        line-height: 1;
        font-size: 0.6rem;

        & + .tile-title {
            margin-top: $unit-1;
        }
    }
    .tile-action {
        display: flex;
        align-items: center;

        button.btn {
            height: 2.682rem;
        }
    }
}
</style>
