<template>
    <div
        v-if="!!record"
        :class="{ active }"
        class="faq-item expandable-list"
    >
        <div
            class="tile tile-centered list-tile"
            @click="toggle"
        >
            <div class="tile-content">
                <div class="tile-title">
                    <form-input
                        v-if="editing"
                        :id="record.id ? `${field_name('title')}-${record.id}` : field_name('title')"
                        v-model="record[field_name('title')]"
                        :name="field_name('title')"
                        :required="true"
                        :placeholder="`${$root.translate('Title')}*`"
                        :saving="saving(field_name('title'))"
                        :error="has_error(field_name('title'))"
                        @input="save"
                    />
                    <span v-else>{{ record[field_name('title')] }}</span>
                </div>
            </div>
            <div class="tile-action">
                <default-button
                    v-if="editing"
                    flavor="link"
                    @click.prevent.stop="confirm_record_delete"
                >
                    <open-icon glyph="close" library="hop" />
                </default-button>
                <default-button
                    v-else
                    flavor="link"
                >
                    <open-icon glyph="littlearrow" library="hop" />
                </default-button>
            </div>
        </div>
        <form-editor
            v-if="editing"
            :id="record.id ? `${field_name('content')}-${record.id}` : field_name('content')"
            :data-version="record.id"
            :name="field_name('content')"
            v-model="record[field_name('content')]"
            size="lg"
            :required="true"
            :placeholder="`${$root.translate('Content')}*`"
            :saving="saving(field_name('content'))"
            :error="has_error(field_name('content'))"
            class="content mt-3"
            @input="save"
        />
        <div v-else class="content" v-html="record.content"></div>
    </div>
</template>

<script>
import { handles_saving, confirms } from "@/nibnut/mixins"

import {
    FormInput,
    FormEditor,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "Faq",
    mixins: [handles_saving, confirms],
    components: {
        FormInput,
        FormEditor,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.reset()
    },
    watch: {
        "record.id": "reset"
    },
    methods: {
        reset () {
            this.record_id = this.record ? this.record.id : 0
        },
        toggle () {
            if(!!this.record && !!this.record.id && !this.editing) this.expanded = !this.expanded
        },
        field_name (field) {
            if(this.editing) return `${field}_${this.editing}`
            return field
        },
        save_field_for_record_id (entity, record_id, value, field) {
            return this.save_data_for_record_id(entity, record_id, { [field]: this.standardize_field_save_value(value, field), fields: ["fieldset::editing"] })
        },
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) return this.save_field(this.record[field], field)
            }
            return Promise.resolve()
        },
        confirm_record_delete () {
            this.$emit("remove", this.record)
        }
    },
    computed: {
        active () {
            return this.editing || this.expanded
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        editing: {
            type: String, // '', 'EN', 'FR'
            default: ""
        }
    },
    data () {
        return {
            record_id: 0,
            entity: "custom_text",
            expanded: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.faq-item.expandable-list {
    .tile.tile-centered.list-tile {
        margin-bottom: 0;

        .tile-title {
            overflow: visible;
            text-overflow: unset;
            white-space: normal;
            line-height: 1.2em;
        }
        .tile-content {
            padding-top: $unit-3;
            padding-bottom: $unit-3;
            padding-left: $unit-5;
        }
        .tile-action {
            padding: $unit-3 $unit-3 $unit-3 0.3rem;
            line-height: 1.2em;
            // background-color: $gray-color;
            border-top-right-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
        }
        .btn {
            font-size: 0.7rem;
            font-weight: bold;
            padding: 0;
            background: none;

            i.hop {
                display: inline-block;
                font-size: 1.2rem;
                transform: rotate(-90deg);
                transition: transform 0.3s ease-in-out;
            }
        }
    }
    .content {
        font-family: $body-font-family;
        font-weight: normal;
        padding: 0 $unit-5;
        margin-bottom: 0;
        transition: margin-bottom 0.3s ease-in-out, max-height 0.3s ease-in-out;

        img {
            max-height: 1em;
        }

        &.form-group {
            padding: 0;
        }
    }
    &.active {
        .content {
            margin-top: $unit-2;
            margin-bottom: $unit-5;
        }
        .tile.tile-centered.list-tile {
            .btn i.hop {
                transform: rotate(0deg) translateX(4px) translateY(-4px);
            }
        }
    }
}
</style>
