<template>
    <div>
        <data-table
            id="slides-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            @search="search"
            @add="slide_add"
            @click="slide_edit"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.title_EN }}</h6>
                <h6>{{ row.title_FR }}</h6>
                <default-button
                    color="error"
                    :block="true"
                    class="mt-3"
                    @click.prevent.stop="confirm_slide_delete(row)"
                >
                    <open-icon glyph="close" library="hop" /> {{ $root.translate("Delete") }}
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === '_buttons'" class="text-right">
                    <default-button
                        flavor="link"
                        color="error"
                        @click.prevent.stop="confirm_slide_delete(row)"
                    >
                        <open-icon glyph="close" library="hop" />
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <modal-dialog
            id="slide-editor-dialog"
            :show.sync="editing"
            class="modal-lg"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("Welcome Slide Editor") }}</span>
            </template>
            <base-form
                v-if="!!edited_slide"
                :has-required-fields="true"
                @submit.prevent="slide_create"
            >
                <form-input
                    id="title_EN"
                    v-model="edited_slide.title_EN"
                    name="title_EN"
                    :required="true"
                    :saving="saving('title_EN')"
                    :error="has_error('title_EN')"
                    @input="slide_save"
                >
                    <template v-slot:label>{{ $root.translate("English Title") }}...</template>
                </form-input>
                <form-editor
                    id="content_EN"
                    :data-version="edited_slide.id"
                    name="content_EN"
                    v-model="edited_slide.content_EN"
                    size="lg"
                    :required="false"
                    :saving="saving('content_EN')"
                    :error="has_error('content_EN')"
                    @input="slide_save"
                >
                    <template v-slot:label>...{{ $root.translate("Content") }}</template>
                </form-editor>

                <form-input
                    id="title_FR"
                    v-model="edited_slide.title_FR"
                    name="title_FR"
                    :required="true"
                    :saving="saving('title_FR')"
                    :error="has_error('title_FR')"
                    class="mt-5"
                    @input="slide_save"
                >
                    <template v-slot:label>{{ $root.translate("French Title") }}...</template>
                </form-input>
                <form-editor
                    id="content_FR"
                    :data-version="edited_slide.id"
                    name="content_FR"
                    v-model="edited_slide.content_FR"
                    size="lg"
                    :required="false"
                    :saving="saving('content_FR')"
                    :error="has_error('content_FR')"
                    @input="slide_save"
                >
                    <template v-slot:label>...{{ $root.translate("Content") }}</template>
                </form-editor>
            </base-form>

            <template v-slot:footer>
                <div v-if="!!edited_slide && !edited_slide.id" class="text-center">
                    <default-button
                        :disabled="creating"
                        class="mr-2"
                        @click.prevent="slide_edit_stop"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :waiting="creating"
                        :disabled="creating"
                        color="primary"
                        class="ml-2"
                        @click.prevent="slide_create"
                    >
                        {{ $root.translate("Create") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { is_data_table_source, handles_saving, misc_utilities, confirms } from "@/nibnut/mixins"

import { ModalDialog, BaseForm, FormInput, FormEditor, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "Slides",
    mixins: [is_data_table_source, handles_saving, misc_utilities, confirms],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        FormEditor,
        DefaultButton,
        OpenIcon
    },
    methods: {
        slide_edit (slide) {
            this.edited_slide = slide
            this.record_id = this.edited_slide.id
            this.editing = true
        },
        slide_add () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "custom_text" }
            ).then(record => {
                record.type = this.constants("custom_text_types", "TYPE_WELCOME_SLIDE").id
                this.slide_edit(record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        slide_edit_stop () {
            this.editing = false
            this.record_id = 0
            this.edited_slide = null
        },
        slide_save (value, field) {
            if(this.edited_slide) {
                if(this.edited_slide[field] !== value) this.edited_slide[field] = value
                if(this.edited_slide.id) return this.save_field(this.edited_slide[field], field)
            }
            return Promise.resolve()
        },
        slide_create () {
            if(this.edited_slide && !this.edited_slide.id) {
                this.creating = true
                this.save_data_for_record_id(this.entity, 0, this.edited_slide).then(() => {
                    this.refresh()
                    this.slide_edit_stop()
                }).catch(this.receive_error).then(() => {
                    this.creating = false
                })
            } else this.slide_edit_stop()
        },
        confirm_slide_delete (slide) {
            if(!slide || !slide.id) this.edited_slide = null
            else {
                this.edited_slide = slide
                this.confirm(
                    {
                        type: "error",
                        title: this.$root.translate("Remove a welcome slide"),
                        message: this.$root.translate("Are you sure you want to remove {title}?", { title: slide.title_EN }),
                        cancel: this.$root.translate("Keep"),
                        ok: this.$root.translate("Delete")
                    },
                    "delete-record"
                )
            }
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "custom_text",
                        id: this.edited_slide.id
                    }
                ).then(() => {
                    this.done_confirming()
                    this.edited_slide = null
                    this.record_id = 0
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        state_identifier () {
            return "slides"
        },
        rows () {
            const type = this.constants("custom_text_types", "TYPE_WELCOME_SLIDE").id
            return this.entity_records("custom_text").filter(custom_text => custom_text.type === type)
        }
    },
    data () {
        return {
            entity: "custom_text",
            columns: {
                title_EN: { label: "English", sort: false },
                title_FR: { label: "French", sort: false },
                _buttons: { label: " ", sort: false }
            },

            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "sort_order",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            edited_slide: null,
            editing: false,
            creating: false
        }
    }
}
</script>
