<template>
    <form-group
        v-bind="form_group_props"
        class="trip-category-input"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <span class="text-uppercase">{{ value }}</span>
            </slot>
        </template>
        <template v-slot:label><slot name="label"><div v-if="horizontal" class="d-invisible">{{ name }}</div></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <default-toggle-input
            v-for="category in categories"
            :key="category.id"
            id="category"
            type="radio"
            name="category"
            :value="value"
            :true-value="category.id"
            :false-value="null"
            :aria-describedby="!!has_slot('hint') ? `${category.name}-description` : false"
            :disabled="!editable"
            v-on="$listeners"
        >
            {{ category.name }}
        </default-toggle-input>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import DefaultToggleInput from "@/nibnut/components/Inputs/DefaultToggleInput"

export default {
    name: "TripCategoryInput",
    mixins: [is_nibnut_component, misc_utilities],
    components: {
        FormGroup,
        DefaultToggleInput
    },
    methods: {
    },
    computed: {
        categories () {
            return Object.values(this.constants("categories"))
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: false,
                editable: this.editbale,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        ...DefaultToggleInput.props,
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
