<template>
    <div class="authentication-panel panel-slides page-outer-container">
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            :has-required-fields="false"
            class="page-inner-container"
            @submit.prevent.stop="login"
        >
            <div class="panel-body">
                <div
                    class="carousel"
                    v-touch:swipe="swiped"
                >
                    <div
                        :style="{ left: `${slide_index * slide_width * -1}px` }"
                        class="carousel-container"
                    >
                        <figure
                            v-for="slide in slides"
                            :key="slide.id"
                            class="carousel-item"
                        >
                            <h1>{{ slide.title }}</h1>
                            <div v-html="slide.content"></div>
                        </figure>
                    </div>
                    <ul v-if="slides.length > 1" class="carousel-nav">
                        <li
                            v-for="(slide, index) in slides"
                            :key="slide.id"
                        >
                            <default-button
                                :class="{ active: (index === slide_index) }"
                                @click.prevent="slide_to(index)"
                            />
                        </li>
                    </ul>
                </div>
            </div>

            <div class="panel-footer">
                <div class="columns">
                    <div class="column col-6 col-sm-3 text-left">
                        <default-button
                            v-if="slides.length > 1"
                            color="primary"
                            @click.prevent="goto('login')"
                        >
                            {{ $root.translate("Skip") }}
                        </default-button>
                    </div>
                    <div class="column col-6 col-sm-9 text-right">
                        <default-button
                            @click.prevent="slide_to(slide_index + 1)"
                        >
                            {{ $root.translate("Next") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import is_panel from "./IsPanel"

export default {
    mixins: [is_panel, misc_utilities],
    methods: {
        swiped (direction) {
            if(direction === "left") this.slide_to(this.slide_index + 1)
        },
        slide_to (index) {
            const node = document.querySelector("figure")
            if(node) {
                const style = getComputedStyle(node)
                this.slide_width = node.offsetWidth + parseInt(style.marginLeft) + parseInt(style.marginRight)
            }
            if(index >= this.slides.length) this.goto("login")
            else {
                if(index < 0) index = (this.slides.length - 1)
                this.slide_index = index
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        slides () {
            const type = this.constants("custom_text_types", "TYPE_WELCOME_SLIDE").id
            return orderBy(this.entity_records("custom_text").filter(text => text.type === type), "sort_order", "asc")
        }
    },
    data () {
        return {
            slide_index: 0,
            slide_width: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.carousel {
    overflow: hidden;
    max-width: 480px;
    margin: $unit-8 auto $unit-6 auto;

    .carousel-container {
        position: relative;
        left: 0;
        white-space: nowrap;
        transition: left 0.3s ease-in-out;

        figure {
            display: inline-block;
            width: 100%;

            h1 {
                margin-bottom: 1.2rem;
            }
            p {
                font-weight: bold;
            }
        }
    }
    .carousel-nav {
        display: flex;
        justify-content: center;

        & > li {
            margin: $layout-spacing-sm;
            .btn {
                width: 0.8rem;
                height: 0.8rem;
                background-color: transparent;
                border: 3px solid $body-font-color;
                border-radius: 50%;
                opacity: 0.5;
                padding: 0;
                transition: opacity 0.3s ease-in-out;

                &.active {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
