<template>
    <div class="authentication-panel panel-reset page-outer-container">
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            :has-required-fields="true"
            class="page-inner-container"
            @submit.prevent.stop="reset"
        >
            <div class="panel-header">
                <app-logo />
            </div>
            <div class="panel-body">
                <form-input
                    :id="dismissable ? 'email' : 'first_focusable'"
                    ref="first_field"
                    v-model="email"
                    name="email"
                    type="email"
                    :error="has_error('email')"
                    :required="true"
                    :placeholder="$root.translate('Email')"
                    @input="update_email"
                />
                <password-editor
                    id="password"
                    name="password"
                    v-model="password"
                    :error="has_error('password')"
                    :required="true"
                    :placeholder="$root.translate('New Password')"
                />
            </div>
            <div class="modal-footer">
                <div class="columns text-left">
                    <div class="column">
                        <default-button
                            @click.prevent="goto('login')"
                        >
                            {{ $root.translate("Login") }}
                        </default-button>
                    </div>
                    <div class="column text-right">
                        <default-button
                            type="submit"
                            color="primary"
                        >
                            {{ $root.translate("Reset") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "./IsPanel"

import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import PasswordEditor from "@/nibnut/components/Inputs/PasswordEditor"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    mixins: [is_panel],
    components: {
        BaseForm,
        FormInput,
        PasswordEditor,
        DefaultButton
    },
    methods: {
        reset () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                this.$store.dispatch("RESET_PASSWORD", {
                    token: this.$route.query["password-reset"],
                    email: this.email,
                    password: encodeURIComponent(this.password)
                }).then(response => {
                    this.$emit("input", "")
                    this.password = ""
                    this.cancel()
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        },
        cancel () {
            window.location.search = ""
        }
    },
    data () {
        return {
            password: ""
        }
    }
}
</script>
