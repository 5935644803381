<template>
    <div v-if="!!walker && (!!current_challenge || !!medals.length)" class="challenges">
        <div
            v-if="!!current_challenge"
            class="challenge-join-container"
        >
            <div class="challenge-info">
                <div class="challenge-title">
                    <strong>{{ current_challenge.title }}</strong>
                    <div v-html="current_challenge.content"></div>
                    <!--
                    <div class="popover">
                        <default-button color="light" size="sm">?</default-button>
                        <div class="popover-container">
                            <div class="card">
                                <div class="card-header">
                                    {{ current_challenge.title }}
                                </div>
                                <div class="card-body">
                                    {{ current_challenge.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                    //-->
                </div>
                <!--
                <div class="challenge-specs">
                    {{ $root.translate("1 day:::{n} days", { n: total_days }, total_days) }}&nbsp;&bull;&nbsp;{{ goal(current_challenge) }}
                </div>
                //-->
            </div>
            <div
                v-if="!!current_challenge && !current_walker_achievement && current_challenge"
                class="challenge-join"
            >
                <default-button
                    :waiting="joining"
                    @click.prevent="join"
                >
                    {{ $root.translate("Join!") }}
                </default-button>
            </div>
        </div>
        <div
            v-if="!!current_challenge && current_walker_achievement && !current_walker_achievement.award"
            class="challenge-progress-container"
        >
            <h5>{{ $root.translate("1 day left:::{n} days left", { n: days_left }, days_left) }}</h5>
            <h6>{{ $root.translate("to complete the {name} challenge", { name: current_challenge.title }) }}</h6>
            <div class="progress-container">
                <div class="progress-flags">
                    <open-icon
                        v-for="milestone in milestones"
                        :key="milestone"
                        glyph="flag"
                        library="hop"
                        :class="{ active: milestone_reached(milestone) }"
                        :style="{ left: `${milestone_position(milestone)}%` }"
                    />
                </div>
                <progress class="progress" :value="achievement_progression" :max="current_challenge.gold"></progress>
                <div class="progress-medals">
                    <span
                        v-for="milestone in milestones"
                        :key="milestone"
                        :class="{ active: milestone_reached(milestone) }"
                        :style="{ left: `${milestone_position(milestone)}%` }"
                    >
                        {{ milestone_title(milestone) }}
                    </span>
                </div>
            </div>
        </div>
        <div
            v-if="medals.length"
            class="challenge-medals"
        >
            <div
                v-for="medal in medals"
                :key="medal.id"
                class="medal"
            >
                <img :src="medal_image(medal)" />
                <div class="text-small">
                    {{ medal_challenge(medal).title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import {
    parseISO,
    differenceInDays
} from "date-fns"

import is_achievement_panel from "@/custom/mixins/IsAchievementPanel"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

import bronze from "@/assets/img/Bronze_medal.svg"
import silver from "@/assets/img/Silver_medal.svg"
import gold from "@/assets/img/Gold_medal.svg"
const medals = {
    bronze,
    silver,
    gold
}

export default {
    name: "Challenges",
    mixins: [is_achievement_panel],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        milestone_position (milestone) {
            const challenge = this.current_challenge
            const milestone_value = challenge[milestone]
            const challenge_total = challenge.gold
            return challenge_total ? ((milestone_value / challenge_total) * 100) : 0
        },
        milestone_reached (milestone) {
            const challenge = this.current_challenge
            return this.achieved(challenge, milestone)
        },
        milestone_title (milestone) {
            if(milestone === "gold") return this.$root.translate("Gold")
            if(milestone === "silver") return this.$root.translate("Silver")
            if(milestone === "bronze") return this.$root.translate("Bronze")
            return ""
        },
        join () {
            this.joining = true
            this.$store.dispatch("CREATE_RECORD", {
                entity: "walker_achievement",
                data: { achievement_id: this.current_challenge.id, walker_id: this.walker.id }
            }).catch(this.receive_error).then(() => {
                this.joining = false
            })
        },
        medal_image (medal) {
            return medals[medal.award] || ""
        },
        medal_challenge (medal) {
            return this.entity_records("achievement").find(achievement => achievement.id === medal.achievement_id) || {}
        }
    },
    computed: {
        state_identifier () {
            return "challenges"
        },
        current_challenge () {
            return this.rows.find(achievement => (achievement.type === "challenge") && achievement.is_active)
        },
        current_walker_achievement () {
            const challenge = this.current_challenge
            if(challenge) return this.walker_achievement(challenge)
            return null
        },
        achievement_progression () {
            const current_walker_achievement = this.current_walker_achievement
            if(!current_walker_achievement) return 0
            return Math.min(current_walker_achievement.progression, 10000)
        },
        medals () {
            const achievement_ids = this.rows.filter(achievement => achievement.type === "challenge").map(achievement => achievement.id)
            return this.entity_records("walker_achievement").filter(walker_achievement => (achievement_ids.indexOf(walker_achievement.achievement_id) >= 0) && !!walker_achievement.award && (this.walker.id === walker_achievement.walker_id))
        },
        total_days () {
            const challenge = this.current_challenge
            if(challenge) return differenceInDays(parseISO(challenge.ends_on), parseISO(challenge.starts_on)) + 1
            return 0
        },
        days_left () {
            const challenge = this.current_challenge
            if(!challenge) return 0
            return differenceInDays(parseISO(challenge.ends_on), new Date()) + 1
        }
    },
    data () {
        return {
            milestones: ["bronze", "silver", "gold"],
            joining: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.challenges {
    .challenge-join-container {
        display: flex;
        background-color: $brand-gray-light;
        border-radius: $unit-3;

        & > div {
            flex: 1 1 auto;

            p {
                font-size: 15px;
                font-weight: normal;
                line-height: 1.2em;
            }
            &.challenge-info {
                font-size: 0.7rem;
                line-height: 1rem;
                text-align: left;
                white-space: normal;
                padding: $control-padding-y-lg $control-padding-x ($control-padding-y-lg * 1.5) $control-padding-x;
            }
            &.challenge-join {
                flex: 0 0 auto;
                & > button {
                    height: 100%;
                    border-radius: $unit-3;
                    &, &:active, &:hover, &:focus {
                        background-color: $brand-gray;
                    }
                }
            }
        }
        /*
        .popover {
            margin-left: 0.2rem;
            top: -0.1rem;
            .btn.btn-light {
                font-size: 0.6rem;
                border-radius: 50%;
                height: 0.65rem;
                padding: 0.1rem 0.2rem;
                line-height: 0.5rem;
                background-color: $brand-gray;
                border-color: $brand-gray;
                &, &:focus, &:visited {
                    color: $brand-gray-dark;
                }
                &:active, &.active, &:hover {
                    color: $brand-gray;
                    background-color: $brand-gray-dark;
                    border-color: $brand-gray-dark;
                }
            }
            .popover-container {
                font-size: 0.6rem;
                white-space: normal;
                .card {
                    .card-header {
                        padding-bottom: 0;
                        font-weight: bold;
                    }
                    .card-body {
                        padding-top: 0;
                    }
                }
            }
        }
        */
    }
    .challenge-progress-container {
        margin-top: $unit-6;
        h5 {
            font-size: 1rem;
            margin: 0;
        }
        h6 {
            font-size: 0.7rem;
            margin-bottom: 1.5rem;
        }
        .progress-flags, .progress-medals {
            position: relative;
            margin-left: -0.5rem;
            margin-right: 0.5rem;
            & > i, & > span {
                position: absolute;
                bottom: 0;
                color: $brand-gray;

                &.active {
                    color: $primary-color;
                }
            }
        }
        .progress {
            display: block;
            height: $unit-4;
            margin: 1rem 0 0.7rem 0;
        }
        .progress-medals {
            font-size: 0.5rem;
        }
    }
    .challenge-medals {
        display: flex;
        justify-content: center;
        margin-top: $unit-9;
        @media (max-width: $size-sm) {
            flex-wrap: wrap;
        }

        & > div.medal {
            flex: 0 0 auto;
            padding: $control-padding-y-sm $control-padding-x-sm;
            .text-small {
                font-size: 0.5rem;
            }
        }
    }
}
</style>
