<template>
    <div class="authentication-panel panel-verification page-outer-container">
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <base-form
            :has-required-fields="false"
            class="page-inner-container"
            @submit.prevent.stop="verified"
        >
            <div class="panel-header">
                <app-logo />
            </div>
            <div class="panel-body">
                <h1>{{ $root.translate("It's a fresh new year!") }}</h1>
                <h6>{{ $root.translate("Please verify your walkers' information") }}:</h6>

                <walker-verification
                    v-for="walker in walkers"
                    :key="walker.id"
                    :record="walker"
                    class="mt-8"
                />
            </div>

            <default-button
                type="submit"
                color="primary"
                :disabled="saving()"
                :waiting="saving()"
                class="mt-8"
            >
                {{ $root.translate("Done") }}
            </default-button>
        </base-form>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import is_panel from "./IsPanel"
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import WalkerVerification from "./WalkerVerification"

export default {
    mixins: [is_panel, handles_saving, profile_utilities],
    components: {
        WalkerVerification,
        DefaultButton
    },
    mounted () {
        this.reload()
    },
    watch: {
        $route: "reload",
        profile_id: "reload",
        walker_uuid: "reload",
        "walkers.length": "maybe_move_on"
    },
    methods: {
        reload () {
            if(!!this.profile_id && !this.walker_uuid) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "walker",
                        query: { user_id: this.profile_id }
                    }
                )
            }
        },
        verified () {
            this.profile.verify_info_on_next_login = false
            return this.save_field_for_record_id(this.entity, this.profile.id, this.profile.verify_info_on_next_login, "verify_info_on_next_login").then(() => {
                if(!this.profile.verify_info_on_next_login) this.goto("walkers")
            })
        }
    },
    computed: {
        walkers () {
            return orderBy(this.entity_records("walker").filter(walker => walker.user_id === this.profile.id), ["first_name", "last_name"], ["asc", "asc"])
        }
    },
    data () {
        return {
            entity: "user"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.panel-verification {
    .card {
        background: transparent;
        border-color: $primary-color;
    }
}
</style>
