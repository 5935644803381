<template>
    <div
        :class="{[`calendar-${size}`]: true}"
        class="calendar"
    >
        <div
            :class="{standalone: !showNavigation}"
            class="calendar-nav navbar"
        >
            <default-button
                v-if="showNavigation"
                :label="$root.translate('Previous month')"
                flavor="link"
                size="lg"
                class="btn-action"
                :tabindex="tabindex"
                @click.prevent="previous_month"
            >
                <img
                    src="@/assets/img/calendar_arrows.svg"
                    :alt="$root.translate('Previous month')"
                />
            </default-button>
            <div v-if="current_page_timestamp" class="nav_title">{{ page_title }}</div>
            <default-button
                v-if="showNavigation"
                :label="$root.translate('Next month')"
                flavor="link"
                size="lg"
                class="btn-action"
                :tabindex="tabindex"
                @click.prevent="next_month"
            >
                <img
                    src="@/assets/img/calendar_arrows.svg"
                    :alt="$root.translate('Next month')"
                />
            </default-button>
        </div>

        <div class="calendar-container">
            <div class="calendar-header">
                <div v-for="weekday in weekdays" :key="weekday" class="calendar-date">{{ weekday }}</div>
            </div>

            <div class="calendar-body">
                <div
                    v-for="grid_data in grid"
                    :key="grid_data.id"
                    :class="grid_data.cell_classes"
                    class="calendar-date text-center"
                >
                    <default-button
                        :label="$root.translate('Select date {date}', { date: format_date(grid_data.date, 'MMMM do yyyy') })"
                        color="clear"
                        :disabled="grid_data.disabled"
                        @click.prevent="click(grid_data.date)"
                        :tabindex="tabindex"
                        class="date-item"
                    >
                        {{ format_date(grid_data.date, "d") }}
                    </default-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { startOfDay, startOfMonth, subMonths, addMonths, subDays, addDays, getDay, getMonth, isWithinInterval, isSameMonth, isSameYear, isSameDay, isBefore, isAfter, format } from "date-fns"

import { handles_dates } from "@/nibnut/mixins"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "Calendar",
    mixins: [handles_dates],
    components: {
        DefaultButton
    },
    mounted () {
        this.reset()
    },
    watch: {
        selection: "reset"
    },
    methods: {
        reset () {
            let first_selected_date
            if(!this.selection) first_selected_date = startOfDay(new Date())
            else if(Array.isArray(this.selection)) first_selected_date = this.standardized_range_object(this.selection[0]).from
            else first_selected_date = this.standardized_range_object(this.selection).from
            this.current_page_timestamp = startOfMonth(first_selected_date).getTime()
        },
        previous_month () {
            const date = subMonths(this.current_page_date, 1)
            this.current_page_timestamp = date.getTime()
            this.$emit("navigated", date)
        },
        next_month () {
            const date = addMonths(this.current_page_date, 1)
            this.current_page_timestamp = date.getTime()
            this.$emit("navigated", date)
        },
        click (date) {
            this.$emit("click", date)
        },
        format_date (date, date_format) {
            return format(date, date_format)
        }
    },
    computed: {
        current_page_date () {
            return new Date(this.current_page_timestamp)
        },
        page_title () {
            const today = new Date()
            const current_page_date = this.current_page_date
            if(this.selection) {
                let selected_date
                if(Array.isArray(this.selection)) selected_date = this.standardized_range_object(this.selection[0]).from
                else selected_date = this.standardized_range_object(this.selection).from
                if(isSameYear(selected_date, current_page_date)) return format(selected_date, "EEE, LLL dd")
                return format(selected_date, "EEE, LLL dd yyyy")
            }

            if(isSameMonth(current_page_date, today)) return format(today, "EEE, LLL dd")
            return format(current_page_date, "LLL yyyy")
        },
        weekdays () {
            return [
                this.$root.translate("Sun"),
                this.$root.translate("Mon"),
                this.$root.translate("Tue"),
                this.$root.translate("Wed"),
                this.$root.translate("Thu"),
                this.$root.translate("Fri"),
                this.$root.translate("Sat")
            ]
        },
        grid () {
            const today = startOfDay(new Date())
            const min = this.standardized_date(this.min)
            const max = this.standardized_date(this.max)
            let selection = this.selection
            if(selection && !Array.isArray(selection)) selection = [selection]
            let highlights = this.highlights
            if(highlights && !Array.isArray(highlights)) highlights = [highlights]

            const dates = []
            if(this.current_page_timestamp) {
                const current_page_date = new Date(this.current_page_date)
                let date = subDays(current_page_date, getDay(current_page_date))
                const current_month = getMonth(current_page_date)

                for(let loop = 0; loop < 35; loop++) {
                    const month = getMonth(date)
                    const cell_classes = {}
                    if((month < current_month) || ((month === 11) && (current_month === 0))) cell_classes["prev-month"] = true
                    else if((month > current_month) || ((month === 0) && (current_month === 12))) cell_classes["next-month"] = true

                    if(selection) {
                        selection.forEach(selected_date => {
                            const range = this.standardized_range_object(selected_date)
                            if(isWithinInterval(date, { start: range.from, end: range.to })) {
                                if(!cell_classes.active) cell_classes.active = true
                                if(!cell_classes["range-start"] && isSameDay(date, range.from)) cell_classes["range-start"] = true
                                if(!cell_classes["range-end"] && isSameDay(date, range.to)) cell_classes["range-end"] = true
                            }
                        })
                    }
                    if(highlights) {
                        highlights.forEach(highlighted_date => {
                            const range = this.standardized_range_object(highlighted_date)
                            if(isWithinInterval(date, { start: range.from, end: range.to })) {
                                if(!cell_classes.walked) cell_classes.walked = true
                            }
                        })
                    }

                    if(isSameDay(date, today)) cell_classes["date-today"] = true

                    const disabled = (min && isBefore(date, min)) || (max && isAfter(date, max))

                    dates.push({
                        id: date.getTime(),
                        date: new Date(date),
                        cell_classes,
                        disabled
                    })
                    date = addDays(date, 1)
                }
            }
            return dates
        }
    },
    props: {
        selection: { // null, [dates or ranges], date or range ; date is moment or string, range is {from: <date>, to: <date>}
            default: null
        },
        highlights: { // null, [dates], date ; date is moment or string
            default: null
        },
        showNavigation: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            validator: prop => !!prop && prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        min: { // null, string or moment
            default: null
        },
        max: { // null, string or moment
            default: null
        },
        tabindex: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            current_page_timestamp: null // stores the first day of the month being viewed, **as a timestamp*** so it is reactive
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.calendar  {
    background-color: $body-bg;
    min-width: 250px;

    .btn.btn-clear {
        margin-left: 0;
        margin-right: 0;
        &:before {
            display: none;
        }
    }

    .calendar-nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 1.6em;
        .btn_calendar {
            font-size: 1.5em;
            display: none;
        }
        .btn i {
            font-size: 0.8em;
        }
    }
    .calendar-container {
        .calendar-header {
            font-weight: bold;
        }
        .calendar-header,
        .calendar-body {
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: .5rem 0;
            .calendar-date {
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: .3rem 0;
                flex: 0 0 14.28%;
                max-width: 14.28%;
                .date-item {
                    padding: 0;
                }
                span {
                    font-size: 1.1em;
                    width: 37px;
                    height: 37px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .calendar-date > button {
                    cursor: pointer;

                    &[disabled] {
                        cursor: default;
                        opacity: .15;
                    }
                }
                &.next-month,
                &.prev-month {
                    opacity: 0.5;
                }
                &.walked {
                    .date-item:not(:hover) {
                        background: $brand-gray-light;
                    }
                }
                &.active {
                    .date-item {
                        &:not(:hover) {
                            span {
                                font-weight: bold;
                                border: 5px solid $brand-gray-light;
                            }
                        }
                        &:hover {
                            border-color: transparent;
                        }
                    }
                }
                &.date-today {
                    span {
                        font-weight: bold;
                        border: 5px solid $secondary-color-light;
                    }
                }
            }
        }
    }
}
</style>
