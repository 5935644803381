<template>
    <page
        :id="$route.params.slug"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div class="page-outer-container">
            <div class="page-inner-container">
                <remote-page-content
                    v-if="!missing"
                    :remote-data="remote_data"
                />
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import is_remote_page from "@/custom/mixins/IsRemotePage"

import RemotePageContent from "@/custom/components/RemotePageContent"

export default {
    name: "RemotePage",
    mixins: [is_remote_page],
    components: {
        RemotePageContent
    }
}
</script>
