<template>
    <base-link
        v-if="!!value"
        :href="filtered_value"
        v-on="$listeners"
    >
        <slot>{{ value }}</slot>
    </base-link>
</template>

<script>
import BaseLink from "./BaseLink"

export default {
    name: "MailtoLink",
    components: {
        BaseLink
    },
    computed: {
        filtered_value () {
            return this.nibnut_filter(`nibnut.${this.filter}`, [this.value])
        }
    },
    props: {
        value: {
            validator: prop => (prop === null) || (typeof prop === "string"),
            required: true
        },
        filter: {
            type: String,
            default: "mailto"
        }
    }
}
</script>
