<template>
    <img v-if="inFooter" src="@/assets/img/Hop_logo_footer.svg" :alt="alt" class="img-responsive app-logo" />
    <img v-else src="@/assets/img/logo.svg" :alt="alt" class="img-responsive app-logo" />
</template>

<script>
import { mapState } from "vuex"

export default {
    computed: {
        ...mapState(["app_context"]),
        alt () {
            if(this.app_context.settings && this.app_context.settings) return this.app_context.settings.application_name
            return ""
        }
    },
    props: {
        inFooter: {
            type: Boolean,
            default: false
        }
    }
}
</script>
